// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';
import { getScores } from '../../store/reducer';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/archive', breadcrumb: 'Archives' },
];

// == Composant
const WebappArchive = ({ location, match, waitingRequest, connected, getalveoles, datas, getScores, scores, getAllAlveolesContents, alveolesContents, getQuiz, quizDiscover }) => {

    useEffect(() => {
        getalveoles();
        getScores(`?visit=${match.params.id}`);
        getAllAlveolesContents();
        getQuiz('&status=discover');
    },[])

    const breadcrumbs = useBreadcrumbs(routes);

    let tableaux = []
    if(datas && datas['@id'] == '/api/alveoles' && alveolesContents) {
        datas['hydra:member'].forEach(element => {
            tableaux.push({title: element.title, icon: element.icon, id:element['@id'], quizs: []})
        });
        tableaux.forEach(row => {
            alveolesContents.forEach(element => {
                if(element.alveole === row.id && element.quizz) {
                    row.quizs.push(element.quizz)
                }
            })
        })

        console.log('tableaux : ', tableaux)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    if(scores)
    console.log(scores['hydra:member'].find(e => e.quizz['@id'] === "/api/quizzs/7"))

    return (
    <>
    {/*<Header currentMenuItem={1} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{textAlign:'left', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>affichage des scores par activités</h1>
        </div>
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', maxWidth:'100%'}}>
            {tableaux.map((element) => (
                <div style={{minWidth:'45%', backgroundColor:'rgba(0, 0, 0, 0.1)', borderRadius:10, marginBottom:40}}>
                    <div><h3 style={{padding:20, fontSize:28, color:'#D11317', textTransform:'uppercase', fontWeight:'bold'}}>{element.title}</h3></div>
                    <div>
                        {element.quizs.map(quiz => (
                            <div style={{marginBottom:20}}>
                            <h4 style={{fontSize:20, color:'#0578BE'}}>{scores['hydra:member'].sort((a, b) => (b.score - a.score)).find(e => e.quizz['@id'] === quiz) ? scores['hydra:member'].sort((a, b) => (b.score - a.score)).find(e => e.quizz['@id'] === quiz).quizz.title : ''}</h4>
                            {scores['hydra:member'].sort((a, b) => (b.score - a.score)).map(score => {
                                if(score.quizz['@id'] === quiz && score.student.leader === 'leader') {
                                return <p style={{marginTop:5}}>Equipe {score.student.team} : {score.score}</p>
                                }
                            })}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
        <div>
            <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
                <h1 className='page-title'>affichage des scores pour le quiz de présentation</h1>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                {scores && scores['hydra:member'].sort((a, b) => (b.score - a.score)).map(score => {
                    if(score.quizz['@id'] === quizDiscover['hydra:member'][0]['@id']) {
                        return <p style={{marginTop:5, width:'50%', backgroundColor:'rgba(0, 0, 0, 0.1)', padding:10, borderRadius:10, fontSize:18, color:'#0578BE'}}>{score.student.firstname} {score.student.name} : {score.score}</p>
                        }
                })}
            </div>
        </div>
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappArchive;