// == Import : npm
import React, {useEffect} from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const Visites = ({ location, history, match, waitingRequest, datas, getVisitTypes, connected }) => {

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getVisitTypes(match.params.page)
    }, [])

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
        <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper'>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Gestion des types de visites</h1>
            <div style={{display:'flex'}}>
                <Link to='/admin/parametres/type-visite/ajout' className='button buton-link' style={{marginRight:20}}>Ajouter</Link>
                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row', cursor:'pointer' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
            </div>
        </div>

        <div className='table-wrapper'>
            <table>
                <thead>
                    <tr>
                        <th>Nom du type de visite</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {datas['@id'] === '/api/visit_types' ? datas['hydra:member'].map((element) => (
                        <tr>
                            <td>{element.title}</td>
                            <td>
                                <Link to={`/admin/parametres/type-visite/edit/${element.id}`} style={{ fontStyle:'italic' }}>voir</Link>
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </table>

            <div className='pagination'>
            <Pagination datas={datas} url={'/admin/parametres/type-visite/'} match={match} />
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default withRouter(Visites);