// == Import : npm
import React from 'react';
import { Link } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import GoogleMapReact from 'google-map-react';

import useWindowDimensions from '../../utils/dimension';
import Accordion from '../../components/Accordion/App'

// == Import : local
import '../../style/style.scss';
import './home.scss';
import Header from '../../containers/Header';

const headerEntries = [
    {
        title:'Qu\'est ce que l\'appli MIA',
        link:'/maison-ia',
        class:''
    },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    {
        title:'Contact',
        link:'/contact',
        class:'button button-header'
    }
]

const Marker = ({name}) => {
    return(
        <>
        <div style={{ color:'white', backgroundColor:'#0578BEAA', padding:8, borderRadius:'0 10px 10px 10px', width:'fit-content' }}>{name}</div>
        </>
    )
};

// == Composant
const Public = ({location, match}) => {

    const { height, width } = useWindowDimensions();

    return (
        <div>
            <Header currentMenuItem={1} entries={headerEntries} etablissement location={location} match={match} />
            <div className="home-wrapper" style={{ display:'flex', flexDirection:'column', justifyContent:'space-around', maxWidth:'100vw' }}>
                <div style={{backgroundColor:'#0578BE', borderRadius:10, height:52, overflow:'hidden', width:800, display:'flex', flexDirection:'row', maxWidth:'100vw' }}>
                    <Link to='/ia-departement' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', backgroundColor:'#F33c3C', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                        Tutoriels
                    </Link>
                    <Link to='/chiffre-cles' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                        CGU
                    </Link>
                    <Link to='/actu' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                        FAQ
                    </Link>
                </div>
                <div style={{ backgroundColor:'white', borderRadius:10, boxShadow:'0px 4px 4px rgba(0,0,0,0.25)', padding:30, display:'flex', flexDirection:'row', justifyContent:'space-between', width:900, height:500, maxWidth:'100vw' }}>
                <h1 className='page-title'>Tutoriels</h1>
                </div>
            </div>
        </div>
    )
};

// == Export
export default Public;