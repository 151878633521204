// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const QuizAjout = ({ location, history, match, waitingRequest, createQuizz, connected, getQuizByStatus, quizzStatus }) => {

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getQuizByStatus()
    }, [])

    const [name, setName] = useState(null);
    const [emplacement, setEmplacement] = useState(null);
    const [statut, setStatut] = useState(null);
    const [questionFile, setQuestionFile] = useState(null);
    const [answerFile, setAnswerFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const arrondi = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }

    const uploadQuestion = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'application/pdf') {
            setFileUrl('')
            const data = new FormData();
            data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
            setQuestionFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    const uploadAnswers = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'application/pdf') {
            setFileUrl('')
            const data = new FormData();
            data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
            setAnswerFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    return (
        <>
            <Header currentMenuItem={2} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                    <h1 className='page-title' style={{ marginBottom: 20 }}>Ajouter un quiz</h1>
                    <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                </div>
                <div className='grey-background' style={{ marginBottom: 150 }}>
                    {statut === 'discover' ? (
                        <>
                        <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Informations sur le quiz</h2>
                        <label style={{ marginTop: 10, textAlign:'left', width:'50%' }}>
                            Nom du quiz
                            <input style={{marginTop:10}} type='text' className="input-text" placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value)}}></input>
                        </label>
                        <div style={{width:'100%'}} className='fieldset-wrapper'>
                            {/*file ? file.get('file').type !== 'application/pdf' ? file.get('file').type === 'application/pdf' ? null : (
                                <div style={{textAlign:'center', marginTop:30}}>
                                    <img src={fileUrl} style={{width:'auto',height:'auto', maxWidth:'700px'}} alt=""/>
                                </div>
                            ) : (
                                <div style={{textAlign:'center', marginTop:30}}>
                                    <img src='/images/pdf-color.svg' style={{width:45,height:'auto'}} alt=""/>
                                </div>
                            ) : null*/}
                            <div style={{textAlign:'left'}} className="file-details">
                                {/*file ? <h4>Fichier: {file.get('file').name}</h4> : null*/}
                                {/*file ? <h4>Size: {arrondi((file.get('file').size/1024)/1024)} Mo</h4> : null*/}
                            </div>
                            <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly', marginTop:30 }}>
                                <label className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                                    <div>Questionnaire</div>
                                    <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                                    <input
                                        className="custom-file-input"
                                        style={{display:'none'}}
                                        type="file"
                                        name="customFile"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                            const accept = "application/pdf"
                                            accept.split(',').forEach((element) => {
                                            if(element === element.replace(/\s+/g, '')){
                                                uploadQuestion(e)
                                            }
                                            })
                                        }}
                                    />
                                    <div className="input-button">Parcourir...</div>
                                </label>
                                <label className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                                    <div>Réponse</div>
                                    <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                                    <input
                                        className="custom-file-input"
                                        style={{display:'none'}}
                                        type="file"
                                        name="customFile"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                            const accept = "image/png, image/jpeg"
                                            accept.split(',').forEach((element) => {
                                            if(element === element.replace(/\s+/g, '')){
                                                uploadAnswers(e)
                                            }
                                            })
                                        }}
                                    />
                                    <div className="input-button">Parcourir...</div>
                                </label>
                            </div>
                        </div>
                        </>
                    ) : (
                        <>
                            <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Informations sur le quiz</h2>
                            <label style={{ marginTop: 10, textAlign:'left', width:'50%' }}>
                                Nom du quiz
                                <input style={{marginTop:10}} type='text' className="input-text" placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value)}}></input>
                            </label>
                        </>
                    )}
                    {/*<label style={{ marginTop: 40, textTransform: 'uppercase', fontSize:20, marginBottom:10 }}>Emplacement</label>
                    <p style={{ marginBottom:10 }}>Sélectionnez l'emplacement du quiz</p>
                    <div style={{ backgroundColor:'white', borderRadius:10, textAlign:'left', padding:15, border:'1px solid #C4C4C4', boxShadow:'0px 4px 4px rgba(0,0,0,0.25)' }}>
                        <div>
                            <input type="radio" id="Alvéole Ia et Santé" name="drone" value="Alvéole Ia et Santé" onClick={(e) => {if(e.target.value){setEmplacement(e.target.value)}}} />
                            <label for="Alvéole Ia et Santé">Alvéole Ia et Santé</label>
                        </div>

                        <div>
                            <input type="radio" id="Alvéole IA et Vie Quotidienne" name="drone" value="Alvéole IA et Vie Quotidienne" onClick={(e) => {if(e.target.value){setEmplacement(e.target.value)}}} />
                            <label for="Alvéole IA et Vie Quotidienne">Alvéole IA et Vie Quotidienne</label>
                        </div>
                        <div>
                            <input type="radio" id="Alvéole IA et Territoire" name="drone" value="Alvéole IA et Territoire" onClick={(e) => {if(e.target.value){setEmplacement(e.target.value)}}} />
                            <label for="Alvéole IA et Territoire">Alvéole IA et Territoire</label>
                        </div>
                        <div>
                            <input type="radio" id="Alvéole IA et Art, Culture et Loisir" name="drone" value="Alvéole IA et Art, Culture et Loisir" onClick={(e) => {if(e.target.value){setEmplacement(e.target.value)}}} />
                            <label for="Alvéole IA et Art, Culture et Loisir">Alvéole IA et Art, Culture et Loisir</label>
                        </div>
                        <div>
                            <input type="radio" id="Espace Etablissement" name="drone" value="Espace Etablissement" onClick={(e) => {if(e.target.value){setEmplacement(e.target.value)}}} />
                            <label for="Espace Etablissement">Espace Etablissement</label>
                        </div>
                    </div>*/}
                    <label style={{ marginTop: 40, textTransform: 'uppercase', fontSize:20, marginBottom:10 }}>Statut du Quiz</label>
                    <p style={{ marginBottom:10 }}>Mise en ligne du quiz dans l'emplacement choisi</p>
                    <div>
                        <input type="radio" id="En ligne" name="drone" value="En ligne" onClick={(e) => {if(e.target.value){setStatut(e.target.value)}}} />
                        <label htmlFor="En ligne">En ligne <span style={{fontStyle:'italic'}}>(désactivera par defaut tout autre quiz en ligne dans l'emplacement sélectionné)</span></label>
                    </div>
                    <div>
                        <input type="radio" id="Hors ligne" name="drone" value="Hors ligne" onClick={(e) => {if(e.target.value){setStatut(e.target.value)}}} />
                        <label htmlFor="Hors ligne">Hors ligne</label>
                    </div>
                    <div>
                        <input type="radio" id="webapp" disabled={quizzStatus && quizzStatus['hydra:totalItems'] > 0} name="drone" value="discover" onClick={(e) => {if(e.target.value){setStatut(e.target.value)}}} />
                        <label htmlFor="webapp">Quizz tester ses connaissances <span style={{fontStyle:'italic'}}>(webapp)</span></label>
                    </div>
                    <div>
                        <input type="radio" id="application" name="drone" value="pres" onClick={(e) => {if(e.target.value){setStatut(e.target.value)}}} />
                        <label htmlFor="application">Quizz de présentation <span style={{fontStyle:'italic'}}>(application)</span></label>
                    </div>
                    <button onClick={() => {
                        if (statut === 'discover' && questionFile !== null && answerFile !== null && name !== null) {
                            createQuizz(name, statut, questionFile, answerFile)
                        } else if (statut !== 'discover' && name !== null && statut !== null) {
                            createQuizz(name, statut)
                        }
                    }} style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                </div>

                {/*<div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%' }}>
                    <h2 className='page-title' style={{ marginBottom: 20 }}>liste des questions</h2>
                    <Link to={`/admin/quiz/questions/${match.params.id}/ajout-question`} className='button buton-link'>Nouvelle question</Link>
                </div>

                <div className='table-wrapper' style={{ marginBottom:100 }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>rang</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {testContent.map((element) => (
                                <tr>
                                    <td>{element.name}</td>
                                    <td>{element.rank}</td>
                                    <td>
                                        <Link to={`/admin/alveoles/${match.params.name}/content/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                            <p>Modifier</p>
                                            <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                        </Link>
                                        <Link to={`/admin/alveoles/${match.params.name}/content/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                            <p>Supprimer</p>
                                            <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ textAlign:'left' }}>
                    <button style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold', alignSelf:'flex-start' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                </div>*/}


            </div>
        </>
    )
};

// == Export
export default QuizAjout;