import React from 'react';
import { Link, Redirect } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-wrapper" style={{overflow:'hidden', position:'absolute', bottom:0, height:54, backgroundColor:'#0578BE', width:'100vw'}}>
      <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Link to="#" style={{color:'white', textTransform:'uppercase'}}>Mentions légales</Link>
        <span style={{margin:'0 10px', color:'white'}}> - </span>
        <Link to="#" style={{color:'white', textTransform:'uppercase'}}>Conditions générales d'utilisation</Link>
        <span style={{margin:'0 10px', color:'white'}}> - </span>
        <Link to="/contact" style={{color:'white', textTransform:'uppercase'}}>
          Contact
        </Link>
      </div>
    </div>
  );
};

export default Footer;