// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';
import { editUser } from '../../store/reducer';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/parametres', breadcrumb: 'Changer de mot de passe' },
];

// == Composant
const WebappParametres = ({ location, match, waitingRequest, connected, getMe, datas, editInfoUser }) => {

    const [stateInit, setStateInit] = useState(false);
    const [userId, setuserId] = useState(null);
    const [mail, setMail] = useState(null);
    const [mdp, setMdp] = useState(null);
    const [confirmMdp, setConfirmMdp] = useState(null)
    const [prof, setProf] = useState(null);
    const [classe, setClasse] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [addressSchool, setAddressSchool] = useState(null)
    const [Etablissement, setEtablissement] = useState(null);

    const breadcrumbs = useBreadcrumbs(routes);
    
    useEffect(() => {
        getMe()
    }, [])

    if(datas.email && !stateInit) {
        setuserId(datas.id);
        setMail(datas.email);
        setEtablissement(datas.school.name);
        setAddressSchool(datas.school.adress);
        setProf(datas.school.principalName);
        setPhoneNumber(datas.school.phoneNumber)
        setStateInit(true);
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'left',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left' }}>Changer de mot de passe</h1>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Nouveau mot de passe</div>
                <input type='password' className="input-text" placeholder='Mot de passe' value={mdp} onChange={(e) => {setMdp(e.target.value)}}></input>
            </div>
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Valider le mot de passe</div>
                <input type='password' className="input-text" placeholder='Mot de passe' value={confirmMdp} onChange={(e) => {setConfirmMdp(e.target.value)}}></input>
            </div>
            <div className='fieldset-wrapper'>
                <button className='button' onClick={() => editInfoUser(userId, mdp)}>Enregistrer</button>
            </div>
        </div>
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappParametres;