// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';
import './info.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/informations-pratiques', breadcrumb: 'Informations pratiques' },
];

// == Composant
const WebappInfo = ({ location, match, editWebContent, getWebContent, contactContent, waitingRequest, user, transportContent, recommandationContent, connected }) => {

    const [contact, setContact] = useState(null);
    const [transport, setTransport] = useState(null);
    const [recommandation, setRecommandation] = useState(null);
    const [init, setInit] = useState(false);

    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER')) {
            getWebContent(1);
            getWebContent(2);
            getWebContent(3);
        }
    }, [])

    if( contactContent && transportContent && recommandationContent && !init) {
        setContact(contactContent.content);
        setTransport(transportContent.content);
        setRecommandation(recommandationContent.content);
        setInit(true)
    }
console.log(contact)

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'left',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left' }}>Informations pratiques</h1>
        </div>
        {width >= 1115 ? (
            <>
                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', marginTop:30, width:'100%'}}>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/contact-infos-pratiques.png' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center', padding:'10px'}}>Contact</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'center'}}>
                            <p style={{color:'white', margin:'12px 0'}}>1361 Route des Lucioles,</p>
                            <p style={{color:'white', margin:'12px 0'}}>06410 Sophia Antipolis Biot, France</p>
                            <p style={{color:'white', margin:'12px 0'}}>(+33) 04 22 21 50 42</p>
                            <a style={{color:'white', margin:'12px 0'}} href="mailto:contact@maison-intelligence-artificielle.com">contact@maison-intelligence-artificielle.com</a>
                        </div>
                    </div>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Horaires</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                            <p style={{color:'white', margin:'12px 0'}}>Du lundi au vendredi</p>
                            <p style={{color:'white', margin:'12px 0'}}>09h00 - 17h30</p>
                        </div>
                    </div>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/emplacement-mia-infos-pratiques.png' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Plan MIA</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                            <p style={{color:'white', margin:'12px 0'}}>A venir.</p>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', marginTop:30, width:'100%'}}>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/Acces-handicape-info-pratique.png' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Accès handicapé</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                            <p style={{color:'white', margin:'12px 15px'}}>La MIA est accessible pour toute personne à mobilité réduite.</p>
                        </div>
                    </div>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/tech.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>La visite virtuelle</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'center'}}>
                            <p style={{color:'white', margin:'12px 15px'}}>Pour découvrir les espaces de la MIA et quelques vidéos informatives, n’hésitez pas à parcourir la visite virtuelle ici</p>
                            <p style={{color:'white', margin:'12px 15px'}}>NB : celle-ci ne contient pas les parcours d’activité proposés aux classes de collégiens.</p>
                        </div>
                    </div>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/couverts-infos-pratiques.png' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center', width:'70%'}}>Où déjeuner lors d’une visite à la MIA</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                            <p style={{color:'white', margin:'12px 15px'}}>La MIA ne dispose pas d’une cafétéria ni ne peut proposer de déjeuners aux classes.</p>
                            <p style={{color:'white', margin:'12px 15px'}}>Il est néanmoins possible de s’installer dans le jardin entourant le bâtiment de la MIA où des tables de pique-nique sont disposées.</p>
                            <p style={{color:'white', margin:'12px 15px'}}>A noter qu’en raison du contexte sanitaire, les restaurants qui se situent à proximité de la MIA sont actuellement fermés.</p>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-evenly', marginTop:30, width:'100%'}}>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/formulaires-infos-pratiques.png' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center', width:'70%'}}>Formulaires de droits à l’image</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', height:'190px', justifyContent:'flex-start'}}>
                            <p style={{color:'white', margin:'12px 15px'}}>Vous pouvez télécharger ici le formulaire d’autorisation de droits à l’image à faire remplir par les parents des élèves de votre classe avant votre visite.</p>
                        </div>
                    </div>
                    <div className="page-info_card-container button-shadow">
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                            <img src='/images/masque-infos-pratiques.png' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                            <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Aspects sanitaires</p>
                        </div>
                        <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', height:'190px', justifyContent:'flex-start'}}>
                            <p style={{color:'white', margin:'12px 15px'}}>La MIA s’attache au respect des consignes sanitaires en vigueur afin de vous accueillir malgré le contexte actuel :</p>
                            <ul style={{textAlign:'left', listStyle:'initial'}}>
                                <li style={{color:'white'}}>application des gestes barrières</li>
                                <li style={{color:'white'}}>distanciation physique</li>
                                <li style={{color:'white'}}>port du masque</li>
                                <li style={{color:'white'}}>ventilation des locaux</li>
                                <li style={{color:'white'}}>circulation encadrée</li>
                                <li style={{color:'white'}}>désinfection régulière</li>
                            </ul>
                            <p style={{color:'white', margin:'12px 15px'}}>La taille des locaux (près de 700m² dont 300m² environ de showroom où se situent la plupart des activités) nous permet également de pouvoir vous recevoir en toute sérénité.</p>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', marginTop:30, width:'100%'}}>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/tech.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center', padding:'10px'}}>Contact</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'center'}}>
                        <p style={{color:'white', margin:'12px 0'}}>1361 Route des Lucioles,</p>
                        <p style={{color:'white', margin:'12px 0'}}>06410 Sophia Antipolis Biot, France</p>
                        <p style={{color:'white', margin:'12px 0'}}>(+33) 04 22 21 50 42</p>
                        <a style={{color:'white', margin:'12px 0'}} href="mailto:contact@maison-intelligence-artificielle.com">contact@maison-intelligence-artificielle.com</a>
                    </div>
                </div>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Horaires</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                        <p style={{color:'white', margin:'12px 0'}}>Du lundi au vendredi</p>
                        <p style={{color:'white', margin:'12px 0'}}>09h00 - 17h30</p>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', marginTop:30, width:'100%'}}>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Plan MIA</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                        <p style={{color:'white', margin:'12px 0'}}>A venir.</p>
                    </div>
                </div>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Accès handicapé</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                        <p style={{color:'white', margin:'12px 15px'}}>La MIA est accessible pour toute personne à mobilité réduite.</p>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', marginTop:30, width:'100%'}}>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/tech.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>La visite virtuelle</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'center'}}>
                        <p style={{color:'white', margin:'12px 15px'}}>Pour découvrir les espaces de la MIA et quelques vidéos informatives, n’hésitez pas à parcourir la visite virtuelle ici</p>
                        <p style={{color:'white', margin:'12px 15px'}}>NB : celle-ci ne contient pas les parcours d’activité proposés aux classes de collégiens.</p>
                    </div>
                </div>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center', width:'70%'}}>Où déjeuner lors d’une visite à la MIA</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', justifyContent:'flex-start'}}>
                        <p style={{color:'white', margin:'12px 15px'}}>La MIA ne dispose pas d’une cafétéria ni ne peut proposer de déjeuners aux classes.</p>
                        <p style={{color:'white', margin:'0px 15px'}}>Il est néanmoins possible de s’installer dans le jardin entourant le bâtiment de la MIA où des tables de pique-nique sont disposées.</p>
                        <p style={{color:'white', margin:'12px 15px'}}>A noter qu’en raison du contexte sanitaire, les restaurants qui se situent à proximité de la MIA sont actuellement fermés.</p>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', marginTop:30, width:'100%'}}>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center', width:'70%'}}>Formulaires de droits à l’image</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', height:'190px', justifyContent:'flex-start'}}>
                        <p style={{color:'white', margin:'12px 15px'}}>Vous pouvez télécharger ici le formulaire d’autorisation de droits à l’image à faire remplir par les parents des élèves de votre classe avant votre visite.</p>
                    </div>
                </div>
                <div className="page-info_card-container button-shadow">
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', borderRadius:10, padding:'10px 0'}}>
                        <img src='/images/001-calendar.svg' style={{ height:35, width:35, margin:'10px 10px 10px 0' }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', alignSelf:'center'}}>Aspects sanitaires</p>
                    </div>
                    <div style={{display:'flex',alignItems:'center',flexDirection:'column', textAlign:'center', height:'190px', justifyContent:'flex-start'}}>
                        <p style={{color:'white', margin:'12px 15px'}}>La MIA s’attache au respect des consignes sanitaires en vigueur afin de vous accueillir malgré le contexte actuel :</p>
                        <ul style={{textAlign:'left', listStyle:'initial'}}>
                            <li style={{color:'white'}}>application des gestes barrières</li>
                            <li style={{color:'white'}}>distanciation physique</li>
                            <li style={{color:'white'}}>port du masque</li>
                            <li style={{color:'white'}}>ventilation des locaux</li>
                            <li style={{color:'white'}}>circulation encadrée</li>
                            <li style={{color:'white'}}>désinfection régulière</li>
                        </ul>
                        <p style={{color:'white', margin:'12px 15px'}}>La taille des locaux (près de 700m² dont 300m² environ de showroom où se situent la plupart des activités) nous permet également de pouvoir vous recevoir en toute sérénité.</p>
                    </div>
                </div>
            </div>
        </>
        )}
        </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappInfo;