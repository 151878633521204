// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappExplorer from '../../screen/WebappExplorer';

// Action Creators
import { getCat } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.cat,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCat: () => {
    dispatch(getCat());
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappExplorer);