// == Import : npm
import React from 'react';
import { Link } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import GoogleMapReact from 'google-map-react';

import useWindowDimensions from '../../utils/dimension';

// == Import : local
import '../../style/style.scss';
import './home.scss';
import Header from '../../containers/Header';

const headerEntries = [
    {
        title:'Qu\'est ce que l\'appli MIA',
        link:'/maison-ia',
        class:''
    },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    {
        title:'Contact',
        link:'/contact',
        class:'button button-header'
    }
]

// == Composant
const MaisonIA = ({location, match}) => {

  const { height, width } = useWindowDimensions();

  return (
    <div>
      <Header currentMenuItem={0} entries={headerEntries} etablissement location={location} match={match} />
      <div className="home-wrapper" style={{ display:'flex', flexDirection:'column', justifyContent:'space-around', maxWidth:'100vw' }}>
        <div style={{ backgroundColor:'white', borderRadius:10, boxShadow:'0px 4px 4px rgba(0,0,0,0.25)', padding:30, width:900, maxWidth:'100vw', boxSizing:'border-box', textAlign:'left' }}>
          <ul style={{listStyle:'initial', marginBottom:30}}>Cette application web est proposée par la Maison de l’Intelligence Artificielle. Elle est destinée à l’ensemble du corps enseignant en établissement secondaire (collèges notamment) dans le but de leur fournir :
            <li style={{marginLeft:15}}>des ressources diverses pour aborder la thématique de l’Intelligence Artificielle en classe avec leurs élèves;</li>
            <li style={{marginLeft:15}}>ainsi que leur permettre de réserver une visite à la MIA pour découvrir l’IA au travers de parcours d’activités pédagogiques.</li>
          </ul>
          <ul style={{listStyle:'initial'}}>En vous inscrivant, vous trouverez ici :
            <li style={{marginLeft:15}}>une Médiathèque avec des contenus sur l’IA, cours, définitions, explications, images, présentations, quiz, etc. qui vous permettront de sensibiliser vos élèves à l’IA. Vous pouvez également partager des ressources vous-même, celles-ci n’étant accessibles qu’aux utilisateurs-enseignants inscrits sur l’application;</li>
            <li style={{marginLeft:15}}>la description de l'ensemble des parcours et activités pédagogiques de la MIA;</li>
            <li style={{marginLeft:15}}>et la possibilité de réserver un créneau de visite afin de venir les expérimenter in situ, accompagné d’un médiateur de la MIA.</li>
          </ul>
        </div>
      </div>
    </div>
  )
};

// == Export
export default MaisonIA;