// == Import : npm
import React, { useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    // {
    //     title:'Qu\'est ce que l\'appli MIA',
    //     link:'/maison-ia',
    //     class:''
    // },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    // {
    //     title:'Contact',
    //     link:'/contact',
    //     class:'button button-header'
    // }
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/contact', breadcrumb: 'Contact' },
];

// == Composant
const WebappContact = ({ location, match, connected }) => {
    const breadcrumbs = useBreadcrumbs(routes);
    return (
        <div>
            {connected && connected.apiToken ? <HeaderSchool /> : <Header entries={headerEntries} etablissement etablissementUnderLine location={location} match={match} />}
            <div className="home-wrapper" style={{flexDirection:"column"}}>
                {connected && connected.apiToken ? (
                    <div style={{width:'650px'}}>
                        <div style={{textAlign:'left', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold', alignSelf:'flex-start'}}>
                            {breadcrumbs.map((breadcrumb, i, arr) => (
                                <span key={breadcrumb.match.url}>
                                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                                </span>
                            ))}
                        </div>
                    </div>
                ) : null}
                <div className="connection-wrapper" style={{overflowY:'scroll'}}>
                    <h1>Contact</h1>
                    <div className="input-wrapper">
                        <input type="text" className="input-text" placeholder="Nom"></input>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input-text" placeholder="Prénom"></input>
                    </div>
                    <div className="input-wrapper">
                        <input type="text" className="input-text" placeholder="Adresse e-mail"></input>
                    </div>
                    <div className="input-wrapper">
                        <textarea className="input-text" style={{ maxWidth:'100%', minWidth:'100%', height:'150px' }} placeholder='Message'></textarea>
                    </div>
                    <div style={{width: '100%', display:'flex', justifyContent:'space-between'}}>
                        <button className="button input-submit button-shadow">Valider</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

// == Export
export default WebappContact;