// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import VisitesEdit from '../../screen/VisitesEdit';

// Action Creators
import { getVisitType, setVisitType } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisitType: (id) => {
    dispatch(getVisitType(id));
  },
  setVisitType: (id, title, desc, partners, mediator) => {
    dispatch(setVisitType(id, title, desc, partners, mediator, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisitesEdit);