// == Import : npm
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useLocation } from "react-router";


import useWindowDimensions from '../../utils/dimension';

// == Import : local
import '../../style/style.scss';
import './header.scss';

// == Composant
const Header = ({entries, etablissement, location, match, currentMenuItem, deco, etablissementUnderLine, messageNotif, clearNotif}) => {
    //const path = match.url;
    /*allEntries.forEach(entry => {
        if(path === entry.link) {entry.class = `${entry.class} underline`}
    });*/

    if(messageNotif.message != '') {
        window.scrollTo(0, 0);
    }

    const { height, width } = useWindowDimensions();

    const [displayMenu, setDisplayMenu] = useState(false);

    const displayEntries = () => {
        let allEntries = [];
        for (let index = 0; index < entries.length; index++) {
            if(currentMenuItem === index) {
                allEntries[index] = <Link key={index} style={{maxWidth:`${80/(entries.length)}vw`}} to={entries[index].link} className={entries[index].class!=="" ? `${entries[index].class} underline` : "underline"}>{entries[index].title}</Link>;
            } else {
                allEntries[index] = <Link key={index} style={{maxWidth:`${80/(entries.length)}vw`}} to={entries[index].link} className={entries[index].class!=="" ? entries[index].class : ""}>{entries[index].title}</Link>;
            }
        }

        return (<>{allEntries}</>);

    };
    
    if(messageNotif.message != '') {
        setTimeout(() => {
        clearNotif()
        console.log('clear')
        }, 10000);
    }

    return (
        <>
        <div className="header-wrapper" style={{overflow:'hidden', position:'relative'}}>
            <div style={{position: 'absolute', top:0, left:0, display:'flex', justifyContent:'center', width:'100%'}}>
                <img src="/images/fond_3x_line.png" style={{height:240, width:1920, zIndex:0 }}></img>
            </div>
            <img alt="logo maison de l'ia" src="/images/LOGO-MIA_blanc_rouge.png" style={{marginBottom:15, marginTop:15, zIndex:500}}></img>
            {width > 1050 ? <div className="menu-wrapper">
                {displayEntries()}
                {etablissement ? <Link to='/' className="espace-etablissement">
                    <img alt='logo espace etablissement' src='/images/account.svg'></img>
                    <div>Espace utilisateur</div>
                    {etablissementUnderLine ? <div className='red-line'></div> : <></>}
                </Link> : <></>}
                {deco ? <Link to='/deconnexion' className="espace-etablissement">
                    <div>
                        <img alt='deconnexion' src='/images/logout.svg' style={{ width:28, height:28 }}></img>
                        <div>deconnexion</div>
                    </div>
                </Link> : <></>}
            </div>
            :
            <div style={{ height:40, width:45, marginLeft:'calc(100vw - 100px)', position:'relative' }} onClick={() => setDisplayMenu(!displayMenu)}>
                <div style={{}} className={displayMenu ? 'burger burger-cross' : 'burger'}></div>
                <div style={{}} className={displayMenu ? 'burger burger-cross' : 'burger'}></div>
                <div style={{}} className={displayMenu ? 'burger burger-cross' : 'burger'}></div>
            </div>}
        </div>
        <div>
        {messageNotif.message != "" ? <div className={messageNotif.good ? 'good-notif' : 'bad-notif'} style={{ position:"relative" }}>
            {messageNotif.message}
            <img src='/images/cross-icon.svg' style={{height:20, width:20, position:"absolute", top:10, right:10}} onClick={() => clearNotif()} />
        </div> : <></>}
        </div>
        {width < 1050 ? <div className="menu-wrapper-burger" style={{ right:!displayMenu ? '-310px' : 0, height:document.body.clientHeight-200  }}>
            {displayEntries()}
            {etablissement ? <Link to='/etablissement'>
                <div>Espace utilisateur</div>
            </Link> : <></>}
            {deco ? <Link to='/deconnexion'>
                <div>
                    <div>deconnexion</div>
                </div>
            </Link> : <></>}
        </div> : <></>}
        </>
    );
};

// == Export
export default Header;