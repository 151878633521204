import React from 'react';
import logo from './logo.svg';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { withCookies } from 'react-cookie';
import { guid } from 'react-agenda';
import './reset.css';
import './App.scss';

import Home from './containers/Home';
import Boite from './containers/Boite';
import Alveoles from './containers/Alveoles';
import AlveolesContent from './containers/AlveolesContent';
import AlveolesContentModif from './containers/AlveolesContentModif';
import Quiz from './containers/Quiz';
import QuizQuestion from './containers/QuizQuestion';
import QuizQuestionAdd from './containers/QuizQuestionAdd';
import PlanningReservation from './containers/PlanningReservation';
import GererReservation from './containers/GererReservation';
import VoirReservation from './containers/VoirReservation';
import Parametre from './screen/Parametre';
import BoiteAjout from './containers/BoiteAjout';
import Roles from './containers/Roles';
import RolesEdit from './containers/RolesEdit';
import Etablissement from './containers/Etablissement';
import VoirEtablissement from './containers/VoirEtablissement';
import VoirDemandeEspace from './containers/VoirDemandeEspace';
import EtablissementAjout from './containers/EtablissementAjout';
import Contenus from './containers/Contenus';
import VoirContenus from './containers/VoirContenus';
import Categories from './containers/Categories';
import Visites from './containers/Visites';
import VisitesEdit from './containers/VisitesEdit';
import Evenements from './containers/Evenements';
import EvenementEdit from './containers/EvenementEdit';
import ContenusWebapp from './containers/ContenusWebapp';
import BoiteEdit from './containers/BoiteEdit';
import AlveolesContentAjout from './containers/AlveolesContentAjout';
import QuizAjout from './containers/QuizAjout';
import VisitesAjout from './containers/VisitesAjout';
import EvenementAjout from './containers/EvenementAjout';
import Deconnexion from './containers/Deconnexion';
import QuizQuestionEdit from './containers/QuizQuestionEdit';
import NouveauMembre from './containers/NouveauMembre';
import Public from './screen/Public';
import MaisonIA from './screen/Public/maisonIA'
import WebappHome from './screen/WebappHome';
import WebappArchives from './containers/WebappArchives';
import WebappArchive from './containers/WebappArchive';
import WebappBoite from './containers/WebappBoite';
import WebappExplore from './containers/WebappExplorer';
import WebappCategory from './containers/WebappCategory';
import WebappAjoutContenu from './containers/WebappAjoutContenu';
import WebappVisite from './containers/WebappVisite';
import WebappVisiteDemande from './containers/WebappVisiteDemande';
import WebappVisiteDemande2 from './containers/WebappVisiteDemande2';
import WebappVisiteDemande3 from './containers/WebappVisiteDemande3';
import WebappVisitePrepar from './containers/WebappVisitePrepar';
import WebappQuizTest from './containers/WebappQuizTest';
import WebappQuizTestScore from './containers/WebappQuizTestScore';
import WebappInfo from './containers/WebappInfo';
import WebappParametres from './containers/WebappParametres';
import WebappChiffre from './screen/WebappChiffre';
import WebappActualite from './screen/WebappActualite';
import WebappContact from './containers/WebappContact';
import Page404 from './screen/Page404';
import RolesAjout from './containers/RolesAjout';
import CategoryAjout from './containers/CategoryAjout';
import CategoryEdit from './containers/CategoryEdit';
import WebappParcour from './containers/WebappParcour';
import ForgotPassword from './containers/ForgotPassword';
import GererVisites from './containers/GererVisites';


function App({ cookies, connected }) {

  const isAuthenticated = () => {  
    if (connected && connected.apiToken) {
      return true;
    }
      
    return false;
  }

  /*const ProtectedRoutes = ({ path, component, connected, role }) => {

    if(connected != null && connected.roles.find(element => element === role))
    {
      return (
        <Route exact path={path} component={component}>
        </Route>
      )
    } else {return <Redirect to='/'  />}
  }*/

  const RedirectRoutes = () => {
    if (isAuthenticated() && connected.roles.find(element => element === 'ROLE_ADMIN')) {
      return <Route exact path="/admin/mediatheque/:page(\d+)"  render={(props) => <Boite match={props.match} key={guid()} />}></Route>
    } else if (isAuthenticated() && connected.roles.find(element => element === 'ROLE_USER')) {
      return <Route exact path="/accueil" component={WebappHome}></Route>
    } else {
      return <Route exact path="/" render={() => <Home cookies={cookies} />}></Route>
    }
  }

  const ProtectedRoutes = () => {
    if (isAuthenticated() && connected.roles.find(element => element === 'ROLE_ADMIN')) {
      return <Redirect to="/admin/mediatheque/1" />
    } else if (isAuthenticated() && connected.roles.find(element => element === 'ROLE_USER')) {
      return <Redirect to="/accueil" />
    } else {
      return <Home cookies={cookies} />
    }
  }

  console.log(connected);
  console.log(isAuthenticated())

  return (
    <Router>
      <div className="App">
      <Switch>
          <Route exact path="/" render={() => ProtectedRoutes() }></Route>
          {RedirectRoutes()}
          <Route exact path="/nouveau-membre" component={NouveauMembre}>
          </Route>
          <Route exact path="/ia-departement" component={Public}>
          </Route>
          <Route exact path="/maison-ia" component={MaisonIA}>
          </Route>
          {/*<ProtectedRoutes path="/admin/mediatheque/:page(\d+)" component={Boite} connected={connected} role="ROLE_ADMIN" />*/}
          {/*<Route exact path="/admin/mediatheque/:page(\d+)"  render={(props) => <Boite match={props.match} key={guid()} />}>
          </Route>*/}
          <Route exact path="/admin/mediatheque/ajout" component={BoiteAjout}>
          </Route>
          <Route exact path="/admin/mediatheque/edit/:id(\d+)" component={BoiteEdit}>
          </Route>
          <Route exact path="/admin/quiz/:page(\d+)" render={(props) => <Quiz match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/quiz/ajout" component={QuizAjout}>
          </Route>
          <Route exact path="/admin/quiz/questions/:id(\d+)" component={QuizQuestion}>
          </Route>
          <Route exact path="/admin/quiz/questions/:id(\d+)/ajout-question" component={QuizQuestionAdd}>
          </Route>
          <Route exact path="/admin/quiz/questions/:id(\d+)/edit-question/:question(\d+)" component={QuizQuestionEdit}>
          </Route>
          <Route exact path="/admin/visites" component={Alveoles}>
          </Route>
          <Route exact path="/admin/visites/:name" component={AlveolesContent}>
          </Route>
          <Route exact path="/admin/visites/:name/ajout" component={AlveolesContentAjout}>
          </Route>
          <Route exact path="/admin/visites/:name/content/:id" component={AlveolesContentModif}>
          </Route>
          <Route exact path="/admin/planning-reservation" component={PlanningReservation}>
          </Route>
          <Route exact path="/admin/gerer-visites/:page(\d+)" render={(props) => <GererVisites match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/gerer-reservation/:page(\d+)" render={(props) => <GererReservation match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/gerer-reservation/voir/:id(\d+)" component={VoirReservation}>
          </Route>
          <Route exact path="/admin/parametres" component={Parametre}>
          </Route>
          <Route exact path="/admin/parametres/roles/:page(\d+)" render={(props) => <Roles match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/parametres/roles/edit/:id(\d+)" component={RolesEdit}>
          </Route>
          <Route exact path="/admin/parametres/roles/add" component={RolesAjout}>
          </Route>
          <Route exact path="/admin/parametres/etablissements/:page" component={Etablissement}>
          </Route>
          <Route exact path="/admin/parametres/etablissement/ajout" component={EtablissementAjout}>
          </Route>
          <Route exact path="/admin/parametres/etablissements/voir/:id(\d+)" component={VoirEtablissement}>
          </Route>
          <Route exact path="/admin/parametres/etablissements/voir/demande-espace/:id(\d+)" component={VoirDemandeEspace}>
          </Route>
          <Route exact path="/admin/parametres/contenus/:page(\d+)" render={(props) => <Contenus match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/parametres/contenus/voir/:id(\d+)" component={VoirContenus}>
          </Route>
          <Route exact path="/admin/parametres/categories" component={Categories}>
          </Route>
          <Route exact path="/admin/parametres/categories/ajout" component={CategoryAjout}>
          </Route>
          <Route exact path="/admin/parametres/categories/edit/:id(\d+)" component={CategoryEdit}>
          </Route>
          <Route exact path="/admin/parametres/type-visite/:page(\d+)" render={(props) => <Visites match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/parametres/type-visite/ajout" component={VisitesAjout}>
          </Route>
          <Route exact path="/admin/parametres/type-visite/edit/:id(\d+)" component={VisitesEdit}>
          </Route>
          <Route exact path="/admin/parametres/evenements/:page(\d+)" render={(props) => <Evenements match={props.match} key={guid()} />}>
          </Route>
          <Route exact path="/admin/parametres/evenements/Ajout" component={EvenementAjout}>
          </Route>
          <Route exact path="/admin/parametres/evenements/edit/:id(\d+)" component={EvenementEdit}>
          </Route>
          <Route exact path="/admin/parametres/webapp" component={ContenusWebapp}>
          </Route>
          <Route exact path="/deconnexion" component={Deconnexion}>
          </Route>
          {/*<Route exact path="/accueil" component={WebappHome}>
          </Route>*/}
          <Route exact path="/archives" component={WebappArchives}>
          </Route>
          <Route exact path="/archives/:id(\d+)" component={WebappArchive}>
          </Route>
          <Route exact path="/mediatheque" component={WebappBoite}>
          </Route>
          <Route exact path="/explorer" component={WebappExplore}>
          </Route>
          <Route exact path="/explorer/categorie/:id(\d+)" component={WebappCategory}>
          </Route>
          <Route exact path="/charger-contenu" component={WebappAjoutContenu}>
          </Route>
          <Route exact path="/visite" component={WebappVisite}>
          </Route>
          <Route exact path="/visite/nouvelle-demande" component={WebappVisiteDemande}>
          </Route>
          <Route exact path="/visite/nouvelle-demande/2" component={WebappVisiteDemande2}>
          </Route>
          <Route exact path="/visite/nouvelle-demande/3" component={WebappVisiteDemande3}>
          </Route>
          <Route exact path="/preparer-visite" component={WebappVisitePrepar}>
          </Route>
          <Route exact path="/preparer-visite/test-connaissance" component={WebappQuizTest}>
          </Route>
          <Route exact path="/preparer-visite/parcours/:id" component={WebappParcour}>
          </Route>
          <Route exact path="/test-connaissance/resultats" component={WebappQuizTestScore}>
          </Route>
          <Route exact path="/informations-pratiques" component={WebappInfo}>
          </Route>
          <Route exact path="/parametres" component={WebappParametres}>
          </Route>
          <Route exact path="/chiffre-cles" component={WebappChiffre}>
          </Route>
          <Route exact path="/actu" component={WebappActualite}>
          </Route>
          <Route exact path="/contact" component={WebappContact}>
          </Route>
          <Route exact path="/forget-password" component={ForgotPassword}>
          </Route>
          <Route component={Page404}>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default withCookies(App);
