// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/visite', breadcrumb: 'Réserver une visite' },
    { path: '/visite/nouvelle-demande/2', breadcrumb: 'Nouvelle demande - étape 2' },
];

// == Composant
const WebappVisiteDemande2 = ({ location, history, match, waitingRequest, connected, setSchoolInfos }) => {

    const phoneRegex = /^((\+)33|0)[1-9](\d{2}){4}$/g
    const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

    console.log(location.infos)

    const [teacherName, setTeacherName] = useState(null)
    const [teacherEmail, setTeacherEmail] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [companionName, setCompanionName] = useState(null)
    const [errors, setErrors] = useState({
        email: {
            value: '',
            isValid: false,
            error: ''
        },
        phone: {
            value: '',
            isValid: false,
            error: ''
        },
    })
    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/visite/nouvelle-demande'] });

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const handleChange = (event) => {
        const {name, value} = event.target;

        setSchoolInfos(name, value)
    }

    const validationField = (event) => {
        const input = event.target;
        let inputValidationResult = false;
        let inputValidationError= '';
        switch (input.name) {
            case 'email':
                inputValidationResult = mailRegex.test(input.value) && input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Veuillez entrer une adresse email valide !';
                if (inputValidationResult === true) {
                    setSchoolInfos(input.name, input.value)
                }
                break;
            case 'phone':
                inputValidationResult = phoneRegex.test(input.value) && input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Veuillez entrer un numéro de téléphone valide !';
                if (inputValidationResult === true) {
                    setSchoolInfos(input.name, input.value)
                }
                break;
            default:
                inputValidationResult = false;
        }

        setErrors({
            ...errors,
            [input.name]: {
                ...errors[input.name],
                value: input.value,
                isValid: inputValidationResult,
                error: inputValidationError
            }
        })
    }

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'left',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left' }}>Nouvelle demande</h1>
            <h2 className='page-subtitle' style={{ textAlign:'left' }}>2. Informations utilisateur</h2>
        </div>
        <div style={{ padding:'0 3%', width:'100%', boxSizing:'border-box', marginTop:50 }}>
            <input type='text' className='input-text' name="teacherName" style={{ background:'#F0F0F0', width:'-webkit-fill-available' }} placeholder="Nom et Prénom de l'enseignant" value={teacherName} onChange={handleChange}></input>
            <input type='text' className='input-text' name="companionName" style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop:20 }} placeholder="Nom et Prénom de l'accompagnateur" value={companionName} onChange={handleChange}></input>
            <input type='text' name="email" className='input-text' style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop:20, border:`${errors.email.value !== '' ? errors.email.error !== '' ? '1px solid red' : '1px solid green' : 'none'}` }} placeholder="Adresse email de l'enseignant" value={teacherEmail} onChange={(e)=> validationField(e)}></input>
            <input type='text' name="phone" className='input-text' style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop:20, border:`${errors.phone.value !== '' ? errors.phone.error !== '' ? '1px solid red' : '1px solid green' : 'none'}` }} placeholder="N° de téléphone" value={phoneNumber} onChange={(e)=> validationField(e)}></input>
            <div onClick={() => {if(phoneRegex.test(errors.phone.value) && mailRegex.test(errors.email.value) && errors.email.isValid && errors.phone.isValid){ history.push({pathname:'/visite/nouvelle-demande/3', infos:{...location.infos, teacherEmail, teacherName, phoneNumber}})}}} className='prev-next' style={{ color:'#0578BE', backgroundColor:'white', width:50, height:50, display:'flex', alignItems:'center', justifyContent:'center', border:'2px solid #0578BE', borderRadius:10, padding:'0 8px', boxShadow:'0 4px 4px rgba(0,0,0,0.25)', marginTop:50, marginLeft:'auto', marginRight:0, boxSizing:'border-box', zIndex:50 }}>
                <ReactSVG onClick={() => {if(phoneRegex.test(errors.phone.value) && mailRegex.test(errors.email.value) && errors.email.isValid && errors.phone.isValid){ history.push({pathname:'/visite/nouvelle-demande/3', infos:{...location.infos, teacherEmail: errors.email.value, teacherName, phoneNumber: errors.phone.value}})}}} style={{ width:'fit-content', height:'fit-content', zIndex:15 }} src="/images/chevron-blanc.svg" beforeInjection={svg => {svg.setAttribute('style', 'width: 14px; height:30px'); console.log(svg.querySelector('path').setAttribute('stroke', '#0578BE'))}} />
            </div>
        </div>
        
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappVisiteDemande2;