import React from 'react';
import { Link, Redirect } from "react-router-dom";

import './header-school.scss';
import useWindowDimensions from '../../utils/dimension';

const HeaderSchool = ({messageNotif, clearNotif}) => {

  const { height, width } = useWindowDimensions();

  if(messageNotif && messageNotif.message != '') {
    window.scrollTo(0, 0);
  }

  if(messageNotif && messageNotif.message != '') {
    setTimeout(() => {
    clearNotif()
    console.log('clear')
    }, 10000);
  }

  return (
    <>
      <div className="header-wrapper" style={{overflow:'hidden', position:'relative'}}>
        <div style={{position: 'absolute', top:0, left:0, display:'flex', justifyContent:'center', width:'100%'}}>
            <img src="/images/fond_3x_line.png" style={{height:240, width:1920, zIndex:0 }}></img>
        </div>
        <div className="inner-wrapper" style={{display:'flex', justifyContent:'space-between', alignItems:'center', height:'100%'}}>
          <Link to="/" style={{zIndex:500}}><img alt="logo maison de l'ia" src="/images/LOGO-MIA_blanc_rouge.png" style={width < 500 ? {margin:'15px 5px'} : {margin:'15px 40px'} }></img></Link>
          <Link to='/deconnexion' className="espace-etablissement">
            <div>
              <img alt='deconnexion' src='/images/logout.svg' style={{ width:28, height:28, marginBottom:0 }}></img>
              <div>deconnexion</div>
            </div>
          </Link>
        </div>
      </div>
      <div>
        {messageNotif && messageNotif.message != "" ? 
          <div className={messageNotif.good ? 'good-notif' : 'bad-notif'} style={{ position:"relative" }}>
              {messageNotif.message}
              <img src='/images/cross-icon.svg' style={{height:20, width:20, position:"absolute", top:10, right:10}} onClick={() => clearNotif()} />
          </div> : <></>}
      </div>
    </>
  );
};

export default HeaderSchool;