// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import ReactLoading from 'react-loading';
import Rdate from 'react-datetime';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/visite', breadcrumb: 'Réserver une visite' },
    { path: '/visite/nouvelle-demande', breadcrumb: 'Nouvelle demande - étape 1' },
];

// == Composant
const WebappVisiteDemande = ({ location, match, waitingRequest, me, datas, connected, setSchoolInfos }) => {

    const [etName, setEtName] = useState(null)
    const [classeName, setclasseName] = useState(null)
    const [annee, setannee] = useState(null)
    const [nbEleve, setnbEleve] = useState(null)
    const [init, setInit] = useState(false)
    const breadcrumbs = useBreadcrumbs(routes);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        me()
    },[])

    if(datas.school && !init) {
        setEtName(datas.school.name);
        setInit(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const handleChange = (event) => {
        const {name, value} = event.target;

        setSchoolInfos(name, value)
    }

    return (
    <>
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'left',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left' }}>Nouvelle demande</h1>
            <h2 className='page-subtitle' style={{ textAlign:'left' }}>1. Informations établissement et classe</h2>
        </div>
        <div style={{ padding:'0 3%', width:'100%', boxSizing:'border-box', marginTop:50 }}>
            <input type='text' className='input-text' style={{ background:'#F0F0F0', width:'-webkit-fill-available' }} placeholder="Nom de l'établissement" value={etName} ></input>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', margin:'20px 0' }}>
                <input type='text' placeholder='Année scolaire' name="year" className='input-text' style={{ background:'#F0F0F0', width:'45%' }} value={annee} onChange={handleChange}>
                </input>
                <input type='text' placeholder="Nombre d'élèves" name="studentNumber" className='input-text' style={{ background:'#F0F0F0', width:'45%', border:`${nbEleve?'1':'0'}px solid ${ isNaN(nbEleve)?'red':'green'}` }} value={nbEleve} onChange={handleChange}>
                </input>
            </div>
            <input type='text' className='input-text' name="classe" style={{ background:'#F0F0F0', width:'-webkit-fill-available' }} placeholder="Nom de la classe" value={classeName} onChange={handleChange}></input>
            <Link to={{pathname:'/visite/nouvelle-demande/2', infos:{classe:classeName, year:annee, studentNumber:nbEleve}}} className='prev-next' style={{ color:'#0578BE', backgroundColor:'white', width:50, height:50, display:'flex', alignItems:'center', justifyContent:'center', border:'2px solid #0578BE', borderRadius:10, padding:'0 8px', boxShadow:'0 4px 4px rgba(0,0,0,0.25)', marginTop:50, marginLeft:'auto', marginRight:0, boxSizing:'border-box' }}>
                <ReactSVG style={{ width:'fit-content', height:'fit-content' }} src="/images/chevron-blanc.svg" beforeInjection={svg => {svg.setAttribute('style', 'width: 14px; height:30px'); console.log(svg.querySelector('path').setAttribute('stroke', '#0578BE'))}} />
            </Link>
        </div>
        
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappVisiteDemande;