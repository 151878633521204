// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import RolesAjout from '../../screen/RolesAjout';

// Action Creators
import { getUser, editUser, addUser } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUser: (id) => {
    dispatch(getUser(id));
  },
  editUser: (id, mail, password) => {
    dispatch(editUser(id, mail, password, ownProps.history));
  },
  addUser: (email, mdp, etablissement, classroom, lastName, firstName, notif) => {
    dispatch(addUser(email, mdp, etablissement, classroom, lastName, firstName, notif))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RolesAjout);