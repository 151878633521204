// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import ReactPlayer from 'react-player'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

// == Composant
const VoirContenus = ({ location, history, match, waitingRequest, datas, getTool, acceptTool, refusedTool, connected, getCat, cats }) => {

    const [allChecked, setAllchecked] = useState(false);
    const [content, setcontent] = useState(null);
    const [displayActions, setdisplayActions] = useState(false);
    const [cat, setcat] = useState(null);
    const [categories, setCategories] = useState([]);
    const [init, setinit] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getCat();
        getTool(match.params.id)
    }, [])

    if(datas && datas['@id'] === `/api/tools/${match.params.id}` && cats['@id'] === '/api/categories' && !init) {
        setcontent(datas)
        setcat(datas.category ? datas.category.id : null);
        setCategories(cats['hydra:member'])
        setinit(true)
    }

    const checkAll = (e) => {
        console.log('event', e.target.checked)
        if(e.target.checked === true) {
            setAllchecked(true);
        } else {
            setAllchecked(false)
        }

        content.forEach(element => {
            element.selected = !allChecked;
        });
    }

    const onDocumentLoadSuccess = (numPages) => {
        setNumPages(numPages);
    }

    const onClickDisplayActions = () => {
        if (displayActions === true) {
            setdisplayActions(false);
        } else {
            setdisplayActions(true);
        }
    }

    if(waitingRequest > 0 && content == null || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }
if(content) console.log(content.fileUrl)
    return (
        <>
                <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase', fontWeight:500  }}>Demande de contenu</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                <div className='grey-background' style={{ marginBottom: 50 }}>
                    {/*<h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase', fontWeight:500  }}>Détail de la réservation</h2>*/}
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Nom du contenu</p>
                    <p style={{ marginBottom: 30,  }}>{content && content.title}</p>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Type de contenu</p>
                    <p style={{ marginBottom: 30,  }}>{content && content.contentType}</p>
                    {content && (content.contentType == 'image/jpeg' || content.contentType == 'image/png') && <img style={{height:200, width:200}} src={content && 'https://mia.api.wacan-extranet.com/'+content.fileUrl} />}
                    {content && content.contentType == 'application/pdf' && (
                        <div>
                            <Document
                                file={{ url: "https://mia.api.wacan-extranet.com"+content.fileUrl }}
                                onLoadSuccess={({numPages}) => onDocumentLoadSuccess(numPages)}
                                loading="Loading PDF…"
                                onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
                                onSourceError={(error) => console.log('Error while retrieving document source! ' + error.message)}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <p>Page {pageNumber} of {numPages}</p>
                        </div>
                    )}
                    {content && content.contentType == 'vidéo' && ReactPlayer.canPlay(content.fileUrl) && <ReactPlayer url={content.fileUrl} />}
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 50, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase', fontWeight:500  }}>Information de contact</h2>
                    <div className='table-wrapper' style={{ width:'-webkit-fill-available' }}>
                        <table style={{ border:'1px solid #0578BE' }}>
                            <thead>
                                <tr>
                                    <th>Nom et Prénom de l'utilisateur</th>
                                    <th>Adresse email</th>
                                    <th>n° de téléphone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{content ? `${content.uploaderName} ${content.uploaderLastname}` : null}</td>
                                    <td>{content && content.uploaderEmail}</td>
                                    <td>0606060606</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 150, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                {content && content.statusAccepted != 'accepted' && <><h2 className='page-subtitle' style={{ marginBottom: 15, textTransform:'uppercase', fontWeight:500  }}>Catégorie</h2>
                    <p>Indiquez la catégorie associée à ce contenu dans le cas où vous souhaitez l'ajoutez à la bibliotheque de contenu.</p>
                    <div className="white-background" style={{ boxShadow:'0px 4px 4px rgba(0,0,0,0.25)', border:' 1px solid #C4C4C4', borderRadius:10, padding: 15, width: 'fit-content', textAlign:'left', margin:'20px 0' }}>
                    {
                        categories.map((element) => (
                            <div>
                                <label htmlFor={element.id}>
                                    <input type="radio" checked={cat == element.id} id={element.id} style={{width:'auto'}} name="category" value={element.title} onClick={(e) => {setcat(e.target.id)}} />
                                    {element.title}
                                </label>
                            </div>
                        ))
                    }
                    </div></>}
                    {content && content.statusAccepted != 'accepted' && <><h2 className='page-subtitle' style={{ marginBottom: 10, textTransform:'uppercase', fontWeight:500  }}>Statut de la demande</h2>
                    <div style={{ display:'flex'  }}>
                        <button onClick={() => {acceptTool(content.id, '+accepted', cat, history)}} className='button' style={{ marginTop:20, marginRight:20 }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                        <button onClick={() => {refusedTool(content.id, '+refused', cat, history)}} className='button' style={{ marginTop:20 }}><img src='/images/cross-icon-blue.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Refuser</button>
                    </div></>}
                </div>
        </div>
        </>
    )
};

// == Export
export default VoirContenus;