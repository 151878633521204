// == Import : npm
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// == Import : local
import reducer from './reducer';
import logMiddleware from './logMiddleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['connected']
}

const persistedReducer = persistReducer(persistConfig, reducer);

// == Store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(
  applyMiddleware(logMiddleware),
);

const store = createStore(
  persistedReducer,
  enhancers,
);

const configureStrore = () => {
  const store = createStore(
    persistedReducer,
    enhancers,
  );
  let persistor = persistStore(store);
  return { store, persistor };
}

// == Export
export default configureStrore;