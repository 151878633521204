// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';
import './visit.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../containers/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/visite', breadcrumb: 'Réserver une visite' },
];

// == Composant
const WebappVisite = ({ location, match, waitingRequest, getVisitesRequest, datas, connected, cancelSchoolVisitRequest }) => {

    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes);

    const [visitRequest, setVisitRequest] = useState([])
    const [init, setInit] = useState(false)
    const [showModalVisit, setShowModalVisit] = useState(false)
    const [infoModalVisit, setInfoModalVisit] = useState()

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        getVisitesRequest()
    }, [])

    if(datas['@id'] === '/api/visit_requests' && !init) {
        setVisitRequest(datas['hydra:member']);
        setInit(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          justifyContent:'space-between',
          //height:307,
          width:275,
          padding: '30px 40px',
          color: '#0578BE',
          boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
          borderRadius:10
        }
      };

    const statusName = (status) => {
        switch (status) {
            case 'pending':
              return 'En attente'
            case 'accepted':
                return 'Accepté'
            case 'canceled':
                return 'Annulé'
            default:
                return 'Refusé'
        }
    }

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        {/*<MenuVisite currentMenuItem={1} />*/}
        <div style={{ display:'flex', justifyContent:'space-between',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left', marginBottom:0 }}>Mes réservations</h1>
            {/* <Link to='/visite/nouvelle-demande' className='button-shadow' style={{ width:'fit-content', backgroundColor:'#0578BE', color:'white', padding:10, borderRadius:10 }}>
                <div style={{ width: 'fit-content',marginLeft: 'auto',marginRight: 'auto',display: 'flex',flexDirection: 'row-reverse',alignItems: 'center' }}>
                    <p style={{ fontSize:15, fontFamily:'Roboto',marginLeft:10, textTransform:'uppercase' }}>Nouvelle Demande</p>
                    <img src='/images/plus-button-white.svg' style={{ height:20, width:20, margin:0 }}></img>
                </div>
            </Link> */}
        </div>
        <div style={{ padding:'0 3%', width:'100%', boxSizing:'border-box' }}>
            <h2 className='page-title' style={{textAlign:'left', marginTop:25}}>Mes réservations à venir</h2>
            <div className="page-visit_card-pending-container" style={{display:'flex', boxSizing:'border-box', flexWrap:'wrap'}}>
                {init ? visitRequest.map((element) => (
                    element.visit.end && moment(element.visit.end) >= moment() && element.statusAccepted !== 'canceled' && element.statusAccepted !== 'refused' ? (
                    <div className="visit_card-pending" key={element['@id']}>
                        <div style={{textDecoration:'none', cursor:'pointer'}} onClick={() => {
                            if(element.statusAccepted !== 'pending') {
                                return
                            } else {
                                setShowModalVisit(true); 
                                setInfoModalVisit(element);
                            }
                            }}>
                            <div className='button-shadow'>
                                <div style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                                    <p style={{fontWeight:'bold', marginBottom:12}}>N°{element['@id'].split('/')[3]}</p>
                                    <p style={{marginBottom:12}}>Statut: {statusName(element.statusAccepted)}</p>
                                    <p>{moment(element.visit.start).format('lll')}</p>
                                    <p>{moment(element.visit.end).format('lll')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : null
                )) : <></>}
            </div>
            <h2 className='page-title' style={{textAlign:'left', marginTop:40}}>Toutes mes réservations</h2>
            <div className="page-visit_card-all-container" style={{display:'flex', boxSizing:'border-box', flexWrap:'wrap'}}>
                {init ? visitRequest.map((element) => (
                    moment(element.visit.end) < moment() || (element.statusAccepted == 'canceled' || element.statusAccepted == 'refused') ? (
                        <div className="visit_card-all" key={element['@id']}>
                            <div style={{textDecoration:'none', cursor:'pointer'}}>
                                <div className='button-shadow'>
                                    <div style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                                        <p style={{fontWeight:'bold', marginBottom:12}}>N°{element['@id'].split('/')[3]}</p>
                                        <p style={{marginBottom:12}}>Statut: {statusName(element.statusAccepted)}</p>
                                        <p>{moment(element.visit.start).format('lll')}</p>
                                        <p>{moment(element.visit.end).format('lll')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                )) : <></>}
            </div>
        </div>
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    <Modal
    isOpen={showModalVisit}
    //onAfterOpen={afterOpenModal}
    onRequestClose={() => {
        setShowModalVisit(false);
    }}
    style={customStyles}
    contentLabel="Example Modal"
    >
        <div style={{position:'relative', width:'100%'}}>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%' }}>
                <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="2x" icon={faTimes} onClick={() => {setShowModalVisit(false)}} />
            </div>
            <div style={{ width:'100%', marginBottom:'28px', textAlign:'left' }}>
                <p style={{fontSize:'16px'}}>Statut : {infoModalVisit ? statusName(infoModalVisit.statusAccepted) : null}</p>
                <br />
                <p style={{fontSize:'16px'}}>Date de début : {infoModalVisit ? moment(infoModalVisit.visit.start).format('lll') : null}</p>
                <br/>
                <p style={{fontSize:'16px'}}>Date de fin : {infoModalVisit ? moment(infoModalVisit.visit.end).format('lll') : null}</p>
            </div>
            <button style={{ border:'none', background:'transparent', color:'red', textTransform:'uppercase', fontSize:14, marginTop:10, fontWeight:'bold', cursor:'pointer' }} onClick={() => {cancelSchoolVisitRequest(parseInt(infoModalVisit['@id'].split('/')[3])); setShowModalVisit(false)}}>Annuler ma réservation</button>
        </div>
    </Modal>
    </>
    )
};

// == Export
export default WebappVisite;