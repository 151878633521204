// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import CategoryEdit from '../../screen/CategoryEdit';

// Action Creators
import { editCat, getACat } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editCat: (id, title, image) => {
    dispatch(editCat(id, title, image, ownProps.history));
  },
  getACat: (id) => {
    dispatch(getACat(id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryEdit);