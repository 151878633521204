// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ContenusWebapp from '../../screen/ContenusWebapp';

// Action Creators
import { setCurrentMenuItem } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*setCurrentMenuItem: (item) => {
      console.log(item)
    dispatch(setCurrentMenuItem(item));
  },*/
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContenusWebapp);