// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappVisiteDemande from '../../screen/WebappVisiteDemande';

// Action Creators
import { me, setSchoolInfos } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  me: () => {
    dispatch(me());
  },
  setSchoolInfos: (name, value) => {
    dispatch(setSchoolInfos(name, value))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappVisiteDemande);