// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import ForgotPassword from '../../screen/ForgotPassword';

// Action Creators
import { forgetPassword } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  forgetPassword: (email, history) => {
    dispatch(forgetPassword(email, history))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);