// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import QuizAjout from '../../screen/QuizAjout';

// Action Creators
import { createQuizz, getQuizByStatus } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  quizzStatus: state.quizzStatus
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createQuizz: (name, statut, questionFile, answerFile) => {
    dispatch(createQuizz(name, statut, questionFile, answerFile, ownProps.history));
  },
  getQuizByStatus: () => {
    dispatch(getQuizByStatus())
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuizAjout);