// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment'

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';
import Pagination from '../../components/Pagination';

// == Composant
const Contenus = ({ location, history, match, waitingRequest, getToolsPending, pendingTools, getAcceptedTool, acceptedTools, connected, deleteEntity }) => {

    const [allChecked, setAllchecked] = useState(false);
    const [contentPending, setcontentPending] = useState([]);
    const [contentAccept, setcontentAccept] = useState([]);
    const [displayActions, setdisplayActions] = useState(false);
    const [init, setInit] = useState(false);
    const [init2, setInit2] = useState(false);
    const [checkedAllPending, setCheckedAllPending] = useState(false);
    const [checkedAllAccept, setCheckedAllAccept] = useState(false);
    const [selectedPending, setSelectedPending] = useState(null);
    const [selectedAccepted, setSelectedAccepted] = useState(null);
    const [datasPendingTool, setDatasPendingTool] = useState(null)
    const [page, setPage] = useState({
        datas: [],
        currentPage: 1,
        datasPerPage: 15
      })

    useEffect(()=> {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')) {
            getToolsPending();
            getAcceptedTool(match.params.page)
        }
    }, [])
    //console.log('tools', pendingTools)

    if(pendingTools && pendingTools['@id'] === '/api/tools' && !init) {
        setPage({
            ...page,
            datas: pendingTools['hydra:member']
        })
        setcontentPending(pendingTools['hydra:member']);
        setInit(true)
    }
    if(acceptedTools && acceptedTools['@id'] === '/api/tools' && !init2) {
        setcontentAccept(acceptedTools['hydra:member']);
        setInit2(true)
    }

    const indexOfLastTodo = page.currentPage * page.datasPerPage;
    const indexOfFirstTodo = indexOfLastTodo - page.datasPerPage;
    const currentDatas = contentPending.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(contentPending.length / page.datasPerPage); i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <>
                <li
                    key={number}
                    id={number}
                    className='page-number'
                    style={{cursor:'pointer'}}
                    onClick={(e) => setPage({
                        ...page,
                        currentPage: Number(e.target.id)
                    })}
                >
                    {number}
                </li>
            </>
        );
    });

    const checkAllPending = (e) => {
        if (e.target.name === "checkbox-pending" && e.target.checked === true) {
            setCheckedAllPending(true);
        } else {
            setCheckedAllPending(false);
        }

        contentPending.forEach(element => {
            element.selected = !checkedAllPending;
        });
    }

    const checkAllAccepted = (e) => {
        if (e.target.name === "checkbox-accept" && e.target.checked === true) {
            setCheckedAllAccept(true);
        } else {
            setCheckedAllAccept(false);
        }

        contentAccept.forEach(element => {
            element.selected = !checkedAllAccept;
        });
    }

    const handleAction = () => {
        if(selectedPending === 'delete') {
            contentPending.forEach(element => {
                if(element.selected === true) {
                    deleteEntity('tools', element['@id'].split('/')[3])
                }
            })
        } else if(selectedAccepted === 'delete') {
            contentAccept.forEach(element => {
                if(element.selected === true) {
                    deleteEntity('tools', element['@id'].split('/')[3])
                }
            })
        }
    }

    console.log(history)

    const onClickDisplayActions = () => {
        if (displayActions === true) {
            setdisplayActions(false);
        } else {
            setdisplayActions(true);
        }
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

      return (
          <>
            <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                  <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between' }}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                                <h1 className='page-title' style={{ textTransform:'uppercase' }}>Gestion des contenus</h1>
                                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                            </div>
                            <h2 className='page-subtitle' style={{ textTransform:'uppercase' }}>Nouvelles demandes d'ajout</h2>
                        </div>
                  </div>
                  <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'20px 0%' }}>
                    <div>
                        <select name="actions" id="actions"  className='button button-shadow' style={{ marginRight:20 }} onChange={(e) => {setSelectedPending(e.target.value)}}>
                            <option value="">Action groupée</option>
                            <option value="delete">Supprimer</option>
                        </select>
                        <input type='submit' className='button button-shadow' value='Appliquer' onClick={() => {handleAction()}}></input>
                    </div>
                </div>
                  <div className='table-wrapper'>
                  <table style={{ tableLayout:'auto' }}>
                        <colgroup>
                            <col span="1" style={{width: '5%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{ width:'fit-content' }}><input type='checkbox' name="checkbox-pending" onClick={checkAllPending}></input></th>
                                <th>contenus</th>
                                <th>Ajouté par</th>
                                <th>établissement</th>
                                <th>date d'ajout</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentDatas.map((element) => (
                                <tr key={element.id}>
                                    <td style={{ width:'fit-content' }}><input checked={element.selected} type='checkbox' onClick={() => {console.log(element.selected); element.selected = !element.selected; setcontentPending([...contentPending]); console.log(allChecked)}}></input></td>
                                    <td>{element.title}</td>
                                    <td>{element.uploaderName}</td>
                                    <td>{element.school ? element.school.name : null}</td>
                                    <td>{moment(element.addedAt).format('ll')}</td>
                                    <td style={{textAlign: 'right', paddingRight:20, fontStyle:'italic'}}>
                                        <Link to={`/admin/parametres/contenus/voir/${element.id}`}>Voir</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <ul id="page-numbers" style={{listStyle: 'none', display:'flex'}}>
                            <li className="prev-next" style={{cursor:'pointer'}} onClick={(e) => setPage({
                                    ...page,
                                    currentPage: page.currentPage === 1 ? 1 : page.currentPage - 1
                                })}
                                style={{display:`${page.currentPage === 1 ? 'none' : 'block' }`}}
                            >
                                {'<'}
                            </li>
                            {renderPageNumbers}
                            <li className="prev-next">
                                {'>'}
                            </li>
                        </ul>
                    </div>
                  </div>


                  <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between' }}>
                        <h2 className='page-subtitle' style={{ textTransform:'uppercase' }}>contenus validés</h2>
                        </div>
                  </div>
                  <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'20px 0%' }}>
                    <div>
                        <select name="actions" id="actions"  className='button button-shadow' style={{ marginRight:20 }} onChange={(e) => {setSelectedAccepted(e.target.value)}}>
                            <option value="">action groupée</option>
                            <option value="delete">Supprimer</option>
                        </select>
                        <input type='submit' className='button button-shadow' value='Appliquer' onClick={() => {handleAction()}}></input>
                    </div>
                </div>
                  <div className='table-wrapper' style={{ marginBottom:50 }}>
                  <table style={{ tableLayout:'auto' }}>
                        <colgroup>
                            <col span="1" style={{width: '5%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{ width:'fit-content' }}><input type='checkbox' name="checkbox-accept" onClick={checkAllAccepted}></input></th>
                                <th> Contenu</th>
                                <th>ajouté par</th>
                                <th>établissement</th>
                                <th>date d'ajout</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {contentAccept.map((element) => (
                                <tr key={element.id}>
                                    <td style={{ width:'fit-content' }}><input checked={element.selected} type='checkbox' onClick={() => {console.log(element.selected); element.selected = !element.selected; setcontentAccept([...contentAccept]); console.log(allChecked)}}></input></td>
                                    <td>{element.title}</td>
                                    <td>{element.uploaderName}</td>
                                    <td>{element.school ? element.school.name : null}</td>
                                    <td>{moment(element.addedAt).format('ll')}</td>
                                    <td style={{textAlign: 'right', paddingRight:20, fontStyle:'italic'}}>
                                        <Link to={`/admin/parametres/contenus/voir/${element.id}`}>Voir</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='pagination'>
                        {/*<Link className='button buton-link' to='/admin/contenus/add'>Voir plus</Link>*/}
                        <Pagination datas={acceptedTools} url={'/admin/parametres/contenus/'} match={match} />
                    </div>
                  </div>
            </div>
            </>
      )
};

// == Export
export default withRouter(Contenus);