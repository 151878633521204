// == Import : npm
import React, {useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const VoirReservation = ({ location, history, match, waitingRequest, datas, getSchool, editSchools, connected }) => {

    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [phone, setPhone] = useState(null);
    const [mail, setMail] = useState(null);
    const [principale, setPrincipale] = useState(null);
    const [role, setRole] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [init, setInit] = useState(false);
    const [tools, setTools] = useState(null);
    const [visitRequest, setVisitRequest] = useState(null);

    useState(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getSchool(match.params.id)
    }, [])

    if(datas['@id'] === `/api/schools/${match.params.id}` && !init) {
        setName(datas.name);
        setAdresse(datas.adress);
        setPhone(datas.phoneNumber);
        setPrincipale(datas.principalName);
        setZipCode(datas.zipCode);
        setTools(datas.tools);
        setVisitRequest(datas.visitRequests);
        setInit(true);
    }


    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase', fontWeight:500  }}>Fiche établissement</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                <div className='grey-background' style={{ marginBottom: 50, position:'relative' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase', fontWeight:500  }}>Coordonnées de l'établissement</h2>
                    <p style={{ marginBottom: 15, textTransform:'uppercase', fontWeight:500 }}>Nom de l'établissement</p>
                    {editMode ? <input type='text' className="input-text" value={name} onChange={(e) => {setName(e.target.value)}}></input> : <p style={{ marginBottom: 30,  }}>{name}</p>}
                    <p style={{ marginBottom: 15, textTransform:'uppercase', fontWeight:500, marginTop:editMode?30:0  }}>Adresse postale</p>
                    {editMode ? <input type='text' className="input-text" value={adresse} onChange={(e) => {setAdresse(e.target.value)}}></input> : <p style={{ marginBottom: 30,  }}>{adresse}</p>}
                    <p style={{ marginBottom: 15, textTransform:'uppercase', fontWeight:500, marginTop:editMode?30:0  }}>Code postal</p>
                    {editMode ? <input type='text' className="input-text" value={zipCode} onChange={(e) => {setZipCode(e.target.value)}}></input> : <p style={{ marginBottom: 30,  }}>{zipCode}</p>}
                    <p style={{ marginBottom: 15, textTransform:'uppercase', fontWeight:500, marginTop:editMode?30:0  }}>Numéro de téléphone</p>
                    {editMode ? <input type='text' className="input-text" value={phone} onChange={(e) => {setPhone(e.target.value)}}></input> : <p style={{ marginBottom: 30,  }}>{phone}</p>}
                    <p style={{ marginBottom: 15, textTransform:'uppercase', fontWeight:500, marginTop:editMode?30:0  }}>Nom du principal de l'établissement</p>
                    {editMode ? <input type='text' className="input-text" value={principale} onChange={(e) => {setPrincipale(e.target.value)}}></input> : <p style={{ marginBottom: 30,  }}>{principale}</p>}
                    {editMode ? <button className='button' style={{ marginTop:20 }} onClick={() => {editSchools(name, principale, adresse, phone, zipCode, history, match.params.id)}}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button> :<button className='button' style={{ position:'absolute', top:19, right:30 }} onClick={() => {setEditMode(true)}}>Modifier</button>}
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 50, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                        {/*<label style={{ marginTop: 0, textTransform: 'uppercase', fontSize:20, marginBottom:10, fontWeight:500 }}>Rôle de l'utilisateur</label>
                        <select name="role" id="role-select" className='button' style={{ height:'fit-content', marginBottom:40 }} onChange={(e) => {setRole(e.target.value)}}>
                            <option value="">Selectionner un rôle</option>
                            <option value="ROLE_USER" selected={role === 'ROLE_USER'}>Etablissement</option>
                            <option value="ROLE_ADMIN" selected={role === 'ROLE_ADMIN'}>admin</option>
                            <option value="" selected={role === ''}>anonyme</option>
                        </select>*/}
                    <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase', fontWeight:500  }}>Visites</h2>
                    {visitRequest && visitRequest.length > 0 ? (
                        <div className='table-wrapper' style={{ width:'100%' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:'left', paddingLeft:60}}>Classe</th>
                                        <th>Début de l'événement</th>
                                        <th>Fin de l'événement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {visitRequest.map((elem) => (
                                        <tr>
                                            <td style={{textAlign:'left', paddingLeft:60}}>{elem.classe}</td>
                                            <td>{moment(elem.visit.start).format('llll')}</td>
                                            <td>{moment(elem.visit.end).format('llll')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>Cet établissement n'a pas encore fait de demande de visite.</p>
                    )}
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 150, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase', fontWeight:500  }}>Demande d'ajout de contenus</h2>
                    {tools && tools.length > 0 ? (
                        <div className='table-wrapper' style={{ width:'100%' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:'left', paddingLeft:60}}>Nom</th>
                                        <th>adresse mail</th>
                                        <th>statut</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tools.map((elem) => (
                                        <tr>
                                            <td style={{textAlign:'left', paddingLeft:60}}>{`${elem.uploaderLastname} ${elem.uploaderName}`}</td>
                                            <td>{elem.uploaderEmail}</td>
                                            <td>{elem.statusAccepted}</td>
                                            <td style={{fontStyle:'italic'}}>
                                                <Link to={`/admin/parametres/contenus/voir/${elem.id}`}>Voir</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>Cet établissement n'a pas encore fait de demande d'ajout de contenu.</p>
                    )}
                </div>
            </div>
        </>
    )
};

// == Export
export default VoirReservation;