// == Import : npm
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

// == Import : local
import '../../style/style.scss';
//import './header.scss';

// == Composant
const Actu = () => {
    
    const [displayActu, setDisplayActu] = useState(false);
    return <></>

    return (
        <div className='actu-bottom' style={{}}>
        <button style={{textDecoration:'none', border:'none', padding:0, borderRadius:'10px 10px 0 0', overflow:'hidden', width:'100%' }} onClick={ () => { console.log(displayActu);setDisplayActu(!displayActu) }}>
            <div className='button-shadow' style={{ maxWidth:'100%', maxHeight:69,  backgroundColor:'#0578BE', color:'white', fontSize:20, fontWeight:400, textTransform:'uppercase', display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'space-between', padding:'10px 50px'}}>
                <div className='actu-area-title' style={{  }}>
                    <img src='/images/flux.svg' style={{ height:40, width:40, margin:0, marginRight:40 }}></img>
                    <div>
                        Actualité 1 : voici une actualité sur la maison de l'IA
                    </div>
                </div>
                {displayActu ? <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0, transform:'rotate(90deg)' }}></img> : <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0, transform:'rotate(-90deg)' }}></img>}
            </div>
        </button>
        {displayActu ? <div style={{ height:'calc(100vh - 300px)', backgroundColor:'rgba(1, 5, 37, 0.85)', overflowY:'scroll' }}>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
            <div className='actu-card'>
                <img style={{ backgroundColor:'blue', height:150, width:150 }}></img>
                <div>
                    <h3>Title</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                </div>
            </div>
        </div> : <></>}
    </div>
    );
};

// == Export
export default Actu;