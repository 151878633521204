// == Import : npm
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Alveoles = ({ location, match, waitingRequest, getalveoles, datas, createAvleole, deleteEntity, connected }) => {

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        {
            getalveoles();
        }
    },[])

    //console.log(connected.roles.find(element => element =='ROLE_ADMIN'))

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    } else if(datas['@id'] != undefined && datas['@id'] != '/api/alveoles') {
        getalveoles();
   }

    return (
        <>
        <Header currentMenuItem={1} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
            <div style={{ display:'flex',flexDirection: 'column', margin:'100px 0% 10px 0%', marginBottom: 50}}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>VISITES</h1>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h2 className='page-subtitle'>Sélectionner un visites pour accéder aux activités</h2>
                    <button onClick={() => {createAvleole()}} className='button'>Ajouter un visites</button>
                </div>
            </div>
            <div style={{ display:'flex', flexDirection: 'row', justifyContent:'space-between', width:1150, maxWidth:'80vw', flexWrap:'wrap', margin:'0 auto', marginBottom: 150 }}>
                {/*<Link to='/admin/alveoles/territoire' className="alveole"><img src="/images/IA&territoire.png" style={{ height:240, width:240 }}></img></Link>
                <Link to='/admin/alveoles/sante' className="alveole" style={{ margin:'0 215px' }}><img src="/images/IA&santé.png" style={{  height:240, width:240 }}></img></Link>
                <Link to='/admin/alveoles/vie-quotidienne' className="alveole"><img src="/images/IA&vie-quotidienne.png" style={{ height:240, width:240 }}></img></Link>
                <Link to='/admin/alveoles/art-culture-loisir'className="alveole"><img src="/images/IA&culture.png" style={{ height:240, width:240 }}></img></Link>
                <Link to='/admin/alveoles/experimentation' className="alveole" style={{ margin:'0 215px' }}><img src="/images/zone-exp-1.png" style={{ height:240, width:240 }}></img></Link>*/}
                {datas['hydra:member'] ? datas['hydra:member'].map((data) => (
                    <Link to={`/admin/visites/${data.id}`} className="alveole">
                        <img src={`https://mia.api.wacan-extranet.com${data.icon}`} style={{ height:240, width:240 }}></img>
                        <p style={{fontSize:18, fontWeight:'bold'}}>{data.title}</p>
                    </Link>
                )) :<></>}
            </div>
        </div>
        </>
    )
};

// == Export
export default Alveoles;