// == Import : npm
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
    {
        id: 1,
        name: 'test',
        rank: '1',
    },
]

// == Composant
const QuizQuestionAdd = ({ location, history, match, waitingRequest, addQuestions, connected }) => {

    const [title, setTitle] = useState(null);
    const [order, setOrder] = useState(null);
    const [opt1, setOpt1] = useState(null);
    const [opt2, setOpt2] = useState(null);
    const [opt3, setOpt3] = useState(null);
    const [opt4, setOpt4] = useState(null);
    const [goodRep, setGoodRep] = useState(null);


    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={2} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>Ajouter une question</h1>
                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
            </div>
            <div className='grey-background' style={{ marginBottom: 150 }}>
                <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Question</h2>
                <label style={{ marginTop: 10, marginBottom:10 }}>Titre de la question</label>
                <input type='text' className="input-text" placeholder='Titre...' value={title} onChange={(e) => {setTitle(e.target.value)}}></input>
                <label style={{ marginTop: 40, marginBottom:10 }}>Ordre d'apparition de la question</label>
                <input type='text' className="input-text" placeholder='ex : 1' value={order} onChange={(e) => {setOrder(e.target.value)}}></input>
                
                <label style={{ marginTop: 40, textTransform: 'uppercase', fontSize:20, marginBottom:10 }}>Possibilités de réponse a la question</label>
                <label style={{ marginTop: 10, marginBottom:10 }}>Option 1 :</label>
                <input type='text' className="input-text" placeholder='réponse...' value={opt1} onChange={(e) => {setOpt1(e.target.value)}}></input>
                <div style={{ marginTop: 10, marginBottom:20 }}>
                    <input type="radio" id="1" name="drone" value="1" onClick={(e) => {if(e.target.checked) {setGoodRep(e.target.id)}}}/>
                    <label for="1">Sélectionner cette option comme la bonne réponse à donner à la question</label>
                </div>
                <label style={{ marginTop: 10, marginBottom:10 }}>Option 2 :</label>
                <input type='text' className="input-text" placeholder='réponse...' value={opt2} onChange={(e) => {setOpt2(e.target.value)}}></input>
                <div style={{ marginTop: 10, marginBottom:20 }}>
                    <input type="radio" id="2" name="drone" value="2" onClick={(e) => {if(e.target.checked) {setGoodRep(e.target.id)}}} />
                    <label for="2">Sélectionner cette option comme la bonne réponse à donner à la question</label>
                </div>
                <label style={{ marginTop: 10, marginBottom:10 }}>Option 3 :</label>
                <input type='text' className="input-text" placeholder='réponse...' value={opt3} onChange={(e) => {setOpt3(e.target.value)}}></input>
                <div style={{ marginTop: 10, marginBottom:20 }}>
                    <input type="radio" id="3" name="drone" value="3" onClick={(e) => {if(e.target.checked) {setGoodRep(e.target.id)}}} />
                    <label for="3">Sélectionner cette option comme la bonne réponse à donner à la question</label>
                </div>
                <label style={{ marginTop: 10, marginBottom:10 }}>Option 4 :</label>
                <input type='text' className="input-text" placeholder='réponse...' value={opt4} onChange={(e) => {setOpt4(e.target.value)}}></input>
                <div style={{ marginTop: 10, marginBottom:20 }}>
                    <input type="radio" id="4" name="drone" value="4" onClick={(e) => {if(e.target.checked) {setGoodRep(e.target.id)}}} />
                    <label for="4">Sélectionner cette option comme la bonne réponse à donner à la question</label>
                </div>
                <button onClick={() => {addQuestions(title, opt1, opt2, opt3, opt4, parseInt(goodRep), parseInt(order), match.params.id)}} style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
            </div>
        </div>
        </>
    )
};

// == Export
export default QuizQuestionAdd;