// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id:1,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:2,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:3,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:4,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:5,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:6,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:7,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:8,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:9,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:10,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
]

// == Composant
const VoirReservation = ({ location, history, match, waitingRequest, connected, getAVistiRequest, datas, acceptVistiRequest, deleteEntity, cancelVisitRequest, refusedVistiRequest }) => {

    const [allChecked, setAllchecked] = useState(false);
    const [content, setcontent] = useState(testContent);
    const [displayActions, setdisplayActions] = useState(false);

    useEffect(() => {
        getAVistiRequest(match.params.id)
    },[])

    const checkAll = (e) => {
        console.log('event', e.target.checked)
        if(e.target.checked === true) {
            setAllchecked(true);
        } else {
            setAllchecked(false)
        }

        content.forEach(element => {
            element.selected = !allChecked;
        });
    }

    const onClickDisplayActions = () => {
        if (displayActions === true) {
            setdisplayActions(false);
        } else {
            setdisplayActions(true);
        }
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
                <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase', fontWeight:500  }}>Demande de réservation</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                {datas['@id'] == `/api/visit_requests/${match.params.id}` && <><div className='grey-background' style={{ marginBottom: 50, position:'relative' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase', fontWeight:500  }}>Détail de la réservation</h2>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Nom de l'evenement</p>
                    <p style={{ marginBottom: 30,  }}>{datas?.visit.visitType?.title}</p>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Type d'évenement</p>
                    <p style={{ marginBottom: 30,  }}>{datas?.visit?.visitType?.title}</p>
                    {/*<p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Date de réservation</p>
                    <p style={{ marginBottom: 30,  }}>01/03/202 à 20:02</p>*/}
                    <div style={{ display:'flex', flexDirection:'row' }}>
                        <div style={{ marginRight:50 }}>
                            <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Début évenement</p>
                <p style={{ marginBottom: 30,  }}>{moment(datas.visit.start).format('DD/MM/YY HH:mm')}</p>
                        </div>
                        <div>
                            <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Fin évenement</p>
                            <p style={{ marginBottom: 30,  }}>{moment(datas.visit.end).format('DD/MM/YY HH:mm')}</p>
                        </div>
                    </div>
                    <button className='button' style={{ position:'absolute', top:30, right:30 }} onClick={() => {deleteEntity('visit_requests', datas['@id'].split('/')[3]); history.goBack()}}>Supprimer</button>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 50, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase', fontWeight:500  }}>Information sur l'établissement</h2>
                    <div className='table-wrapper' style={{ width:'-webkit-fill-available' }}>
                        <table style={{ border:'1px solid #0578BE' }}>
                            <thead>
                                <tr>
                                    <th>Nom de l'etablissement</th>
                                    <th>classe</th>
                                    <th>nombre d'élèves</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{datas.school.name}</td>
                                    <td>{datas.classe}</td>
                                    <td>{datas.studentNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 150, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase', fontWeight:500  }}>Information de contact</h2>
                    <div className='table-wrapper' style={{ width:'-webkit-fill-available' }}>
                        <table style={{ border:'1px solid #0578BE' }}>
                            <thead>
                                <tr>
                                    <th>Nom de famille</th>
                                    <th>adresse mail</th>
                                    <th>N° de téléphone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{datas.teacherName}</td>
                                    <td>{datas.teacherEmail}</td>
                                    <td>{datas.phoneNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {datas.statusAccepted === 'pending' && moment(datas.visit.end) >= moment() && (
                        <div style={{display:'flex', width:'200px', justifyContent:'space-between'}}>
                            <button onClick={() => {acceptVistiRequest(match.params.id)}} className='button' style={{ marginTop:50, cursor:'pointer' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                            <button onClick={() => {refusedVistiRequest(match.params.id)}} className='button' style={{ marginTop:50, cursor:'pointer' }}><img src='/images/cross-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Refuser</button>
                        </div>
                    )}
                    {datas.statusAccepted === 'accepted' && (
                        <button onClick={() => {cancelVisitRequest(match.params.id)}} className='button' style={{ marginTop:50, cursor:'pointer' }}><img src='/images/cross-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Annuler la réservation</button>
                    )}
                    {datas.statusAccepted === 'canceled' || datas.statusAccepted === 'refused' || (datas.statusAccepted === 'pending' && moment(datas.visit.end) < moment()) && null}
                </div></>}
        </div>
        </>
    )
};

// == Export
export default VoirReservation;