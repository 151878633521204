// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Boite from '../../screen/Boite';

// Action Creators
import { getTools, deleteEntity, getAcceptedTool } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTools: (page) => {
    dispatch(getTools(page));
  },
  getAcceptedTool: (page) => {
    dispatch(getAcceptedTool(page))
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Boite);