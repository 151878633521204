// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import BoiteEdit from '../../screen/BoiteEdit';

// Action Creators
import { getTool, editTool, getCat, setMessageNotif } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  cats:state.cat,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTool: (id) => {
    dispatch(getTool(id));
  },
  editTool: (id, name, cat, file, fileType, videoLink, status, history) => {
    dispatch(editTool(id, name, cat, file, fileType, videoLink, status, history));
  },
  getCat: () => {
    dispatch(getCat());
  },
  setMessageNotif: (message, good) => {
    dispatch(setMessageNotif(message, good));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoiteEdit);