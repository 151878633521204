// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappArchives from '../../screen/WebappArchives';

// Action Creators
import { getMyVisitsRequests } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  MyVisitsRequests: state.MyVisitsRequests,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMyVisitsRequests: () => {
    dispatch(getMyVisitsRequests());
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappArchives);