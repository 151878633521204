// == Import : npm
import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Rdate from 'react-datetime';
import ReactLoading from 'react-loading';


// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Deconnexion = ({ location, match, history, waitingRequest, setConnected }) => {

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            {/*<Header currentMenuItem={6} entries={boEntries} location={location} match={match} />*/}
            <div className='page-wrapper' style={{ minHeight:'calc(100vh - 300px)', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <div className='grey-background' style={{ width:'fit-content', margin:'auto' }}>
                        <h1 style={{ color:"#0578BE" }}>CONFIRMER VOTRE DÉCONNEXION</h1>
                        <div style={{ marginRight:'auto', marginLeft:'auto' }}>
                            <button className='button' style={{ marginRight:50 }} onClick={() => {history.push('/'); setConnected(null); localStorage.removeItem("alreadyLaunched");}}>Oui</button>
                            <button className='button' onClick={() => {history.goBack()}}>Non</button>
                        </div>
                    </div>
            </div>
        </>
    )
};

// == Export
export default Deconnexion;