// == Import : npm
import React, {useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
      {
          id:1,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:2,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:3,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:4,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:5,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:6,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:7,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:8,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:9,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
      {
          id:10,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          visites: 1,
      },
  ]

// == Composant
const ContenusWebapp = ({ location, history, match, waitingRequest, connected }) => {

    const [allChecked, setAllchecked] = useState(false);
    const [content, setcontent] = useState(testContent);
    const [displayActions, setdisplayActions] = useState(false);

    const checkAll = (e) => {
        console.log('event', e.target.checked)
        if(e.target.checked === true) {
            setAllchecked(true);
        } else {
            setAllchecked(false)
        }

        content.forEach(element => {
            element.selected = !allChecked;
        });
    }

    const onClickDisplayActions = () => {
        if (displayActions === true) {
            setdisplayActions(false);
        } else {
            setdisplayActions(true);
        }
    }


    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

      return (
          <>
                  <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                  <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between', width:'100%' }}>
                            <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                                <h1 className='page-title' style={{ textTransform:'uppercase' }}>Gestion contenu webapp publique</h1>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <Link className='button buton-link' to='/admin/contenus/add' style={{ textTransform:'uppercase', style:20 }}>Paramétrer le menu</Link>
                                    <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                                </div>
                            </div>
                            <h2 className='page-subtitle' style={{ textTransform:'uppercase' }}>Pages</h2>
                        </div>
                  </div>
                  <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'20px 0%' }}>
                    <div>
                        <select name="actions" id="actions"  className='button button-shadow' style={{ marginRight:20 }}>
                            <option value="">action groupée</option>
                            <option value="delete">Supprimer</option>
                        </select>
                        <input type='submit' className='button button-shadow' value='Appliquer'></input>
                    </div>
                </div>
                  <div className='table-wrapper'>
                  <table style={{ tableLayout:'auto' }}>
                        <colgroup>
                            <col span="1" style={{width: '5%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{ width:'fit-content' }}><input type='checkbox' onClick={checkAll}></input></th>
                                <th>contenus</th>
                                <th>Date de publication</th>
                                <th>Auteur</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {testContent.map((element) => (
                                <tr>
                                    <td style={{ width:'fit-content' }}><input checked={element.selected} type='checkbox' onClick={() => {console.log(element.selected); element.selected = !element.selected; setcontent([...content]); console.log(allChecked)}}></input></td>
                                    <td>{element.name}</td>
                                    <td>{element.mail}</td>
                                    <td>{element.visites}</td>
                                    <td>
                                        <Link to={`/admin/parametres/contenus/voir/${element.id}`}>Voir</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style ={{ textAlign:'left', marginTop:50, marginLeft:'15%' }}>
                        <Link className='button buton-link' to='/admin/contenus/add'>Voir plus</Link>
                    </div>
                  </div>


                  <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between' }}>
                        <h2 className='page-subtitle' style={{ textTransform:'uppercase' }}>hotspot</h2>
                        </div>
                  </div>
                  <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'20px 0%' }}>
                    <div>
                        <select name="actions" id="actions"  className='button button-shadow' style={{ marginRight:20 }}>
                            <option value="">action groupée</option>
                            <option value="delete">Supprimer</option>
                        </select>
                        <input type='submit' className='button button-shadow' value='Appliquer'></input>
                    </div>
                </div>
                  <div className='table-wrapper' style={{ marginBottom:50 }}>
                  <table style={{ tableLayout:'auto' }}>
                        <colgroup>
                            <col span="1" style={{width: '5%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                            <col span="1" style={{width: '23.75%'}} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{ width:'fit-content' }}><input type='checkbox' onClick={checkAll}></input></th>
                                <th> Contenu</th>
                                <th>Date de publication</th>
                                <th>Auteur</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {testContent.map((element) => (
                                <tr>
                                    <td style={{ width:'fit-content' }}><input checked={element.selected} type='checkbox' onClick={() => {console.log(element.selected); element.selected = !element.selected; setcontent([...content]); console.log(allChecked)}}></input></td>
                                    <td>{element.name}</td>
                                    <td>{element.mail}</td>
                                    <td>{element.visites}</td>
                                    <td>
                                        <Link to={`/admin/parametres/contenus/voir/${element.id}`}>Voir</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style ={{ textAlign:'left', marginTop:50, marginLeft:'15%' }}>
                        <Link className='button buton-link' to='/admin/contenus/add'>Voir plus</Link>
                    </div>
                  </div>
            </div>
            </>
      )
};

// == Export
export default ContenusWebapp;