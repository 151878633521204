// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import EvenementAjout from '../../screen/EvenementAjout';

// Action Creators
import { createVisites, getVisitTypes } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  visitTypes: state.visitTypes,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createVisites: (name, start, end, visitType) => {
    dispatch(createVisites(name, start, end, visitType, ownProps.history));
  },
  getVisitTypes: () => {
    dispatch(getVisitTypes());
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvenementAjout);