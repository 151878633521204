// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import QuizQuestion from '../../screen/QuizQuestion';

// Action Creators
import { getAQuiz, editQuizz, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAQuiz: (id) => {
    dispatch(getAQuiz(id));
  },
  editQuizz: (id, name, statut, questionFile, answerFile, fileQuestionId, fileAnswerId) => {
    dispatch(editQuizz(id, name, statut, questionFile, answerFile, fileQuestionId, fileAnswerId, ownProps.history));
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuizQuestion);