// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Contenus from '../../screen/Contenus';

// Action Creators
import { getToolsPending, getAcceptedTool, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  pendingTools: state.pendingTools,
  acceptedTools: state.acceptedTools,
  connected: state.connected,
  waitingRequest: state.waitingRequest,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getToolsPending: () => {
    dispatch(getToolsPending());
  },
  getAcceptedTool: (datas) => {
    dispatch(getAcceptedTool(datas));
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contenus);