// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination'

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
      {
          id:1,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:2,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:3,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:4,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:5,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:6,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:7,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:8,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:9,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:10,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
  ]

// == Composant
const Roles = ({ location, history, match, waitingRequest, getUsers, datas, connected }) => {

      const [allChecked, setAllchecked] = useState(false);
      const [content, setcontent] = useState(testContent);
      const [displayActions, setdisplayActions] = useState(false);

      useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getUsers(match.params.page)
      }, [])

      if(datas['@id']==='/api/users' && content===testContent) {
          setcontent(datas['hydra:member']);
      }

      const getRoles = (status) => {
        switch (status) {
            case 'pending':
                return 'En attente'
            case 'accepted':
                return 'Accepté'
            case 'refused':
                return 'Refusé'
            default:
                return ''
        }
      }
  
      const checkAll = (e) => {
          console.log('event', e.target.checked)
          if(e.target.checked === true) {
              setAllchecked(true);
          } else {
              setAllchecked(false)
          }
  
          content.forEach(element => {
              element.selected = !allChecked;
          });
      }
  
      const onClickDisplayActions = () => {
          if (displayActions === true) {
              setdisplayActions(false);
          } else {
              setdisplayActions(true);
          }
      }


      if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

      return (
            <>
                  <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                  <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'row', justifyContent:'space-between', marginBottom:50, width:'100%' }}>
                            <h1 className='page-title' style={{ textTransform:'uppercase', margin:0 }}>Gestion des utilisateurs</h1>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <Link to={"/admin/parametres/roles/add"} className={"button"} style={{marginRight:20}}>Ajouter</Link>
                                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                            </div>
                        </div>
                  </div>
                  <div className='table-wrapper'>
                  <table style={{ tableLayout:'auto' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign:'left', paddingLeft:60 }}>Email</th>
                                <th style={{ textAlign:'left', paddingLeft:60 }}>Rôle</th>
                                <th style={{ textAlign:'left', paddingLeft:60 }}>Statut</th>
                                <th style={{ textAlign:'left', paddingLeft:60 }}>établissement</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {content.map((element) => (
                                <tr>
                                    <td style={{ textAlign:'left', paddingLeft:60 }}>{element.email}</td>
                                    <td style={{ textAlign:'left', paddingLeft:60 }}>{element.roles ? element.roles.map(element => (`${element} `)) : ""}</td>
                                    <td style={{ textAlign:'left', paddingLeft:60 }}>{getRoles(element.statusApproved)}</td>
                                    <td style={{ textAlign:'left', paddingLeft:60 }}>{element.school ? element.school.name : null}</td>
                                    <td>
                                        <Link to={`/admin/parametres/roles/edit/${element.id}`}>Voir</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                        <div className='pagination'>
                            <Pagination datas={datas} url={'/admin/parametres/roles/'} match={match} />
                        </div>
                  </div>
            </div>
            </>
      )
};

// == Export
export default withRouter(Roles);