// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';
import './visite-prepare.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/preparer-visite', breadcrumb: 'Votre visite' },
];


// == Composant
const WebappVisitePrepar = ({ location, match, connected, datas, getalveoles, waitingRequest, quizzFile, getQuizFile }) => {
    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes);
    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        {
            getalveoles();
            getQuizFile()
        }
    },[])

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    } else if(datas['@id'] != undefined && datas['@id'] != '/api/alveoles') {
        getalveoles();
        getQuizFile()
   }

   console.log(quizzFile)

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        {quizzFile && quizzFile.quizzQuestion.length !== 0 && quizzFile.quizzAnswer.length !== 0 ? (
            <>
                <div style={{ display:'flex', flexDirection:'column', justifyContent: 'left',width:'100%', margin:'50px 0% 0 0' }}>
                    <h1 className='page-title' style={{ textAlign:'left', marginBottom:20 }}>Tester ses connaissances</h1>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <h2 className='page-subtitle' style={{ marginBottom: 50 }}>Quiz à proposer aux élèves en amont de la visite</h2>
                    </div>
                </div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly', width:'100%', marginBottom:100 }}>
                    {quizzFile && quizzFile.quizzQuestion.length !== 0 ? (
                        <a className="white-background button" href={`https://mia.api.wacan-extranet.com${quizzFile.quizzQuestion[0].fileUrl}`}>
                            <div>Questionnaire</div>
                            <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                            <div className="input-button">Télécharger</div>
                        </a>
                    ) : null}
                    {quizzFile && quizzFile.quizzAnswer.length !== 0 ? (
                        <a className="white-background button" href={`https://mia.api.wacan-extranet.com${quizzFile.quizzAnswer[0].fileUrl}`}>
                            <div>Réponse</div>
                            <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                            <div className="input-button">Télécharger</div>
                        </a>
                    ) : null}
                </div>
            </>
        ) : null}
        <div className='visite-prepare_parcours-container'>
            <div style={{ display:'flex',flexDirection: 'column' }}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>PARCOURS</h1>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <h2 className='page-subtitle' style={{ marginBottom: 50 }}>Sélectionner un parcours pour accéder aux activités</h2>
                </div>
            </div>
            <div className="card-container">
                {datas['hydra:member'] ? datas['hydra:member'].map((data) => (
                    <Link to={`/preparer-visite/parcours/${data.id}`} className="alveole">
                        <img src={`https://mia.api.wacan-extranet.com${data.icon}`}></img>
                        <p style={{fontSize:18, fontWeight:'bold'}}>{data.title}</p>
                    </Link>
                )) : null}
            </div>
        </div>
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappVisitePrepar;