// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';

// == Import : local
import '../../style/style.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/explorer', breadcrumb: 'Explorer' },
    { path: '/explorer/categorie/1', breadcrumb: 'Catégorie' },
];


// == Composant
const WebappCategory = ({ location, match, waitingRequest, datas, getTools, connected }) => {

    const { height, width } = useWindowDimensions();

    const [content, setContent] = useState([])
    const [order, setOrder] = useState(null)
    const [willOrder, setWillOrder] = useState(false)
    const [filter, setFilter] = useState(null)
    const [willFilter, setWillFilter] = useState(false)
    const [search, setSearch] = useState('')
    const [init, setInit] = useState(false)
    const [showModalVideo, setShowModalVideo] = useState(false)
    const [videoInfo, setVideoInfo] = useState()

    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/explorer/categorie'] });

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        getTools(undefined, `statusAccepted=accepted&category=${match.params.id}`);
    }, []);

    if(datas['@id'] === `/api/tools` && datas['hydra:member'].length !== 0 && datas['hydra:member'][0].category['@id'] === `/api/categories/${match.params.id}` && !init) {
        setContent(datas['hydra:member']);
        setInit(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          justifyContent:'space-between',
          width:680,
          padding: '30px 40px',
          color: '#0578BE',
          borderRadius:10,
          overflow: 'hidden',
          border: 'none',
          backgroundColor: 'transparent'
        }
    };

    if(willFilter) {
        let donnee = datas['hydra:member'];
        if(filter != '') {
            const temp = donnee.filter((a) => (a.contentType == filter))
            console.log('tempt', temp)
            setContent(temp)
        } else {
            setContent(donnee)
        }
        setWillFilter(false)
    }
    if(willOrder) {
        if(order === 'ASC') {
            const temp = content.sort((a, b) => (new Date(a.addedAt) - new Date(b.addedAt)))
            console.log('tempt', temp)
            setContent(temp)
        }
        if(order === 'DESC') {
            const temp = content.sort((a, b) => (new Date(b.addedAt) - new Date(a.addedAt)))
            console.log('tempt', temp)
            setContent(temp)
        }
        setWillOrder(false)
    }

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Contenus</h1>
        </div>
        <div style={{height:'fit-content', backgroundColor:'#0578BE', borderRadius:10, boxShadow:'0 4px 4px rgba(0,0,0,0.25)', display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', padding:'15px 20px'}}>
            <p style={{color:'white', marginRight:'10%', fontSize:25, fontWeight:400}}>Parcourir</p>
            <select className='button' style={{ marginRight:40 }} onChange={(e) => {setFilter(e.target.value); setWillFilter(true)}}>
                <option value="">TYPE</option>
                <option value="png">PNG</option>
                <option value="pdf">PDF</option>
            </select>
            <select className='button' style={{ marginRight:40 }} onChange={(e) => {setOrder(e.target.value);setWillOrder(true)}}>
                <option value="">DATE</option>
                <option value="ASC">ASC</option>
                <option value="DESC">DESC</option>
            </select>
            <input onChange={(e) => {setSearch(e.target.value)}} className='input-text' type='search' placeholder='Rechercher...' style={{ width:323, marginTop:width<1311 ? 10 : 0 }}></input>
        </div>

        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', marginTop:40, padding:'0 8%', justifyContent:'space-between'}}>
            {content.map((element) => {
                if(element.title.toLowerCase().includes(search.toLowerCase()) || search === undefined || search === "") {
                return (
                <div style={{ textDecoration:'none', color:'white', fontSize:15, marginBottom:20, boxShadow:'0 4px 4px rgba(0,0,0,0.25)' }}>
                    {element.contentType !== 'vidéo' ? (
                        <a href={`https://mia.api.wacan-extranet.com${element.fileUrl}`} target="_blank">
                            <div style={{height:'fit-content', display:'flex', flexDirection:'column', maxWidth:'200px'}}>
                                <img src={element.contentType !== 'application/pdf' ? `https://mia.api.wacan-extranet.com/${element.fileUrl}` : '/images/pdf.svg'} style={{backgroundColor:'rgba(0,0,0,0.25)', height:200, width:200, margin:0}} />
                                <p title={element.title} style={{ backgroundColor:'#0578BE', lineHeight:'45px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', padding:'0 5px' }}>{element.title}</p>
                            </div>
                        </a>
                    ) : (
                        <div style={{cursor:'pointer', maxWidth:'200px'}} onClick={() => {setShowModalVideo(true);setVideoInfo(element.fileUrl)}}>
                            <div style={{height:'fit-content', display:'flex', flexDirection:'column'}}>
                                <img src={'/images/camera.svg'} style={{backgroundColor:'rgba(0,0,0,0.25)', height:200, width:200, margin:0}} />
                                <p title={element.title} style={{ backgroundColor:'#0578BE', lineHeight:'45px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', padding:'0 5px' }}>{element.title}</p>
                            </div>
                        </div>
                    )}
                </div>)}
            })}
        </div>

    </div>
    <div>

    </div>
    <Actu />
    <Footer />

    <Modal
        isOpen={showModalVideo}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {
            setShowModalVideo(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <div style={{position:'relative'}}>
            <ReactPlayer url={videoInfo} controls />
        </div>
    </Modal>
    </>
    )
};

// == Export
export default WebappCategory;