// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Deconnexion from '../../screen/Deconnexion';

// Action Creators
import { setConnected } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setConnected: () => {
    dispatch(setConnected());
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Deconnexion);