// == Import : npm
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

// == Import : local
import '../../style/style.scss';
//import './header.scss';

// == Composant
const MenuVisite = ({entries, currentMenuItem }) => {
    //const path = match.url;
    /*allEntries.forEach(entry => {
        if(path === entry.link) {entry.class = `${entry.class} underline`}
    });*/

    /*const displayEntries = () => {
        let allEntries = [];
        for (let index = 0; index < entries.length; index++) {
            if(currentMenuItem === entries[index].title) {
                allEntries[index] = <Link onClick={() => {setCurrentMenuItem(entries[index].title)}} to={entries[index].link} className={entries[index].class!=="" ? `${entries[index].class} underline` : "underline"}>{entries[index].title}</Link>;
            } else {
                allEntries[index] = <Link onClick={() => {setCurrentMenuItem(entries[index].title)}} to={entries[index].link} className={entries[index].class!=="" ? entries[index].class : ""}>{entries[index].title}</Link>;
            }
        }

        return (<>{allEntries}</>);

    };*/

    return (
        <div style={{backgroundColor:'#0578BE', borderRadius:10, height:52, overflow:'hidden', width:800, display:'flex', flexDirection:'row', marginTop:70, boxShadow:'0 4px 4px rgba(0,0,0,0.25)', maxWidth:'100vw' }}>
            <Link to='/visite' style={{width:'50%', maxWidth:'30vw', lineHeight:'52px', backgroundColor: currentMenuItem === 1 ? '#F33c3C' : '', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                Réserver une visiste
            </Link>
            <Link to='/preparer-visite' style={{width:'50%', maxWidth:'30vw', lineHeight:'52px', backgroundColor: currentMenuItem === 2 ? '#F33c3C' : '', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                Votre visite
            </Link>
            {/*<Link to='/informations-pratiques' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', backgroundColor: currentMenuItem === 3 ? '#F33c3C' : '', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                Informations pratiques
            </Link>*/}
        </div>
    );
};

// == Export
export default MenuVisite;