// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import EvenementEdit from '../../screen/EvenementEdit';

// Action Creators
import { getVisite, editVisites, getVisitTypes } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  visitTypes: state.visitTypes,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisite: (id) => {
    dispatch(getVisite(id));
  },
  editVisites: (id, start, end, visitType) => {
    dispatch(editVisites(id, start, end, visitType, ownProps.history));
  },
  getVisitTypes: () => {
    dispatch(getVisitTypes());
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvenementEdit);