// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import QRCode from 'qrcode.react';
import { guid } from 'react-agenda';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import FileUpload from '../../components/FileUpload';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
    {
        id: 1,
        name: 'test',
        statut: 'En ligne',
    },
]

// == Composant
const AlveolesContent = ({ location, history, match, waitingRequest, datas, getalveolesContents, editAvleole, deleteEntity, connected }) => {

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [oldFile, setOldFile] = useState('');
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [toDelete, setToDelete] = useState(null);

    const [stateInit, setStateInit] = useState(false);

    /*if(datas.contents && name==null) {
        setName(datas.title)
    }*/

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getalveolesContents(match.params.name)
    }, []);

    if(!stateInit && datas.title) {
        setName(datas.title);
        setDescription(datas.description);
        setOldFile(datas.icon)
        setStateInit(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }else if(datas['@id'] != undefined && datas['@id'] != '/api/alveoles') {
        getalveolesContents(match.params.name);
   }

   const customStyles = {
        content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'space-between',
        //height:307,
        width:375,
        padding: '30px 40px',
        color: '#0578BE',
        boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
        borderRadius:10
        }
    };

   const onImageChange = (event) => {
        if(event.target.files.length !== 0) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setImageUrl({ imageSrc: e.target.result });
            console.log(e.target.result)
            }

            reader.readAsDataURL(event.target.files[0]);
        }
    }

   const data = new FormData();

    return (
        <>
        <Header currentMenuItem={1} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>VISITES</h1>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <Link to={`/admin/visites/${match.params.name}/ajout`} className='button buton-link' style={{marginRight:20}}>Nouvelle activité</Link>
                    <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                </div>
            </div>
            <div className='grey-background' style={{ marginBottom: 150 }}>
                <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Informations sur la visiste</h2>
                <label style={{ marginTop: 10 }}>Nom de la visite</label>
                <input type='text' className="input-text" placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value)}}></input>
                <label style={{ marginTop: 10 }} htmlFor="description">Description</label>
                <textarea name="description" className="input-text" id="description" value={description} onChange={(e) => {setDescription(e.target.value)}} cols="30" rows="10"></textarea>
                <label style={{ marginTop: 10 }}>Icône</label>
                <img style={{ height:300, width: 300, marginBottom:10 }} src={imageUrl ? imageUrl.imageSrc : `https://mia.api.wacan-extranet.com/${oldFile}`} />
                <FileUpload accept={"image/png, image/jpeg"} inputText={"Parcourir..."} OnClickDo={(e) => {onImageChange(e); const fileURI = e.target.files[0]; const data = new FormData(); data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-')); setFile(data); console.log('onClickdo', data.get('file'))}} buttonClass={"input-button"} />
                {/*<QRCode style={{ marginTop:20, marginBottom:20 }} value={`${datas.title}/${guid()}`} />*/}
                <button onClick={() => {editAvleole(match.params.name, name, description, file)}} className='button' style={{ marginTop:20, fontWeight:500 }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                
            </div>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%' }}>
                <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Liste des activités liées a la visite</h2>
            </div>
            <div className='table-wrapper' style={{ marginBottom:100 }}>
            <table>
                <thead>
                    <tr>
                        <th>Nom de l'activité</th>
                        <th>statut</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {datas.contents ? datas.contents.map((element) => (
                        <tr>
                            <td>{element.title}</td>
                            <td>{element.status_published}</td>
                            <td>
                                <Link to={`/admin/visites/${match.params.name}/content/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                    <p>Modifier</p>
                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                </Link>
                                <div onClick={() => {setShowModalDelete(true);setToDelete({id: element.id, title: element.title})}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none', cursor:'pointer'}}>
                                    <p>Supprimer</p>
                                    <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                </div>
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </table>
        </div>
        </div>

        <Modal
        isOpen={showModalDelete}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {
            setShowModalDelete(false);
        }}
        style={customStyles}
        contentLabel="Example Modal"
        >
            <div style={{ position:'absolute', top:15, right:15 }}>
                <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="1x" icon={faTimes} onClick={() => {setShowModalDelete(false)}} />
            </div>
            <div style={{position:'relative'}}>
                <div style={{ width:'100%', marginBottom:'28px' }}>
                    <p style={{fontSize:'16px'}}>Etes-vous sur de vouloir supprimer ce contenu ?</p>
                    <br />
                    <p style={{fontSize:'16px'}}>Nom du contenu : {toDelete ? toDelete.title : null}</p>
                    <br />
                    <p style={{fontSize:'16px'}}>Il sera définitivement supprimé.</p>
                </div>
                <div style={{ marginRight:'auto', marginLeft:'auto' }}>
                    <button className='button' style={{ marginRight:50 }} onClick={() => toDelete ? deleteEntity('alveole_contents', toDelete.id) : null}>Oui</button>
                    <button className='button' onClick={() => {setShowModalDelete(false)}}>Non</button>
                </div>
            </div>
        </Modal>
        </>
    )
};

// == Export
export default AlveolesContent;