// == Import : npm
import React, { useState } from 'react';
import { Link, Redirect } from "react-router-dom";

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    // {
    //     title:'Qu\'est ce que l\'appli MIA',
    //     link:'/maison-ia',
    //     class:''
    // },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    // {
    //     title:'Contact',
    //     link:'/contact',
    //     class:'button button-header'
    // }
]


// == Composant
const WebappChiffre = ({ location, match }) => {

    return (
        <div>
            <Header currentMenuItem={1} entries={headerEntries} etablissement location={location} match={match} />
            <div className="home-wrapper" style={{ display:'flex', flexDirection:'column', justifyContent:'space-around', maxWidth:'100vw' }}>
                <div style={{backgroundColor:'#0578BE', borderRadius:10, height:52, overflow:'hidden', width:800, display:'flex', flexDirection:'row', maxWidth:'100vw' }}>
                    <Link to='/ia-departement' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                        Tutoriels
                    </Link>
                    <Link to='/chiffre-cles' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', backgroundColor:'#F33c3C', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                        CGU
                    </Link>
                    <Link to='/actu' style={{width:'33%', maxWidth:'30vw', lineHeight:'52px', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                        FAQ
                    </Link>
                </div>
                <div style={{ backgroundColor:'white', borderRadius:10, boxShadow:'0px 4px 4px rgba(0,0,0,0.25)', padding:30, display:'flex', flexDirection:'row', justifyContent:'space-between', width:900, height:500, maxWidth:'100vw' }}>
                <h1 className='page-title'>Chiffres clés</h1>
                </div>
            </div>
        </div>
    )
};

// == Export
export default WebappChiffre;