// == Import : npm
import React, { useState } from 'react';
import { Link } from "react-router-dom";

// == Import : local
import '../../style/style.scss';
//import './home.scss';
import Header from '../../containers/Header';

const headerEntries = [
    // {
    //     title:'Qu\'est ce que l\'appli MIA',
    //     link:'/maison-ia',
    //     class:''
    // },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    // {
    //     title:'Contact',
    //     link:'/contact',
    //     class:'button button-header'
    // }
]

// == Composant
const ForgotPassword = ({location, match, history, forgetPassword}) => {

  const [emailInput, setEmailInput] = useState()

  const handleSubmit = (event) => {
    event.preventDefault()

    forgetPassword(emailInput, history)
  }

  return (
    <div>
      <Header entries={headerEntries} /*etablissement*/ location={location} match={match} />
      <div className="home-wrapper" style={{ display:'flex', flexDirection:'column' }}>
        <form onSubmit={handleSubmit} autoComplete="disabled">
          <div className="connection-wrapper">
            <h1>Mot de passe oublié</h1>
            <div className="input-wrapper">
              <input type="mail" style={{ background:'#F0F0F0' }} name="email" onChange={(e) => setEmailInput(e.target.value)} className="input-text" placeholder="Adresse e-mail"></input>
            </div>
            <div style={{width: '100%', display:'flex', justifyContent:'space-between'}}>
              <button className="button input-submit button-shadow" type="submit">Valider</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
};

// == Export
export default ForgotPassword;