// == Import : npm
import React, {useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id:1,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:2,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:3,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:4,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:5,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:6,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:7,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:8,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:9,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:10,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
]

// == Composant
const VoirDemandeEspace = ({ location, history, match, waitingRequest, connected }) => {

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
                <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase', fontWeight:500  }}>Demande d'espace établissement</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                <div className='grey-background' style={{ marginBottom: 50 }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase', fontWeight:500  }}>Coordonnées de l'établissement</h2>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Nom de l'établissement</p>
                    <p style={{ marginBottom: 30,  }}>Collége les baous</p>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Adresse postale</p>
                    <p style={{ marginBottom: 30,  }}>1835 Route de gattiéres, 06640 Saint-jeannet</p>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Numéro de téléphone</p>
                    <p style={{ marginBottom: 30,  }}>040404040404</p>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Email</p>
                    <p style={{ marginBottom: 30,  }}>college@gmail.com</p>
                    <p style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Nom du principal de l'établissement</p>
                    <p style={{ marginBottom: 30,  }}>Mr Dupont</p>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', flexWrap:'wrap', margin:'0 0%', marginBottom: 0, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Demande d'espace de l'établissement</h2>
                    <div style={{ display:'flex', flexDirection:'row' }}>
                        <button className='button' style={{ marginTop:0, marginRight:30 }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Accepter</button>
                        <button className='button' style={{ marginTop:0 }}><img src='/images/cross-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Refuser</button>
                    </div>
                </div>
        </div>
        </>
    )
};

// == Export
export default VoirDemandeEspace;