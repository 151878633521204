// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const Categories = ({ location, history, match, waitingRequest, datas, getCat, createCat, saveCat, connected }) => {

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getCat();
    }, [])

    const [cat, setCat] = useState([]);
    const [init, setInit] = useState(false);
    console.log('Loading categori page')

    if(datas['@id'] === '/api/categories' && !init) {
        console.log('setcat')
        setCat(datas['hydra:member']);
        setInit(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0', flexDirection:'row', width:'100%' }}>
            <h1 className='page-title'>Les catégories</h1>
            <div style={{display:'flex', flexDirection:'row'}}>
                <Link to='/admin/parametres/categories/ajout' className='button buton-link' style={{marginRight:20}}>Nouvelle catégorie</Link>
                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
            </div>
        </div>

        <div className='table-wrapper'>
            <table>
                <thead>
                    <tr>
                        <th>Titre</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {datas["@id"] === '/api/categories' ? datas["hydra:member"].map((element) => (
                        <tr>
                            <td>{element.title}</td>
                            <td>
                                <Link to={`/admin/parametres/categories/edit/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto'}}>
                                    <p>Modifier</p>
                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                </Link>
                                {/*<div style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10}}>
                                    <p>Supprimer</p>
                                    <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                    </div>*/}
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </table>
        </div>
    </div>
    </>
    )
};

// == Export
export default Categories;