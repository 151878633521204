// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import HeaderSchool from '../../containers/HeaderSchool';

// == Composant
const WebappParcour = ({ location, history, match, waitingRequest, datas, getalveolesContents, connected }) => {

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null)
    const [activitiesName, setActivitiesName] = useState({
      id: null,
      name: ''
    })
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [oldFile, setOldFile] = useState('');
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [toDelete, setToDelete] = useState(null);

    const [stateInit, setStateInit] = useState(false);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        getalveolesContents(match.params.id)
    }, []);

    if(!stateInit && datas.title) {
        setName(datas.title);
        setOldFile(datas.icon);
        setDescription(datas.description);
        setActivitiesName({
          id: datas.id,
          name: datas.title
        });
        setStateInit(true);
    }

    console.log(activitiesName)

    const DynamicActivitiesBreadcrumb = () => (
      <span>{activitiesName['name']}</span>
    );

    const routes = [
      { path: '/', breadcrumb: 'Accueil' },
      { path: '/preparer-visite', breadcrumb: 'Votre visite' },
      { path: '/preparer-visite/parcours', breadcrumb: 'Parcours' },
      { path: '/preparer-visite/parcours/:id', breadcrumb: `Parcours - ${activitiesName['name']}` },
    ];

    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/preparer-visite/parcours'] });

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }else if(datas['@id'] != undefined && datas['@id'] != '/api/alveoles') {
        getalveolesContents(match.params.name);
   }

   const customStyles = {
        content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'space-between',
        //height:307,
        width:375,
        padding: '30px 40px',
        color: '#0578BE',
        boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
        borderRadius:10
      }
    };

    return (
        <>
        <HeaderSchool />
        <div className='page-wrapper'>
          <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
              {breadcrumbs.map((breadcrumb, i, arr) => (
                  <span key={breadcrumb.match.url}>
                      <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                  </span>
              ))}
          </div>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>PARCOURS</h1>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                </div>
            </div>
            <div className='grey-background' style={{ marginBottom: 150 }}>
                <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Informations sur le parcours</h2>
                <p style={{ marginTop: 10 }}>Nom du parcours : <span style={{color:'initial'}}>{name}</span></p>
                <p style={{ marginTop: 10 }}>Description du parcours :</p>
                <p style={{color:'initial'}}>{description !== null ? description : null}</p>
                <img style={{ height:300, width: 300, marginBottom:10, marginTop: 10 }} src={imageUrl ? imageUrl.imageSrc : `https://mia.api.wacan-extranet.com/${oldFile}`} />
                
            </div>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%' }}>
                <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Liste des activités liées au parcours</h2>
            </div>
            <div className='table-wrapper' style={{ marginBottom:100 }}>
            <table>
                <thead>
                    <tr>
                        <th>Nom de l'activité</th>
                        <th>Détails</th>
                    </tr>
                </thead>
                <tbody>
                    {datas.contents ? datas.contents.map((element) => (
                        <tr>
                            <td>{element.title}</td>
                            <td>Voir la Médiathèque pour le détail de l'activité</td>
                        </tr>
                    )) : <></>}
                </tbody>
            </table>
        </div>
        </div>
        </>
    )
};

// == Export
export default WebappParcour;