// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import Rdate from 'react-datetime';
import ReactLoading from 'react-loading';


// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id:1,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:2,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:3,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:4,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:5,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:6,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:7,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:8,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:9,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:10,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
]

// == Composant
const EvenementAjout = ({ location, history, match, waitingRequest, createVisites, getVisitTypes, visitTypes, connected }) => {

    const [name, setName] =useState(null);
    const [type, setType] =useState(null);
    const [debut, setDebut] =useState(new Date());
    const [fin, setFin] =useState(new Date());
    const [recurrent, setRecurrent] =useState(null);
    const [scolaire, setScolaire] =useState(null);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        getVisitTypes();
    }, [])

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
                <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase', fontWeight:500  }}>Ajout d'un événements</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                <div className='grey-background' style={{ marginBottom: 50 }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase', fontWeight:500  }}>Informations générales</h2>
                    <label style={{ marginTop: 10, marginBottom:10, textTransform:'uppercase', fontWeight:500 }}>Nom de l'événement</label>
                    <input type='text' className="input-text" placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value)}}></input>
                    <label style={{ marginTop: 30, marginBottom:10, textTransform:'uppercase', fontWeight:500 }}>Type d'événement</label>
                    <select name="actions" id="actions"  className='button button-shadow' style={{ marginRight:20, height:40 }} onChange={(e) => {setType(e.target.value)}}>
                        <option value="">Séléctionner un type...</option>
                        {visitTypes['hydra:member'] ? visitTypes['hydra:member'].map(element => (
                                <option selected={selected[0] && selected[0].visitType==element.id} value={element.id}>{element.title}</option>
                        )) : <></>}
                    </select>
                </div>
                <div style={{ display:'flex', flexDirection: 'column',flexWrap:'wrap', margin:'0 0%', marginBottom: 0, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500  }}>Disponibilité de l'evenement</h2>
                    {/*<div style={{ display:'flex', flexDirection:'row' }}>
                        <label style={{ marginTop: 10, marginBottom:10, marginRight:20 }}>Jour de l'événement</label>
                        <input type='text' className="input-text" placeholder='01/01/2020'style={{ width:100 }}></input>
                    </div>*/}
                    <div style={{ display:'flex', flexDirection:'row' }}>
                        <label style={{ marginTop: 10, marginBottom:10, marginRight:20 }}>Début</label>
                        <Rdate input={false} viewMode="time" value={new Date(debut)} onChange={(e) => {console.log(e.unix()); setDebut(e)}} ></Rdate>
                    </div>
                    <div style={{ display:'flex', flexDirection:'row' }}>
                        <label style={{ marginTop: 10, marginBottom:10, marginRight:20 }}>Fin</label>
                        <Rdate input={false} viewMode="time" value={new Date(fin)} onChange={(e) => {console.log(e.unix()); setFin(e)}} ></Rdate>
                    </div>
                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                        <input type='checkbox' onClick={(e) => {setRecurrent(e.target.checked)}}></input>
                        <label style={{ marginTop: 10, marginBottom:10, marginLeft:5 }}>Evénement récurrent</label>
                    </div>
                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                        <input type='checkbox' onClick={(e) => {setScolaire(e.target.checked)}}></input>
                        <label style={{ marginTop: 10, marginBottom:10, marginLeft:5 }}>Evénement destiné aux etablissement scolaires</label>
                    </div>
                    <button onClick={() => {createVisites( name, debut.toISOString(), fin.toISOString(), type)}} className='button' style={{ marginTop:40, marginRight:30 }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Accepter</button>
                </div>
        </div>
        </>
    )
};

// == Export
export default EvenementAjout;