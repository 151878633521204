// == Import : npm
import React from 'react';
import { Link } from "react-router-dom";

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Parametre = ({ location, match }) => (
    <>
      <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
      <div className='page-wrapper'>
            <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                  <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase' }}>Paramètres</h1>
                  </div>
            </div>
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                  <div style={{ margin:'0px 0%' }}>
                        <div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion des utilisateurs</h2>
                              <Link to='/admin/parametres/roles/1' className='button buton-link button-shadow'>Accéder</Link>
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion des établissements</h2>
                              <Link to='/admin/parametres/etablissements/1' className='button buton-link button-shadow'>Accéder</Link>
                        </div>
                        {/*<div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion des contenus</h2>
                              <Link to='/admin/parametres/contenus/1' className='button buton-link button-shadow'>Accéder</Link>
                        </div>*/}
                        <div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion des catégories Médiathèque</h2>
                              <Link to='/admin/parametres/categories' className='button buton-link button-shadow'>Accéder</Link>
                        </div>
                  </div>
                  <div>
                        <div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion des types de visites</h2>
                              <Link to='/admin/parametres/type-visite/1' className='button buton-link button-shadow'>Accéder</Link>
                        </div>
                        <div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion des événements</h2>
                              <Link to='/admin/parametres/evenements/1' className='button buton-link button-shadow'>Accéder</Link>
                        </div>
                        {/*<div style={{ display:'flex', flexDirection:'column', marginBottom:40 }}>
                              <h2 className='page-subtitle' style={{  textTransform:'uppercase', marginBottom:20 }}>Gestion de la webapp publique</h2>
                              <Link to='/admin/parametres/webapp' className='button buton-link button-shadow'>Accéder</Link>
                        </div>*/}
                  </div>
            </div>
      </div>
  </>
);

// == Export
export default Parametre;