// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ReactAgenda , ReactAgendaCtrl , guid } from 'react-agenda';
import Modal from 'react-modal';
import Rdate from 'react-datetime';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// == Import : local
import '../../style/style.scss';
import './planning-reservation.scss';
import Header from '../../containers/Header';
import { boEntries } from '../../utils/headerEntries.js';
import 'moment/locale/fr';

const momentTimezone = require('moment-timezone');

var colors= {
    'color-1':"rgba(5, 120, 190 , 1)" ,
    "color-2":"rgba(75, 75, 75, 1)" ,
}

var now = new Date();

var items = [
    {
        _id            :guid(),
        name          : 'Meeting , dev staff!',
        startDateTime : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
        endDateTime   : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0),
        classes       : 'color-1'
    },
    {
        _id            :guid(),
        name          : 'Working lunch , Holly',
        startDateTime : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 0),
        endDateTime   : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0),
        classes       : 'color-2'
    },
    
];

const objectValidation = {
    eventTitle: {
        value: '',
        isValid: false,
        error: ''
    },
    eventType: {
        value: '',
        isValid: false,
        error: ''
    },
    amountWeekly: {
        value: '',
        isValid: false,
        error: ''
    },
    amountDaily: {
        value: '',
        isValid: false,
        error: ''
    },
    date: {
        value: '',
        isValid: false,
        error: ''
    },
    timeStart: {
        value: '',
        isValid: false,
        error: ''
    },
    timeEnd: {
        value: '',
        isValid: false,
        error: ''
    },
}



// == Composant
const PlanningReservation = ({
    location,
    match,
    waitingRequest,
    datas,
    getVisites,
    createVisites,
    editVisites,
    getVisitTypes,
    visitTypes,
    connected,
    deleteEvent,
    createEventHebdo,
    createEventDaily,
    createVisiteRequestAdmin,
    getSchools,
    schools,
    getUsers,
    users,
}) => {
    
    const [Items, setItems] = useState(items);
    const [selected, setSelected] = useState([]);
    const [cellHeight, setCellHeight] = useState(30);
    const [showModal, setShowModal] = useState(false);
    const [showModalOverlap, setShowModalOverlap] = useState(false);
    const [showModalRecurrent, setShowModalRecurrent] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [locale, setlocale] = useState("fr");
    const [rowsPerHour, setRowsPerHour] = useState(1);
    const [numberOfDays, setnumberOfDays] = useState(7);
    const [startDate, setStartDate] = useState(moment().startOf('week'));

    const [tempName, setTempName] = useState("");
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);
    const [tempType, setTempType] = useState(null);

    const [initItems, setInitItems] = useState(false);

    const [recurrentType, setRecurrentType] = useState(null);
    const [amountWeekly, setAmountWeekly] = useState(null);
    const [amountDaily, setAmountDaily] = useState(null);
    const [weeklyDate, setWeeklyDate] = useState(moment());
    const [dailyDate, setDailyDate] = useState(moment());
    const [weeklyTimeDateStart, setWeeklyTimeDateStart] = useState(null);
    const [weeklyTimeDateEnd, setWeeklyTimeDateEnd] = useState(null);
    const [dailyTimeDateStart, setDailyTimeDateStart] = useState(null);
    const [dailyTimeDateEnd, setDailyTimeDateEnd] = useState(null);

    const [errors, setErrors] = useState(objectValidation)

    // add for reservation by admin
    const [showReservation, setShowReservation] = useState(false);
    const [etName, setEtName] = useState(null)
    const [classeName, setclasseName] = useState(null)
    const [annee, setannee] = useState(null)
    const [nbEleve, setnbEleve] = useState(null)

    const [teacherName, setTeacherName] = useState(null)
    const [teacherEmail, setTeacherEmail] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [companionName, setCompanionName] = useState(null)
    const [user, setUser] = useState(null)
    const [resErrors, setResErrors] = useState({
        email: {
            value: '',
            isValid: false,
            error: ''
        },
        phone: {
            value: '',
            isValid: false,
            error: ''
        },
    })
    
    const phoneRegex = /^((\+)33|0)[1-9](\d{2}){4}$/g
    const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

    // add for reservation by admin END


    const yesterday = moment().subtract( 1, 'day' );
    const valid = function( current ){
        return current.isAfter( yesterday ) && current.day() !== 0 && current.day() !== 6;
    };

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')) {
            getVisites()
            getVisitTypes();
        }
        getSchools();
        getUsers();
    },[])

    console.log('teacher : ', teacherEmail)

    if(datas["@id"] === "/api/visits" && !initItems) {
        const temp = datas["hydra:member"].map((element) => {
            const arrayOfDigitsStartDate = moment(element.start)._a;
            const arrayOfDigitsEndDate = moment(element.end)._a;
            const start = new Date(...arrayOfDigitsStartDate);
            const end = new Date(...arrayOfDigitsEndDate);
            return {
                _id           : element.id,
                name          : element.title,
                startDateTime : start,
                endDateTime   : end,
                visitType     : element.visitType ? element.visitType.title : null,
                classes       : element.status === 'locked' ? 'color-locked' : 'color-2',
                status        : element.status ? element.status : null,
                visitRequests : element.visitRequests,
            }
        })
        //console.log(temp)
        setItems(temp);
        setInitItems(true)
    }

    const validationField = (event) => {
        const input = event.target;
        let inputValidationResult = false;
        let inputValidationError= '';
        switch (input.name) {
            case 'eventTitle':
                inputValidationResult = input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Veuillez entrer un nom pour l\'événement !';
                break;
            case 'eventType':
                inputValidationResult = input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Veuillez sélectionner un type d\'événement !';
                break;
            case 'amountWeekly':
                inputValidationResult = parseInt(input.value) >= Number(parseInt(input.min)) && parseInt(input.value) <= Number(parseInt(input.max));
                inputValidationError = inputValidationResult ? '' : 'Veuillez sélectionner un nombre compris entre 1 et 12 !';
                break;
            case 'amountDaily':
                inputValidationResult = input.min >= Number(input.min) && input.max <= Number(input.max);
                inputValidationError = inputValidationResult ? '' : 'Veuillez sélectionner un nombre compris entre 1 et 21 !';
                break;
            default:
                inputValidationResult = false;
        }

        setErrors({
            ...errors,
            [input.name]: {
                ...errors[input.name],
                value: input.value,
                isValid: inputValidationResult,
                error: inputValidationError
            }
        })
    }
    
    const handleCellSelection = (item) =>{
        // console.log('handleCellSelection',item)
        setSelected([item])
        setShowModal(true)
    }
    
    const handleItemEdit = (item) => {
        // console.log('handleCellSelection',item)
        setSelected([item])
        setShowModal(true)
    }

    const handleRangeSelection = (item) => {
        // console.log('handleCellSelection',item)
        setSelected([item])
        setShowModal(true)
    }

    const editItem = (item) => {
        if (item.status !== null && item.status === 'locked') {
            setSelected([item]);
            setTempName(item.name);
            setTempStartDate(item.startDateTime);
            setTempEndDate(item.endDateTime);
            setTempType(item.visitType);
            setShowModalEdit(true)
        } else {
            // console.log(item);
            setSelected([item]);
            setTempName(item.name);
            setTempStartDate(item.startDateTime);
            setTempEndDate(item.endDateTime);
            setTempType(item.visitType);
            setShowModal(true);
        }
    }
    const editEvent = () => {

        
        Items.forEach(Item => {
            if(Item._id === selected[0]._id) {
                if(tempName) {
                    Item.name = tempName;
                }
                if(tempStartDate) {
                    Item.startDateTime = new Date(tempStartDate);
                }
                if(tempEndDate) {
                    Item.endDateTime = new Date(tempEndDate);
                }
                if(tempType) {
                    Item.visitType = tempType;
                }
                editVisites(Item.name, Item._id, new Date(Item.startDateTime), new Date(Item.endDateTime), Item.visitType)
            }
        });

        
        setShowModal(false);
        setShowModalRecurrent(false);
        
        setTempName("");
        setTempStartDate(null);
        setTempEndDate(null);
        setTempType(null)
        
        
        setShowModal(false)
    }

    const addNewEvent = () => {
        const tempItem = {
            _id            :guid(),
            name          : tempName,
            startDateTime : tempStartDate ? tempStartDate.toDate() : moment(selected[0]).toDate(),
            endDateTime   : tempEndDate ? tempEndDate.toDate() : moment(selected[0]).add(1, 'hours').toDate(),
            visitType     : tempType,
            classes       : 'color-1'
        };
        createVisites(tempItem.name, tempItem.startDateTime, tempItem.endDateTime, tempType);

        // console.log(tempItem);

        //items.push(tempItem)
        //console.log(items)
        setItems([...Items, tempItem]);
        // console.log('add items', Items)
        setShowModal(false);
        setShowModalRecurrent(false);

        setTempName("");
        setTempStartDate(null);
        setTempEndDate(null);
        setTempType(null)
    }

    const checkOverlappingDates = (startDate, endDate) => {
        // console.log(startDate, endDate)
        return Items.some(elem => (moment(elem.startDateTime) <= moment(endDate)) && (moment(startDate) <= moment(elem.endDateTime)))
    }
    
    // console.log('main', Items)
    
    const AgendaCard = ({item}) => {
        console.log(item)
        return (
            <div style={{display:'block', /*position:'absolute' ,*/ color:'white', padding:10, backgroundColor:'#FFFFFF26', width:'-webkit-fill-available', display:'flex', justifyContent:'space-between' }} /*onClick={()=> {editItem(item)}}*/>
                {item.name}
                <div style={{display: "flex", gap: 5}}>
                    <div style={{ color:'white' }} onClick={()=> {editItem(item)}}>Editer</div>
                    {item.visitRequests?.length ? (
                        <a href={`/admin/gerer-reservation/voir/${item.visitRequests[0].split('/')[3]}`} target="_blank">Voir</a>
                    )
                    :
                    <></>
                    }
                </div>
            </div>
        );
    }

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          justifyContent:'space-between',
          //height:307,
          width:305,
          padding: '30px 30px',
          color: '#0578BE',
          boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
          borderRadius:10,
          maxHeight: '99vh',
          overflowX: 'hidden',
          overflowY: 'scroll'
        }
      };

      if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={3} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper' style={{ paddingBottom:0 }}>
                    <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between' }}>
                            <h1 className='page-title'>PLANNING</h1>
                            <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase' }}>Cliquer sur le planning pour ajouter un nouvel événement</h2>
                        </div>
                        <div style={{display:'flex'}}>
                            <Link to='/admin/gerer-reservation/1' style={{backgroundColor:'#0578be', color:'white'}} className='button buton-link'>Gérer les réservations</Link>
                            <button className='button buton-link' style={{ display:'flex', flexDirection:'row', marginLeft:'15px' }} onClick={() => {setShowModalRecurrent(true)}}>Événement récurrent</button>
                        </div>
                    </div>
            </div>
            <div className="agenda-container" style={{ width:'90%', margin:'0 auto', border:'1px solid #0578BE' }}>
                <ReactAgenda
                itemComponent={AgendaCard}
                minDate={moment().startOf('week')}
                maxDate={new Date(now.getFullYear(), now.getMonth()+3)}
                disablePrevButton={false}
                startDate={startDate}
                startAtTime={8}
                endAtTime={18}
                cellHeight={cellHeight}
                locale={locale}
                items={Items}
                numberOfDays={numberOfDays}
                rowsPerHour={rowsPerHour}
                itemColors={colors}
                autoScale={false}
                fixedHeader={true}
                // onItemEdit={handleItemEdit}
                onCellSelect={handleCellSelection}
                onRangeSelection={handleRangeSelection}/>
            </div>
            <Modal
            isOpen={showModal}
            //onAfterOpen={afterOpenModal}
            onRequestClose={() => {
                setShowModal(false)
                setTempName("");
                setTempStartDate(null);
                setTempEndDate(null);
                setTempType(null)
            }}
            style={customStyles}
            contentLabel="Example Modal"
            >
                <div style={{ width:'100%', position:'relative' }}>
                    <button onClick={() => {setShowModal(false)
                setTempName("");
                setTempStartDate(null);
                setTempEndDate(null);
                setTempType(null)}} style={{position:"absolute", top:-15, right: -15, color:'red'}}>X</button>
                    <label style={{ display:'block', marginBottom: 10 }}>Nom de l'evenement :</label>
                    <input type='text' className='input-text' style={{ backgroundColor:'#F0F0F0' }} value={tempName!=null ? tempName : selected[0]!=undefined && selected[0].name ? selected[0].name : tempName} onChange={(e) => {
                        setTempName(e.target.value);
                    }}></input>
                </div>
                <div style={{ display:'flex', justifyContent:'space-between', width:'100%', marginTop:20, marginBottom:20 }}>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content' }}>
                        <label>Début :</label>
                        <Rdate value={tempStartDate!=null ? tempStartDate : selected[0]!=undefined && selected[0].startDateTime ? selected[0].startDateTime : new Date(selected[0])} onChange={(date) => {setTempStartDate(date)}} timeConstraints={{ hours: { min: 8, max: 18 }}} input={false} inputProps={{className:'input-text'}} viewMode="time" ></Rdate>
                    </div>
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content' }}>
                        <label>fin :</label>
                        <Rdate value={tempEndDate!=null ? tempEndDate : selected[0]!=undefined && selected[0].endDateTime ? selected[0].endDateTime : new Date(selected[0]).setHours(new Date(selected[0]).getHours() + 1)} onChange={(date) => {setTempEndDate(date)}} timeConstraints={{ hours: { min: 8, max: 18 }}} input={false} viewMode="time" ></Rdate>
                    </div>
                </div>
                <div style={{ width:'100%' }}>
                    <label style={{ display:'block', marginBottom:10 }}>Type d'événement :</label>
                    <select name="type" id="type"  className='button button-shadow' style={{ width:'100%' }} onChange={(e) => {setTempType(e.target.value)}}>
                        <option value="">Sélectionner un type...</option>
                        {visitTypes['hydra:member'] ? visitTypes['hydra:member'].map(element => (
                            <option selected={selected[0] && selected[0].visitType==element.title} value={element.id}>{element.title}</option>
                        )) : <></>}
                    </select>
                </div>
                {
                    showReservation ? 
                    <div style={{width: "100%"}}>
                        {/* <input type='text' className='input-text' style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop: 10 }} placeholder="Nom de l'établissement" value={etName} onChange={(e) => setEtName(e.target.value)}></input> */}
                        <select name="school" id="school"  className='button button-shadow' style={{ width:'100%', border:`${errors.eventType.value !== '' ? !errors.eventType.isValid ? '1px solid red' : '1px solid green' : '1px solid #0578be'}`, marginTop: 20 }} value={etName} onChange={(e) => {setEtName(e.target.value);}}>
                            <option value="">Sélectionner une ecole...</option>
                            {schools['hydra:member'] ? schools['hydra:member'].map(element => (
                                <option value={element['@id']}>{element.name}</option>
                            )) : <></>}
                        </select>
                        <select name="user" id="user"  className='button button-shadow' style={{ width:'100%', border:`${errors.eventType.value !== '' ? !errors.eventType.isValid ? '1px solid red' : '1px solid green' : '1px solid #0578be'}`, marginTop: 10 }} value={user} onChange={(e) => {setUser(e.target.value);}}>
                            <option value="">Sélectionner une utilisateur...</option>
                            {users['hydra:member'] ? users['hydra:member'].map(element => (
                                <option value={element['@id']}>{element.email}</option>
                            )) : <></>}
                        </select>
                        <input type='text' placeholder='Année scolaire' name="year" className='input-text' style={{ background:'#F0F0F0', marginTop: 10 }} value={annee} onChange={(e) => setannee(e.target.value)}></input>
                        <input type='text' placeholder="Nombre d'élèves" name="studentNumber" className='input-text' style={{ background:'#F0F0F0', border:`${nbEleve?'1':'0'}px solid ${ isNaN(nbEleve)?'red':'green'}`, marginTop: 10 }} value={nbEleve} onChange={(e) => setnbEleve(e.target.value)}></input>
                        <input type='text' className='input-text' name="classe" style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop: 10 }} placeholder="Nom de la classe" value={classeName} onChange={(e) => setclasseName(e.target.value)}></input>
                        <input type='text' className='input-text' name="teacherName" style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop: 10 }} placeholder="Nom et Prénom de l'enseignant" value={teacherName} onChange={(e) => setTeacherName(e.target.value)}></input>
                        <input type='text' className='input-text' name="companionName" style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop: 10 }} placeholder="Nom et Prénom de l'accompagnateur" value={companionName} onChange={(e) => setCompanionName(e.target.value) }></input>
                        <input type='text' name="email" className='input-text' style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop: 10 }} placeholder="Adresse email de l'enseignant" value={teacherEmail} onChange={(e)=> setTeacherEmail(e.target.value)}></input>
                        <input type='text' name="phone" className='input-text' style={{ background:'#F0F0F0', width:'-webkit-fill-available', marginTop: 10/*, border:`${errors.phone.value !== '' ? errors.phone.error !== '' ? '1px solid red' : '1px solid green' : 'none'}`*/ }} placeholder="N° de téléphone" value={phoneNumber} onChange={(e)=> {setPhoneNumber(e.target.value);}}></input>

                        <button style={{ border:'none', background:'transparent', color:'#0578BE', textTransform:'uppercase', fontSize:14, marginTop:10, fontWeight:'bold', cursor:'pointer' }} onClick={() => {
                                // console.log(selected[0]._id)
                                setShowReservation(!showReservation);
                                setShowModal(false);
                                createVisiteRequestAdmin({
                                    etName,
                                    annee,
                                    nbEleve,
                                    classeName,
                                    teacherName,
                                    companionName,
                                    teacherEmail,
                                    phoneNumber,
                                    selected: `/api/visits/${selected[0]?._id ? selected[0]._id : ''}`,
                                    user,
                                })
                            }}>
                                Valider réservation
                            </button>
                    </div> : 
                    <></>
                }
                <div style={{display:'flex'}}>
                    {
                        selected[0]!=undefined && selected[0]._id ? (
                            <>
                            <button style={{ border:'none', background:'transparent', color:'red', textTransform:'uppercase', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }} onClick={() => (
                                //console.log(selected[0]._id)
                                deleteEvent(selected[0]._id)
                            )}>
                                Supprimer
                            </button>
                            <button style={{ border:'none', background:'transparent', color:'#0578BE', textTransform:'uppercase', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }} onClick={() => {
                                // console.log(selected[0]._id)
                                setShowReservation(!showReservation);
                            }}>
                                Réserver
                            </button>
                            </>
                        ) : null
                    }
                    <button style={{ border:'none', background:'transparent', color:'#0578BE', textTransform:'uppercase', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }} onClick={() => {
                        if(selected[0]!=undefined && selected[0]._id) {
                            // console.log('edit', tempName, tempStartDate, tempEndDate, tempType)
                            editEvent();
                        } else {
                            checkOverlappingDates(selected[0], tempEndDate) ? setShowModalOverlap(true) : addNewEvent()
                        }
                    }}>Valider</button>
                </div>
            </Modal>

            <Modal
            isOpen={showModalRecurrent}
            //onAfterOpen={afterOpenModal}
            onRequestClose={() => {
                setShowModalRecurrent(false)
                setTempName("");
                setTempStartDate(null);
                setTempEndDate(null);
                setTempType(null);
                setRecurrentType(null);
                setErrors(objectValidation);
            }}
            style={customStyles}
            contentLabel="Example Modal"
            >
                <div style={{ width:'100%', position:'relative' }}>
                    <button onClick={() => {setShowModalRecurrent(false)
                setTempName("");
                setTempStartDate(null);
                setTempEndDate(null);
                setTempType(null);
                setRecurrentType(null);
                setErrors(objectValidation)}} style={{position:"absolute", top:-15, right: -15, color:'red'}}>X</button>
                    <label style={{ display:'block', marginBottom: 10 }}>Nom de l'événement :</label>
                    <input type='text' name="eventTitle" className='input-text' style={{ backgroundColor:'#F0F0F0', border:`${errors.eventTitle.value !== '' ? !errors.eventTitle.isValid ? '1px solid red' : '1px solid green' : 'none'}` }} value={tempName!=null ? tempName : selected[0]!=undefined && selected[0].name ? selected[0].name : tempName} onChange={(e) => {
                        setTempName(e.target.value);
                        validationField(e);
                    }}></input>
                    <p style={{color: 'red', marginTop: 10}}>{errors && !errors.eventTitle.isValid ? errors.eventTitle.error : null}</p>
                </div>
                <div style={{ width:'100%', marginTop:30 }}>
                    <select name="eventType" id="type"  className='button button-shadow' style={{ width:'100%', border:`${errors.eventType.value !== '' ? !errors.eventType.isValid ? '1px solid red' : '1px solid green' : '1px solid #0578be'}` }} value={tempType} onChange={(e) => {setTempType(e.target.value);validationField(e)}}>
                        <option value="">Sélectionner un type...</option>
                        {visitTypes['hydra:member'] ? visitTypes['hydra:member'].map(element => (
                            <option value={element.id}>{element.title}</option>
                        )) : <></>}
                    </select>
                    <p style={{color: 'red', marginTop: 10}}>{errors && !errors.eventType.isValid ? errors.eventType.error : null}</p>
                </div>
                <div style={{ width:'100%', marginTop:30, display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="hebdomadaire">
                        <input type="radio" id="hebdomadaire" name="event" value="hebdomadaire" onChange={(event) => {
                            if(amountWeekly !== null || amountDaily !== null) {
                                setAmountWeekly(null)
                                setAmountDaily(null)
                                setRecurrentType(event.target.value)
                            } else {
                                setRecurrentType(event.target.value)
                            }
                        }}></input>
                        événement récurrent hebdomadaire
                    </label>
                    <label htmlFor="journalier">
                        <input type="radio" id="journalier" name="event" value="journalier" onChange={(event) => {
                            if(amountWeekly !== null || amountDaily !== null) {
                                setAmountWeekly(null)
                                setAmountDaily(null)
                                setRecurrentType(event.target.value)
                            } else {
                                setRecurrentType(event.target.value)
                            }
                        }}></input>
                        événement récurrent journalier
                    </label>
                </div>
                {recurrentType !== null ? (
                    recurrentType === 'hebdomadaire' ? (
                        <>
                            <div style={{ width:'100%', marginTop:30 }}>
                                <label htmlFor="weekly">
                                    Nombre de semaine récurrente (Max. 12)
                                    <input className='input-text' name="amountWeekly" style={{ backgroundColor:'#F0F0F0', border:`${errors.amountWeekly.value !== '' ? !errors.amountWeekly.isValid ? '1px solid red' : '1px solid green' : 'none'}` }} type="number" value={amountWeekly} id="weekly" min="1" max="12" onChange={(event) => {
                                        setAmountWeekly(event.target.value)
                                        validationField(event);
                                    }} />
                                    <p style={{color: 'red', marginTop: 10}}>{errors && !errors.amountWeekly.isValid ? errors.amountWeekly.error : null}</p>
                                </label>
                            </div>
                            <div style={{ display:'flex',flexDirection:'column', justifyContent:'space-between', width:'100%', marginTop:20, marginBottom:20 }}>
                                <div className="datepicker-full-width" style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'100%' }}>
                                    <label>Date :</label>
                                    <Rdate dateFormat="YYYY-MM-DD" timeFormat={false} isValidDate={ valid } onChange={(date) => {
                                        setWeeklyDate(date);
                                    }} input={false}></Rdate>
                                </div>
                                {weeklyDate !== null ? (
                                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%' }}>
                                        <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content' }}>
                                            <label>De :</label>
                                            <Rdate dateFormat={false} onChange={(date) => {
                                                const timeStartDate = date.format('HH:mm');
                                                const minuteStartDate = timeStartDate.split(':')[1];
                                                const hourStartDate = timeStartDate.split(':')[0];

                                                const startDate = weeklyDate.clone().set('hour', hourStartDate).set('minute', minuteStartDate)
                                                setWeeklyTimeDateStart(startDate)
                                            }} input={false} timeConstraints={{ hours: { min: 8, max: 18, step: 1 }}} ></Rdate>
                                        </div>
                                        <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content' }}>
                                            <label>A :</label>
                                            <Rdate dateFormat={false} onChange={(date) => {
                                                const timeEndDate = date.format('HH:mm');
                                                const minute = timeEndDate.split(':')[1];
                                                const hour = timeEndDate.split(':')[0];

                                                const endDate = weeklyDate.clone().set('hour', hour).set('minute', minute)
                                                setWeeklyTimeDateEnd(endDate)
                                            }} input={false} timeConstraints={{ hours: { min: 8, max: 18, step: 1 }}} ></Rdate>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ width:'100%', marginTop:30 }}>
                                <label htmlFor="daily">
                                    Nombre de jour récurrent (Max. 21)
                                    <input className='input-text' name="amountDaily" style={{ backgroundColor:'#F0F0F0', border:`${errors.amountDaily.value !== '' ? !errors.amountDaily.isValid ? '1px solid red' : '1px solid green' : 'none'}` }} type="number" value={amountDaily} id="daily" min="1" max="21" onChange={(event) => {
                                        setAmountDaily(event.target.value)
                                        validationField(event);
                                    }} />
                                </label>
                                <p style={{color: 'red', marginTop: 10}}>{errors && !errors.amountDaily.isValid ? errors.amountDaily.error : null}</p>
                            </div>
                            <div style={{ display:'flex',flexDirection:'column', justifyContent:'space-between', width:'100%', marginTop:20, marginBottom:20 }}>
                                <div className="datepicker-full-width" style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'100%' }}>
                                    <label>Date :</label>
                                    <Rdate dateFormat="YYYY-MM-DD" timeFormat={false} isValidDate={ valid } onChange={(date) => {
                                        setDailyDate(date);
                                    }} input={false}></Rdate>
                                </div>
                                {dailyDate !== null ? (
                                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%' }}>
                                        <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content' }}>
                                            <label>De :</label>
                                            <Rdate dateFormat={false} onChange={(date) => {
                                                const timeStartDate = date.format('HH:mm');
                                                const minuteStartDate = timeStartDate.split(':')[1];
                                                const hourStartDate = timeStartDate.split(':')[0];

                                                const startDate = dailyDate.clone().set('hour', hourStartDate).set('minute', minuteStartDate)
                                                setDailyTimeDateStart(startDate)
                                            }} input={false} timeConstraints={{ hours: { min: 8, max: 18, step: 1 }}} ></Rdate>
                                        </div>
                                        <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content' }}>
                                            <label>A :</label>
                                            <Rdate dateFormat={false} onChange={(date) => {
                                                const timeEndDate = date.format('HH:mm');
                                                const minute = timeEndDate.split(':')[1];
                                                const hour = timeEndDate.split(':')[0];

                                                const endDate = dailyDate.clone().set('hour', hour).set('minute', minute)
                                                setDailyTimeDateEnd(endDate)
                                            }} input={false} timeConstraints={{ hours: { min: 8, max: 18, step: 1 }}} ></Rdate>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    )
                ) : null}
                <button style={{ border:'none', background:'transparent', color:'#0578BE', textTransform:'uppercase', fontSize:14, marginTop:20, fontWeight:'bold' }} onClick={() => {
                    if(recurrentType === 'hebdomadaire' && errors.eventTitle.isValid && errors.eventType.isValid && errors.amountWeekly.isValid) {
                        createEventHebdo({
                            amount: parseInt(amountWeekly),
                            title: tempName,
                            start: weeklyTimeDateStart.format('YYYY-MM-DD HH:mm:ss'),
                            end: weeklyTimeDateEnd.format('YYYY-MM-DD HH:mm:ss'),
                            visitType: `/api/visit_types/${tempType}`,
                            color: "#0000",
                            status: "valid"
                        })
                    } else if(recurrentType === 'journalier' && errors.eventTitle.isValid && errors.eventType.isValid && errors.amountDaily.isValid) {
                        createEventDaily({
                            amount: parseInt(amountDaily),
                            title: tempName,
                            start: dailyTimeDateStart.format('YYYY-MM-DD HH:mm:ss'),
                            end: dailyTimeDateEnd.format('YYYY-MM-DD HH:mm:ss'),
                            visitType: `/api/visit_types/${tempType}`,
                            color: "#0000",
                            status: "valid"
                        })
                    }
                }}>Valider</button>
            </Modal>

            <Modal
            isOpen={showModalOverlap}
            //onAfterOpen={afterOpenModal}
            onRequestClose={() => {
                setShowModalOverlap(false);
            }}
            style={customStyles}
            contentLabel="Example Modal"
            >
                <div style={{position:'relative'}}>
                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%' }}>
                        <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="1x" icon={faTimes} onClick={() => {setShowModalOverlap(false)}} />
                    </div>
                    <div style={{ width:'100%', marginBottom:'28px' }}>
                        <p style={{fontSize:'16px'}}><span style={{color:'red'}}>Attention !</span> Vous ne pouvez pas créer cet événement car il en existe déjà un sur ce créneau ! Merci de choisir un autre créneau.</p>
                    </div>
                </div>
            </Modal>

            <Modal
            isOpen={showModalEdit}
            //onAfterOpen={afterOpenModal}
            onRequestClose={() => {
                setTempName("");
                setTempStartDate(null);
                setTempEndDate(null);
                setTempType(null);
                setShowModalEdit(false);
            }}
            style={customStyles}
            contentLabel="Example Modal"
            >
                <div style={{position:'relative'}}>
                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%' }}>
                        <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="1x" icon={faTimes} onClick={() => {
                            setTempName("");
                            setTempStartDate(null);
                            setTempEndDate(null);
                            setTempType(null);
                            setShowModalEdit(false);
                        }} />
                    </div>
                    <div style={{ width:'100%', marginBottom:'28px' }}>
                        <p style={{fontSize:'16px'}}><span style={{color:'red'}}>Attention !</span> Ce créneau est réservé, souhaitez-vous tout de même le modifier ?</p>
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-evenly', width:'100%'}}>
                    <button style={{ border:'none', background:'transparent', color:'#0578BE', textTransform:'uppercase', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }} onClick={() => {
                        setShowModalEdit(false);
                        setShowModal(true);
                    }}>Oui</button>
                    <button style={{ border:'none', background:'transparent', color:'red', textTransform:'uppercase', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }} onClick={() => {
                        setTempName("");
                        setTempStartDate(null);
                        setTempEndDate(null);
                        setTempType(null);
                        setShowModalEdit(false);
                    }}>Non</button>
                </div>
            </Modal>
        </>
    );
};

// == Export
export default PlanningReservation;