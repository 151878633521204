// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import GererReservation from '../../screen/GererReservation';

// Action Creators
import { getVisitesRequest, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisitesRequest: (page) => {
    dispatch(getVisitesRequest(page));
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GererReservation);