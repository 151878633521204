// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import PlanningReservation from '../../screen/PlanningReservation';

// Action Creators
import { getVisites, createVisites, editVisites, getVisitTypes, deleteEvent, createEventHebdo, createEventDaily, createVisiteRequestAdmin, getSchools, getUsers } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  visitTypes: state.visitTypes,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  schools: state.schools,
  users: state.users,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisites: () => {
    dispatch(getVisites());
  },
  createVisites: (name, start, end, visitType) => {
    dispatch(createVisites(name, start, end, visitType, true, ownProps.history));
  },
  editVisites: (name, id, start, end, visitType) => {
    dispatch(editVisites(name, id, start, end, visitType, true, ownProps.history));
  },
  getVisitTypes: () => {
    dispatch(getVisitTypes());
  },
  deleteEvent: (id) => {
    dispatch(deleteEvent(id));
  },
  createEventHebdo: (object) => {
    dispatch(createEventHebdo(object, ownProps.history))
  },
  createEventDaily: (object) => {
    dispatch(createEventDaily(object, ownProps.history))
  },
  createVisiteRequestAdmin: (object) => {
    dispatch(createVisiteRequestAdmin(object, ownProps.history))
  },
  getSchools: (object) => {
    dispatch(getSchools("1&pagination=false", ownProps.history))
  },
  getUsers: (object) => {
    dispatch(getUsers("1&pagination=false", ownProps.history))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanningReservation);