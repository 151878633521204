// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Alveoles from '../../screen/Alveoles';

// Action Creators
import { getalveoles, createAvleole } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getalveoles: () => {
    dispatch(getalveoles());
  },
  createAvleole: () => {
    dispatch(createAvleole());
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Alveoles);