// == Import : npm
import React, { useState } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'contenu',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const VisitesAjout = ({ location, history, match, waitingRequest, createVisitType, connected }) => {
    
    const [partenaires, setPartenaires] = useState([null]);
    const [name, setName] = useState(null);
    const [desc, setDesc] = useState(null);
    const [mediateur, setMediateur] = useState(null);

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }


    return (
        <>
        <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper'>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
            <h1 className='page-title'>Ajouter un type de visite</h1>
            <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:20 }}></img>Retour</button>
        </div>
        <div className='grey-background' style={{ marginBottom: 50 }}>
                <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase', marginTop:15 }}>Informations générales</h2>
                <label style={{ marginTop: 10, marginBottom:10, fontWeight:500, textTransform:'uppercase' }}>Nom du type de visite</label>
                <input type='text' className="input-text" placeholder='titre...' value={name} onChange={(e) => {setName(e.target.value)}}></input>
                <label style={{ marginTop: 10, marginBottom:10, fontWeight:500, textTransform:'uppercase' }}>Descriptif</label>
                <textarea className="input-text" style={{ maxWidth:'96%', minWidth:'96%' }} placeholder='description...' value={desc} onChange={(e) => {setDesc(e.target.value)}}></textarea>
                <label style={{ marginTop: 10, marginBottom:10, fontWeight:500, textTransform:'uppercase' }}>Partenaires à l'événement</label>
                {partenaires.map((partenaire, index) => (
                    <input type='text' style={{ marginBottom:10 }} className="input-text" placeholder='titre...' value={partenaire} onChange={(e) => {
                        const newPartenaires = partenaires;
                        newPartenaires[index] = e.target.value;
                        setPartenaires(newPartenaires);
                    }}></input>
                ))}
                <button style={{ color:'#0578BE', border:'none', background:'none', fontStyle:'italic', textDecoration:'underline' }} onClick={() => {setPartenaires([...partenaires, null])}}>+ Ajouter un partenaire</button>
                <label style={{ marginTop: 10, marginBottom:10, fontWeight:500, textTransform:'uppercase' }}>Médiateur/animateur événement</label>
                <input type='text' className="input-text" placeholder='nom...' value={mediateur} onChange={(e) => {setMediateur(e.target.value)}}></input>
        </div>
        <div style={{ display:'flex', margin:'0px 0% 10px 0%' }}>
            <p style={{color:'#0578BE',fontSize:20, marginTop: 10, marginBottom:10, marginRight:40, fontWeight:500, textTransform:'uppercase' }}>événements liés</p>
            <button className='button'>Accéder</button>
        </div>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
            <button onClick={() => {createVisitType(name, desc, partenaires, mediateur)}} className='button'><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
        </div>
    </div>
    </>
    )
};

// == Export
export default VisitesAjout;