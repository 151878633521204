// == Import : npm
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import SelectSearch from 'react-select-search';

// == Import : local
import '../../style/style.scss';
import './home.scss';
import Header from '../../containers/Header';

const headerEntries = [
    // {
    //     title:'Qu\'est ce que l\'appli MIA',
    //     link:'/maison-ia',
    //     class:''
    // },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    // {
    //     title:'Contact',
    //     link:'/contact',
    //     class:'button button-header'
    // }
]

// == Composant
const NouveauMembre = ({location, match, waitingRequest, createUser, getSchoolsAnonymous, datas}) => {

    const [field, setField] = useState({
        email: {
            value: '',
            isValid: false,
            error: ''
        },
        schoolName: {
            value: '',
            isValid: false,
            error: ''
        },
        teacherName: {
            value: '',
            isValid: false,
            error: ''
        },
        schoolAddress: {
            value: '',
            isValid: false,
            error: ''
        },
        schoolPhone: {
            value: '',
            isValid: false,
            error: ''
        },
        password: {
            value: '',
            isValid: false,
            error: ''
        },
        passwordConfirm: {
            value: '',
            isValid: false,
            error: ''
        },
    });
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [init, setInit] = useState(false);
    const [schools, setSchools] = useState(null);

    useState(() => {
        //getSchoolsAnonymous()
    }, [])

    if(datas['@id'] === `/api/schools` && !init) {
        setSchools(datas['hydra:member'])
        setInit(true);
    }

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const handleChange = (event) => {
        const phoneRegex = /^((\+)33|0)[1-9](\d{2}){4}$/g
        const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

        const input = event.target;
        let inputValidationResult = false;
        let inputValidationError= '';
        switch (input.name) {
            case 'email':
                inputValidationResult = mailRegex.test(input.value) && input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Veuillez entrer une adresse email valide !';
                break;
            case 'schoolName':
                inputValidationResult = input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Ce champ est obligatoire.';
                break;
            case 'teacherName':
                inputValidationResult = input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Ce champ est obligatoire.';
                break;
            case 'schoolAddress':
                inputValidationResult = input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Ce champ est obligatoire.';
                break;
            case 'schoolPhone':
                inputValidationResult = phoneRegex.test(input.value) && input.value.length > 0;
                inputValidationError = inputValidationResult ? '' : 'Veuillez entrer un numéro de téléphone valide !';
                break;
            case 'password':
                inputValidationResult = input.value.length > 6;
                inputValidationError = inputValidationResult ? '' : 'Le mot de passe doit contenir au moins 6 caractères.';
                break;
            case 'passwordConfirm':
                inputValidationResult = input.value === field.password.value;
                inputValidationError = inputValidationResult ? '' : 'Les mot de passe ne correspondent pas.';
                break;
            default:
                inputValidationResult = false;
        }

        setField({
            ...field,
            [input.name]: {
                ...field[input.name],
                name: input.name,
                value: input.value,
                isValid: inputValidationResult,
                error: inputValidationError
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if(field.email.isValid) {
            createUser(field, selectedSchool)
        }
    }

    return (
  <div>
    <Header entries={headerEntries} /*etablissement*/ location={location} match={match} />
    <div className="home-wrapper" style={{ display:'flex', flexDirection:'column' }}>
        <form onSubmit={handleSubmit} autoComplete="disabled">
            <div className="connection-wrapper">
                <h1>Nouveau Membre</h1>
                <div className="input-wrapper">
                    <input type="mail" title={field.email.error !== '' ? `${field.email.error}` : ''} autoComplete="disabled" style={{ background:'#F0F0F0', border:`${field.email.value !== '' ? field.email.error !== '' ? '1px solid red' : '1px solid green' : 'none'}` }} name="email" className="input-text" onChange={handleChange} placeholder="Adresse e-mail"></input>
                </div>
                <p style={{textAlign:'left', width:'100%', color:'white', marginBottom:5}}>Recherchez votre établissement en tapant le code postal :</p>
                <div className="input-wrapper">
                <SelectSearch
                    options={[]}
                    getOptions={(query) => {
                        //getOptions(query)
                        return new Promise((resolve, reject) => {
                            fetch(`https://mia.api.wacan-extranet.com/api/schools?zipCode=${query}`,{ 
                                    method: 'GET',
                                    headers: new Headers({
                                        'Accept': 'application/ld+json',
                                        'Content-Type' : 'application/ld+json',
                                    })
                                })
                                .then(response => response.json())
                                .then((response) => {
                                    resolve(response['hydra:member'].map((elem) => ({value: elem.id, name: elem.name})))
                                })
                                .catch(reject);
                        });
                    }}
                    type="search"
                    //style={{ position: 'fixed', left: -1000 }}
                    value={selectedSchool}
                    search
                    autoComplete="disabled"
                    placeholder="Selectionnez votre établissement"
                    onChange={(e) => setSelectedSchool(e)}
                />
                </div>
                <div style={{ textAlign:'left', width:'100%' }}>
                    <label style={{ color:'white' }}>
                        <input required type="checkbox"></input>
                        Je certifie que les informations renseignées sont exactes.
                    </label>
                </div>
                <div style={{width: '100%', display:'flex', justifyContent:'space-between'}}>
                    {/*<input value="Valider" type="submit" className="button input-submit button-shadow"></input>*/}
                    <button className="button input-submit button-shadow" type="submit">Valider</button>
                </div>
                <div style={{marginTop:30}}>
                    <p style={{textAlign:'left', width:'100%', color:'white'}}>Si votre établissement ne figure pas dans la liste, merci d’envoyer un mail à <a style={{textAlign:'left', width:'100%', color:'white'}} href="mailto:contact@maison-intelligence-artificielle.com">contact@maison-intelligence-artificielle.com</a></p>
                </div>
            </div>
        </form>
    </div>
  </div>
)};

// == Export
export default NouveauMembre;