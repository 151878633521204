// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Evenements from '../../screen/Evenements';

// Action Creators
import { getVisites } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisites: (page) => {
    dispatch(getVisites(page));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Evenements);