// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappVisite from '../../screen/WebappVisite';

// Action Creators
import { getVisitesRequest, cancelSchoolVisitRequest } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisitesRequest: () => {
    dispatch(getVisitesRequest());
  },
  cancelSchoolVisitRequest: (id) => {
    dispatch(cancelSchoolVisitRequest(id));
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappVisite);