// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import App from '../../App';

// Action Creators
import { getApp, createAvleole } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  //waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*getApp: () => {
    dispatch(getApp());
  },*/
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);