// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import FileUpload from '../../components/FileUpload';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const BoiteAjout = ({ location, history, match, waitingRequest, datas, createTools, upload, cats, getCat, setMessageNotif, connected }) => {
    
    const [name, setName] = useState(null);
    const [cat, setcat] = useState(null);
    const [file, setFile] = useState(null);
    const [reset, setReset] = useState({});
    const [categories, setCats] = useState([]);
    const [init, setInit] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [displayVideo, setDisplayVideo] = useState(false);
    const [videoLink, setVideoLink] = useState('');
    const [schoolOnly, setSchoolOnly] = useState(true)

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getCat()
    }, [])
    
    if(cats['@id'] === '/api/categories' && !init) {
        setCats(cats['hydra:member']);
        setInit(true)
    }

    //console.log(cats)

    //console.log(file)


    const arrondi = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }

    const OnClickDo = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'image/jpg' || fileURI.type === 'image/png' || fileURI.type === 'image/jpeg') {
          //setFileName(fileURI.name)
          setFileUrl(URL.createObjectURL(fileURI))
    
          const data = new FormData();
          data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
          setFile(data)
        } else if(fileURI.type === 'application/pdf') {
            setFileUrl('')
            const data = new FormData();
            data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
            setFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const data = new FormData();

    return (
    <>
    <Header currentMenuItem={0} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper'>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%', flexDirection:'row', width:'100%' }}>
            <h1 className='page-title'>AJOUTER UNE ACTIVITE</h1>
            <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Nom du fichier</div>
                <input type='text' className="input-text" placeholder='Nom du fichier' value={name} onChange={(e) => {setName(e.target.value)}}></input>
            </div>
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Indiquer la categorie</div>
                <div className="white-background">
                    {
                        categories.map((element) => (
                            <div>
                                <label htmlFor={element.id} style={{ fontWeight:500 }}>
                                    <input type="radio" id={element.id} style={{width:'auto'}} name="category" value={element.title} onClick={(e) => {if(e.target.checked){setcat(e.target.id)}}} />
                                    {element.title}
                                </label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Type de fichier Téléchargé</div>
                {file ? file.get('file').type !== 'application/pdf' ? file.get('file').type === 'application/pdf' ? null : (
                    <div style={{textAlign:'center', marginTop:30}}>
                        <img src={fileUrl} style={{width:'auto',height:'auto', maxWidth:'700px'}} alt=""/>
                    </div>
                ) : (
                    <div style={{textAlign:'center', marginTop:30}}>
                        <img src='/images/pdf-color.svg' style={{width:45,height:'auto'}} alt=""/>
                    </div>
                ) : null}
                <div style={{textAlign:'left'}} className="file-details">
                    {file ? <h4>Fichier: {file.get('file').name}</h4> : null}
                    {file ? <h4>Size: {arrondi((file.get('file').size/1024)/1024)} Mo</h4> : null}
                </div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
                    <label className="white-background button" onClick={() => setDisplayVideo(false)} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                        <div>PDF</div>
                        <img src="/images/pdf-icon.svg"></img>
                        <input
                            className="custom-file-input"
                            style={{display:'none'}}
                            type="file"
                            name="customFile"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                            accept="application/pdf"
                            onChange={(e) => {
                                const accept = "application/pdf"
                                accept.split(',').forEach((element) => {
                                if(element === element.replace(/\s+/g, '')){
                                    OnClickDo(e)
                                }
                                })
                            }}
                        />
                        <div className="input-button">Parcourir...</div>
                    </label>
                    <label className="white-background button" onClick={() => setDisplayVideo(false)} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                        <div> Image</div>
                        <img src="/images/image-icon.svg"></img>
                        <input
                            className="custom-file-input"
                            style={{display:'none'}}
                            type="file"
                            name="customFile"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                            accept="image/x-png,image/jpeg"
                            onChange={(e) => {
                                const accept = "image/png, image/jpeg"
                                accept.split(',').forEach((element) => {
                                if(element === element.replace(/\s+/g, '')){
                                    OnClickDo(e)
                                }
                                })
                            }}
                        />
                        <div className="input-button">Parcourir...</div>
                    </label>
                    <label className="button white-background" onClick={() => {
                        if(file) {
                            setFile(null);
                            setDisplayVideo(true)
                        } else {
                            console.log('clique')
                            setDisplayVideo(true)
                        }
                    }} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', alignItems:'center', cursor:'pointer' }}>
                        <div>Lien Vidéo</div>
                        <img style={{width:34, height:42, marginBottom:0}} src="/images/video-icon.svg"></img>
                        <div></div>
                    </label>
                </div>
                {displayVideo ? (
                    <div>
                        <input type='text' className='input-text' placeholder='Lien vidéo' style={{marginTop:30, backgroundColor:'#F0F0F0', width:'100%'}} value={videoLink} onChange={(e)=>{setVideoLink(e.target.value)}} />
                    </div>
                ) : null}
                <div onClick={() => {if(name!=null && name!="" && cat!=null && (file!=null || videoLink !== '')){createTools(name, cat, file, file ? file.get('file').type : null, videoLink, '+accepted', history, undefined, undefined, undefined, schoolOnly, undefined, undefined, undefined)}else{setMessageNotif('Veuillez renseigner tout les champs', false)}}} className="button" style={{ margin:'0 auto', marginTop:40, height:'fit-content', cursor:'pointer' }}><img src="/images/check-icon.svg" style={{ width:15, height:15 }}></img>Valider</div>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default BoiteAjout;