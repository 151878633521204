// == Import : npm
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import { useCookies, withCookies } from 'react-cookie'

// == Import : local
import '../../style/style.scss';
import './home.scss';
import Header from '../../containers/Header';

const headerEntries = [
    // {
    //     title:'Qu\'est ce que l\'appli MIA',
    //     link:'/maison-ia',
    //     class:''
    // },
    // {
    //     title:'Comment l\'utiliser',
    //     link:'/ia-departement',
    //     class:''
    // },
    // {
    //     title:'Contact',
    //     link:'/contact',
    //     class:'button button-header'
    // }
]

// == Composant
const Home = ({location, match, waitingRequest, login, cookies}) => {

    const [mail, setMail] = useState(null);
    const [password, setPassword] = useState(null);
    

    //cookies.set('test', 'test')

    //console.log('cookies', cookies.getAll())

    if(waitingRequest > 0) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const checkFormLogin = (event) => {
        event.preventDefault();
        if (mail !== null && password !== null) {
            login(mail, password)
        }
    }

    return (
  <div>
    <Header entries={headerEntries} /*etablissement*/ etablissementUnderLine location={location} match={match} />
    <div className="home-wrapper">
        <div className="connection-wrapper">
            <h1>Connectez-vous</h1>
            <form style={{width:'100%'}} noValidate autocomplete onSubmit={checkFormLogin}>
                <div className="input-wrapper">
                    <input type="text" className="input-text" placeholder="Adresse e-mail" name="email" value={mail} onChange={(e) => {setMail(e.target.value)}}></input>
                </div>
                <div className="input-wrapper">
                    <input type="password" className="input-text" placeholder="Mot de passe" name="password" value={password} onChange={(e) => {setPassword(e.target.value)}}></input>
                    <Link to="/forget-password" className="forget-link">Mot de passe oublié</Link>
                </div>
                <div style={{width: '100%', display:'flex', justifyContent:'space-between'}}>
                    <Link to="/nouveau-membre" className="inscription-link button-shadow">Nouveau Membre</Link>
                    {/*<input onClick={() => {login(mail, password)}} value="CONNEXION" type="submit" className="button input-submit button-shadow"></input>*/}
                    <button className="button input-submit button-shadow" type="submit">
                        <span>Envoyer</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
  </div>
)};

// == Export
export default Home;