// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg'; // a remove
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactLoading from 'react-loading';
import QRCode from 'qrcode.react';
import { guid } from 'react-agenda';
import FileUpload from '../../components/FileUpload';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
]

// == Composant
const AlveolesContentAjout = ({ location, history, match, waitingRequest, createAlveoleContent, quizzs, getQuiz, connected }) => {

    const [name, setName] = useState(null);
    const [body, setBody] = useState(null);
    const [online, setOnline] = useState(null);
    const [qr, setQr] = useState("");
    const [file, setFile] = useState(null);
    const [Imageurl, setImageurl] = useState('');
    const [oldFile, setOldFile] = useState('');
    const [quiz, setQuiz] = useState(null)
    const [fileUrl, setFileUrl] = useState('');
    const [displayVideo, setDisplayVideo] = useState(false);
    const [videoLink, setVideoLink] = useState('');

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getQuiz(1, '')
        //createAlveoleContent('name', 'body', 'online', 'qr', 1)
    }, [])

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const handleClick = () => {
        if (name !== null && body !== null && online !== null && qr !== "" && (file !== '' || videoLink !== '')) {
            createAlveoleContent(name, body, online, qr, quiz, file, videoLink, match.params.name)   
        }
    }

    const arrondi = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }

    const OnClickDo = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'image/jpg' || fileURI.type === 'image/png' || fileURI.type === 'image/jpeg') {
          setFileUrl(URL.createObjectURL(fileURI))
    
          const data = new FormData();
          data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
          setFile(data)
        } else if(fileURI.type === 'application/pdf') {
            setFileUrl('')
            const data = new FormData();
            data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
            setFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    const onImageChange = (event) => {
        if(event.target.files.length !== 0) {
            const reader = new FileReader();
    
            reader.onload = (e) => {
                setImageurl({ imageSrc: e.target.result });
                console.log(e.target.result)
            }
    
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    return (
        <>
        <Header  currentMenuItem={1} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>Ajouter une activité</h1>
                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                <label style={{ marginTop: 10, textTransform:'uppercase' }}>Titre de l'activité</label>
                <input type='text' className="input-text" style={{ border:'1px solid lightgray' }} placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value); setQr(`${e.target.value}/${guid()}`)}}></input>
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={body}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setBody(data);
                        console.log( { event, editor, data, body } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
            <div className='fieldset-wrapper'>
                {file ? file.get('file').type !== 'application/pdf' ? file.get('file').type === 'application/pdf' ? null : (
                    <div style={{textAlign:'center', marginTop:30}}>
                        <img src={fileUrl} style={{width:'auto',height:'auto', maxWidth:'700px'}} alt=""/>
                    </div>
                ) : (
                    <div style={{textAlign:'center', marginTop:30}}>
                        <img src='/images/pdf-color.svg' style={{width:45,height:'auto'}} alt=""/>
                    </div>
                ) : null}
                <div style={{textAlign:'left'}} className="file-details">
                    {file ? <h4>Fichier: {file.get('file').name}</h4> : null}
                    {file ? <h4>Size: {arrondi((file.get('file').size/1024)/1024)} Mo</h4> : null}
                </div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly' }}>
                    <label className="white-background button" onClick={() => setDisplayVideo(false)} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                        <div>Image</div>
                        <img style={{width:34, height:42, marginBottom:0}} src="/images/image-icon.svg"></img>
                        <input
                            className="custom-file-input"
                            style={{display:'none'}}
                            type="file"
                            name="customFile"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                            accept="image/x-png,image/jpeg"
                            onChange={(e) => {
                                const accept = "image/png, image/jpeg"
                                accept.split(',').forEach((element) => {
                                if(element === element.replace(/\s+/g, '')){
                                    OnClickDo(e)
                                }
                                })
                            }}
                        />
                        <div className="input-button">Parcourir...</div>
                    </label>
                    <label className="button white-background" onClick={() => {
                        if(file) {
                            setFile(null);
                            setDisplayVideo(true)
                        } else {
                            console.log('clique')
                            setDisplayVideo(true)
                        }
                    }} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', alignItems:'center', cursor:'pointer' }}>
                        <div>Lien Vidéo</div>
                        <img style={{width:34, height:42, marginBottom:0}} src="/images/video-icon.svg"></img>
                        <div></div>
                    </label>
                </div>
                {displayVideo ? (
                    <div>
                        <input type='text' className='input-text' placeholder='Lien vidéo' style={{marginTop:30, backgroundColor:'#F0F0F0', width:'100%'}} value={videoLink} onChange={(e)=>{setVideoLink(e.target.value)}} />
                    </div>
                ) : null}
            </div>
            <div style={{textAlign:'left', margin:'30px 0', padding:50}}>
                <select name="quizz" id="quizz" className='button button-shadow' onChange={e => {setQuiz(e.target.value)}}>
                    <option value="">Choisir un quizz</option>
                    {quizzs['hydra:member'] ? quizzs['hydra:member'].map(element => (
                        <option value={element.id}>{element.title}</option>
                    )) : <></>}
                </select>
            </div>
            <div className='grey-background' >
                <label style={{ marginTop: 10, textTransform:'uppercase' }}>QR code (généré avec le titre de l'activité)</label>
                <QRCode style={{ marginTop:20, marginBottom:20 }} value={qr} />
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                <div>
                    <input type="radio" id="En ligne" name="drone" value="En ligne" onClick={(e) => {if(e.target.checked){setOnline(e.target.id)}}} />
                    <label for="En ligne">En ligne</label>
                </div>

                <div>
                    <input type="radio" id="Hors ligne" name="drone" value="Hors ligne" onClick={(e) => {if(e.target.checked){setOnline(e.target.id)}}} />
                    <label for="Hors ligne">Hors ligne</label>
                </div>
                <button onClick={handleClick} style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
            </div>
        </div>
        </>
    )
};

// == Export
export default AlveolesContentAjout;