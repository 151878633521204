// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappInfo from '../../screen/WebappInfo';

// Action Creators
import { editWebContent, getWebContent } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  contactContent: state.contact,
  transportContent: state.transport,
  recommandationContent: state.recommandation,
  user: state.connected,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editWebContent: (id, content) => {
    dispatch(editWebContent(id, content));
  },
  getWebContent: (id) => {
    dispatch(getWebContent(id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappInfo);