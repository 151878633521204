// == Import : npm
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

// == Import : local
import '../../style/style.scss';
//import './header.scss';

// == Composant
const FileUpload = ({accept, inputText, OnClickDo, buttonClass, buttonStyle, id }) => {

    let inputElement = null;

    return (
        <>
            <input ref={input => {inputElement = input}} id={id} style={{display:'none'}} type="file" name="file"
            accept={accept} onChange={(e) => {
                accept.split(',').forEach((element) => {
                    if(element === element.replace(/\s+/g, '')){
                        OnClickDo(e)
                    }
                })
            }}></input>
            <div className={buttonClass} style={buttonStyle} onClick={() => {inputElement.click()}}>{inputText}</div>
       </>
    );
};

// == Export
export default FileUpload;