// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/archives', breadcrumb: 'Historique' },
];

// == Composant
const WebappArchives = ({ location, match, waitingRequest, connected, MyVisitsRequests, getMyVisitsRequests }) => {
    
    useEffect(() => {
        getMyVisitsRequests()
    }, [])

    const breadcrumbs = useBreadcrumbs(routes);

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    {/*<Header currentMenuItem={1} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'50px 0% 0 0', alignSelf:'flex-start' }}>
            <h1 className='page-title'>Historique</h1>
        </div>
        {/*<div style={{backgroundColor:'#0578BE', borderRadius:10, height:52, overflow:'hidden', width:800, maxWidth:'100vw', display:'flex', flexDirection:'row', marginTop:70 }}>
            <Link style={{width:'50%', maxWidth:'50vw', lineHeight:'52px', backgroundColor:'#F33c3C', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                Les visites
            </Link>
            <Link style={{width:'50%', maxWidth:'50vw', lineHeight:'52px', padding:'0 50px', color:'white', textDecoration:'none', textTransform:'uppercase', fontWeight:'bold'}}>
                Les quiz préparatoires
            </Link>
        </div>*/}

        {MyVisitsRequests && MyVisitsRequests['hydra:member'].map(element => (
            <Link to={`/archives/${element.visit['@id'].split('/')[3]}`} style={{textDecoration:'none', width:'100%'}}>
                <div className='button-shadow' style={{ maxWidth:'100%', maxHeight:50, borderRadius:10, backgroundColor:'#0578BE', marginTop:40, color:'white', fontSize:15, fontWeight:400, display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'space-between', padding:'10px 50px'}}>
                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                        {element.visit.title ? element.visit.title : element.visit.visitType.title}
                    </div>
                    <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0 }}></img>
                </div>
            </Link>
        ))}
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappArchives;