// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import VoirContenus from '../../screen/VoirContenus';

// Action Creators
import { getTool, acceptTool, refusedTool, getCat } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  cats:state.cat,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTool: (id) => {
    dispatch(getTool(id));
  },
  acceptTool: (id, status, category) => {
    dispatch(acceptTool(id, status, category))
  },
  refusedTool: (id, status, category) => {
    dispatch(refusedTool(id, status, category))
  },
  getCat: () => {
    dispatch(getCat());
  },
  /*editTool: (id, status, name, cat, file, history) => {
    dispatch(editTool(id, status, name, cat, file, ownProps.history));
  },*/
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoirContenus);