// == Import : npm
import React, { useEffect } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination'

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const Evenements = ({ location, history, match, waitingRequest, getVisites, datas, connected }) => {

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getVisites(match.params.page)
    }, [])
    
    const number_of_page = datas['hydra:view'] !== undefined && datas['hydra:view'] !== [] ? datas['hydra:view']['hydra:last'].split('=')[datas['hydra:view']['hydra:last'].split('=').length-1] : null;
    console.log(datas)
    
    if(match.params.page < 1 || (match.params.page > number_of_page && number_of_page !== null)) {
        return <Redirect to="/notFound" />
    }

    const pagination = (number_of_page) => {
        let toDisplay = [];
        for (let index = 1; index <= number_of_page; index++) {
            toDisplay[index] = <Link 
            to={`/admin/parametres/evenements/${index}`} 
            className='page-number' 
            style={match.params.page == index ? {textDecoration: 'underline'} : {textDecoration:'none'}}>
                {index}
            </Link>;
        }

        console.log(toDisplay);

        return (<>{toDisplay}</>);
    }


    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }


    return (
        <>
        <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper'>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 50px' }}>
            <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                <h1 className='page-title' style={{ textTransform:'uppercase', margin:0 }}>Gestion des événements</h1>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <Link to={'/admin/parametres/etablissements/ajout'} className={"button"} style={{marginRight:20}}>Ajouter</Link>
                    <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                </div>
            </div>
        </div>

        <div className='table-wrapper'>
            <table>
                <thead>
                    <tr>
                        <th>Nom de l'événement</th>
                        <th>Type de visite</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {datas['@id'] === '/api/visits' ? datas['hydra:member'].map((element) => (
                        <tr>
                            <td>{element.title}</td>
                            <td>{element.visitType ? element.visitType.title : null}</td>
                            <td>
                                <Link to={`/admin/parametres/evenements/edit/${element.id}`}>voir</Link>
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </table>

            <div className='pagination'>
                <Pagination datas={datas} url={'/admin/parametres/evenements/'} match={match} />
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default withRouter(Evenements);