// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../containers/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';
import FileUpload from '../../components/FileUpload';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/mediatheque', breadcrumb: 'Médiathèque' },
    { path: '/mediatheque/charger-contenu', breadcrumb: 'Charger un contenu' },
];

const MAX_FILE_SIZE = 10

// == Composant
const WebappAjoutContenu = ({ location, match, waitingRequest, createTools, setMessageNotif, connected }) => {


    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState('');
    const [reset, setReset] = useState({});
    const [uploaderName, setUploadName] = useState(null)
    const [uploaderLastName, setUploadLastName] = useState(null)
    const [uploaderEmail, setUploadEmail] = useState(null)
    const [uploaderClass, setUploadClass] = useState(null)
    const [schoolOnly, setSchoolOnly] = useState(false)
    const [docTitle, setDocTitle] = useState(null)
    const [docDescription, setDocDescription] = useState(null)
    const [docLevel, setDocLevel] = useState(null)
    const [displayVideo, setDisplayVideo] = useState(false)
    const [videoLink, setVideoLink] = useState('')


    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes);

    console.log(schoolOnly)

    const arrondi = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }
    
    const data = new FormData();

    const OnClickDo = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'image/jpg' || fileURI.type === 'image/png' || fileURI.type === 'image/jpeg' || fileURI.type === 'application/pdf') {
          //setFileName(fileURI.name)
          setFileUrl(URL.createObjectURL(fileURI))
          // const fileType = fileURI.type.split('/')[1]
    
          const data = new FormData();
          data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
          setFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }
    
    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{textAlign:'left', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'50px 0% 0 0' }}>
            <h1 className='page-title'>Charger un Contenu</h1>
        </div>
        <div>
            <h2 style={{ color:'#0578BE', fontWeight:400, fontSize:25, textAlign:'left' }}>1. Importer le document</h2>
            <div style={{ margin:'0 3%' }}>
                <input type='text' className='input-text' placeholder='Titre' style={{marginTop:30, backgroundColor:'#F0F0F0'}} value={docTitle} onChange={(e)=>{setDocTitle(e.target.value)}} />
                <input type='text' className='input-text' placeholder='Descripton' style={{marginTop:30, backgroundColor:'#F0F0F0'}} value={docDescription} onChange={e => setDocDescription(e.target.value)} />
                <input type='text' className='input-text' placeholder='Niveau' style={{marginTop:30, backgroundColor:'#F0F0F0'}} value={docLevel} onChange={e => setDocLevel(e.target.value)} />
            </div>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly', marginTop:30 }}>
                <label className="white-background button" onClick={() => setDisplayVideo(false)} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                    <div>PDF</div>
                    <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                    <input
                        className="custom-file-input"
                        style={{display:'none'}}
                        type="file"
                        name="customFile"
                        id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01"
                        accept="application/pdf"
                        onChange={(e) => {
                            const accept = "application/pdf"
                            accept.split(',').forEach((element) => {
                            if(element === element.replace(/\s+/g, '')){
                                OnClickDo(e)
                            }
                            })
                        }}
                    />
                    <div className="input-button">Parcourir...</div>
                    
                </label>
                <label className="white-background button" onClick={() => setDisplayVideo(false)} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                    <div>Image</div>
                    <img style={{width:34, height:42, marginBottom:0}} src="/images/image-icon.svg"></img>
                    <input
                        className="custom-file-input"
                        style={{display:'none'}}
                        type="file"
                        name="customFile"
                        id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01"
                        accept="image/x-png,image/jpeg"
                        onChange={(e) => {
                            const accept = "image/png, image/jpeg"
                            accept.split(',').forEach((element) => {
                            if(element === element.replace(/\s+/g, '')){
                                OnClickDo(e)
                            }
                            })
                        }}
                    />
                    <div className="input-button">Parcourir...</div>
                </label>
                <label className="button white-background" onClick={() => {
                    if(file) {
                        setFile(null);
                        setDisplayVideo(true)
                    } else {
                        setDisplayVideo(true)
                    }
                }} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', alignItems:'center', cursor:'pointer' }}>
                    <div>Lien Vidéo</div>
                    <img style={{width:34, height:42, marginBottom:0}} src="/images/video-icon.svg"></img>
                    <div></div>
                </label>
            </div>
            <div style={{marginTop:30}}>
                {displayVideo ? (
                    <div>
                        <input type='text' className='input-text' placeholder='Lien vidéo' style={{marginTop:30, backgroundColor:'#F0F0F0', width:'75%'}} value={videoLink} onChange={(e)=>{setVideoLink(e.target.value)}} />
                    </div>
                ) : null}
                {file ? file.get('file').type !== 'application/pdf' ? file.get('file').type === 'application/pdf' ? null : (
                    <div style={{textAlign:'center', marginTop:30}}>
                        <img src={fileUrl} style={{width:'auto',height:'auto', maxWidth:'700px', marginBottom:0}} alt=""/>
                    </div>
                ) : (
                    <div style={{textAlign:'center', marginTop:30, marginBottom:0}}>
                        <img src='/images/pdf-color.svg' style={{width:45,height:'auto'}} alt=""/>
                    </div>
                ) : null}
                <div style={{textAlign:'left'}} className="file-details">
                    {file ? <h4>Fichier: {file.get('file').name}</h4> : null}
                    {file ? <h4>Size: {arrondi((file.get('file').size/1024)/1024)} Mo</h4> : null}
                </div>
            </div>
        </div>
        <div style={{ textAlign:'left' }}>
            <h2 style={{ color:'#0578BE', fontWeight:400, fontSize:25, textAlign:'left', marginTop:50 }}>2. Informations personnelles</h2>
            <div style={{ margin:'0 3%', textAlign:'left', marginBottom:50 }}>
                <input type='text' className='input-text' placeholder='Nom' style={{marginTop:30, backgroundColor:'#F0F0F0'}} value={uploaderName} onChange={(e)=>{setUploadName(e.target.value)}} />
                <input type='text' className='input-text' placeholder='Prénom' style={{marginTop:30, backgroundColor:'#F0F0F0'}} value={uploaderLastName} onChange={e => setUploadLastName(e.target.value)} />
                <input type='email' className='input-text' placeholder='Adresse e-mail' style={{marginTop:30, backgroundColor:'#F0F0F0'}} value={uploaderEmail} onChange={e => setUploadEmail(e.target.value)} />
                <div style={{marginTop:30}}>
                    <label style={{ marginTop: 10, marginBottom:10, color:'#0578BE' }}>
                        <input onClick={e => setSchoolOnly(e.target.checked)} checked={schoolOnly} type='checkbox'></input>
                        <span style={{marginLeft:5}}>Rendre le contenu accessible à tous les utilisateurs</span>
                    </label>
                </div>
            </div>
            <div style={{marginTop:10}}>
                <label style={{ marginTop: 10, marginBottom:10, color:'#0578BE' }}>
                    <input type='checkbox'></input>
                    <span style={{marginLeft:5}}>Je certifie que les informations complétés sont exactes</span>
                </label>
            </div>
            <div style={{marginTop:10}}>
                <label style={{ marginTop: 10, marginBottom:10, color:'#0578BE' }}>
                    <input type='checkbox'></input>
                    <span style={{marginLeft:5}}>J'ai lu et pris connaissance des conditions générales d'tutilisation</span>
                </label>
            </div>
            <div style={{marginTop:10}}>
                <label style={{ marginTop: 10, marginBottom:10, color:'#0578BE' }}>
                    <input type='checkbox'></input>
                    <span style={{marginLeft:5}}>J'accepte les conditions génerales d'utilisation</span>
                </label>
            </div>
            <button onClick={() => {if(file!=null || videoLink !== ''){createTools(undefined, undefined, file, file ? file.get('file').type : null, videoLink, undefined, undefined, uploaderName, uploaderLastName, uploaderEmail, schoolOnly, docTitle, docDescription, docLevel)}else{setMessageNotif('Veuillez renseigner tout les champs', false)}}} className='button' style={{ marginTop:20, fontWeight:500 }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
        </div>
    </div>


    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappAjoutContenu;