// == Import : npm
import React, {useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const EtablissementAjout = ({ location, history, match, waitingRequest, createSchools, connected }) => {

    const [name, setName] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [phone, setPhone] = useState(null);
    const [mail, setMail] = useState(null);
    const [principale, setPrincipale] = useState(null);
    const [zipCode, setZipCode] = useState(null);

    const phoneRegex = /^((\+)33|0)[1-9](\d{2}){4}$/g
    const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase', fontWeight:500  }}>Demande d'espace établissement</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                <div className='grey-background' style={{ marginBottom: 50 }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase', fontWeight:500  }}>Coordonnées de l'établissement</h2>
                    <label style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Nom de l'établissement</label>
                    <input type='text' className="input-text" placeholder='Nom...'  style={{ marginBottom: 30,  }} value={name} onChange={(e) => {setName(e.target.value)}}></input>
                    <label style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Adresse postale</label>
                    <input type='text' className="input-text" placeholder='Adresse...'  style={{ marginBottom: 30,  }} value={adresse} onChange={(e) => {setAdresse(e.target.value)}}></input>
                    <label style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Code postal</label>
                    <input type='text' className="input-text" placeholder='Code postal'  style={{ marginBottom: 30,  }} value={zipCode} onChange={(e) => {setZipCode(e.target.value)}}></input>
                    <label style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Numero de téléphone</label>
                    <input type='tel' className="input-text" placeholder='0606060606'  style={{ marginBottom: 30, border:`1px solid ${phoneRegex.test(phone) ? 'green' : 'red' }` }} value={phone} onChange={(e) => {setPhone(e.target.value)}}></input>
                    <label style={{ marginBottom: 20, textTransform:'uppercase', fontWeight:500 }}>Nom du pricipal de l'établissement</label>
                    <input type='text' className="input-text" placeholder='Nom...'  style={{ marginBottom: 30,  }} value={principale} onChange={(e) => {setPrincipale(e.target.value)}}></input>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', flexWrap:'wrap', margin:'0 0%', marginBottom: 0, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <div style={{ display:'flex', flexDirection:'row', marginTop:20 }}>
                        <button onClick={() => {if(phoneRegex.test(phone) && name && adresse){createSchools(name, principale, adresse, phone, zipCode)}}} className='button' style={{ marginTop:0, marginRight:30 }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                    </div>
                </div>
            </div>
        </>
    )
};

// == Export
export default EtablissementAjout;