// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappVisiteDemande2 from '../../screen/WebappVisiteDemande2';

// Action Creators
import { setSchoolInfos } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*setCurrentMenuItem: (item) => {
      console.log(item)
    dispatch(setCurrentMenuItem(item));
  },*/
  setSchoolInfos: (name, value) => {
    dispatch(setSchoolInfos(name, value))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappVisiteDemande2);