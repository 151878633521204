export const boEntries = [
    {
        title:'Médiathèque',
        link:'/admin/mediatheque/1',
        class:''
    },
    {
        title:'Visites',
        link:'/admin/visites',
        class:''
    },
    {
        title:'Quiz',
        link:'/admin/quiz/1',
        class:'',
    },
    {
        title:'Planning de réservation',
        link:'/admin/planning-reservation',
        class:''
    },
    {
        title:'Paramètres',
        link:'/admin/parametres',
        class:''
    },
    {
        title:'Déconnexion',
        link:'/deconnexion',
        class:''
    }
];