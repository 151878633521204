// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination'

import { boEntries } from '../../utils/headerEntries.js';
import moment from 'moment';

const testContent = [
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const Boite = ({ location, match, datas, waitingRequest, getAcceptedTool, deleteEntity, connected }) => {

    console.log('datas : ', datas);

    useEffect(() => {
        console.log(connected)
        if(connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')
        {
            console.log('getTools')
            getAcceptedTool(match.params.page);
        }
    }, []);
    console.log('datas id', datas['id'])

    const [showModalDelete, setShowModalDelete] = useState(false)
    const [toDelete, setToDelete] = useState(null);

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    } else if(datas['@id'] != undefined && datas['@id'] != '/api/tools') {
        getAcceptedTool(match.params.page);
   }

   const number_of_page = datas['hydra:view'] !== undefined && datas['hydra:view']['hydra:last'] !== undefined && datas['hydra:view'] !== [] ? datas['hydra:view']['hydra:last'].split('=')[datas['hydra:view']['hydra:last'].split('=').length-1] : null;

   if(match.params.page < 1 || (match.params.page > number_of_page && number_of_page !== null)) {
        return <Redirect to="/notFound" />
    }

   const customStyles = {
        content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'space-between',
        //height:307,
        width:375,
        padding: '30px 40px',
        color: '#0578BE',
        boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
        borderRadius:10
        }
    };

    return (
    <>
    <Header currentMenuItem={0} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Les contenus</h1>
            <div>
                <Link to='/admin/parametres/contenus/1' style={{backgroundColor:'#0578be', color:'white'}} className='button buton-link'>Gestion des contenus des écoles</Link>
                <Link to='/admin/mediatheque/ajout' style={{marginLeft:15}} className='button buton-link'>Ajouter un contenu</Link>
            </div>
        </div>

        <div className='table-wrapper'>
            <table>
                <thead>
                    <tr>
                        <th>Nom du contenu</th>
                        <th>catégorie</th>
                        <th>date d'ajout</th>
                        <th>type de fichier</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    {datas["@id"] === '/api/tools' ? datas["hydra:member"].map((element) => (
                        <tr>
                            <td>{element.title}</td>
                            <td>{element.category ? element.category.title : null}</td>
                            <td>{moment(element.addedAt).format('DD/MM/YYYY')}</td>
                            <td>{element.contentType.split('/').length > 1 ? element.contentType.split('/')[1] : element.contentType.split('/')[0]}</td>
                            <td>
                                <Link to={`/admin/mediatheque/edit/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto'}}>
                                    <p>Modifier</p>
                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                </Link>
                                <div onClick={() => {setShowModalDelete(true);setToDelete({id: element.id, title: element.title})}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, cursor:'pointer'}}>
                                    <p>Supprimer</p>
                                    <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                </div>
                            </td>
                        </tr>
                    )) : null}
                </tbody>
            </table>

            <div className='pagination'>
                {/*<Link to={`/admin/mediatheque/${match.params.page == 1 ? 1 : parseInt(match.params.page) - 1}`} className='prev-next'>{'<'}</Link>
                {pagination(number_of_page)}
                    <Link to={`/admin/mediatheque/${match.params.page == number_of_page ? number_of_page : parseInt(match.params.page) + 1}`} className='prev-next'>{'>'}</Link>*/}
                <Pagination datas={datas} url={'/admin/mediatheque/'} match={match} />
            </div>
        </div>
    </div>

    <Modal
    isOpen={showModalDelete}
    //onAfterOpen={afterOpenModal}
    onRequestClose={() => {
        setShowModalDelete(false);
    }}
    style={customStyles}
    contentLabel="Example Modal"
    >
        <div style={{ position:'absolute', top:15, right:15 }}>
            <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="1x" icon={faTimes} onClick={() => {setShowModalDelete(false)}} />
        </div>
        <div style={{position:'relative'}}>
            <div style={{ width:'100%', marginBottom:'28px' }}>
                <p style={{fontSize:'16px'}}>Etes-vous sur de vouloir supprimer ce contenu ?</p>
                <br />
                <p style={{fontSize:'16px'}}>Nom du contenu : {toDelete ? toDelete.title : null}</p>
                <br />
                <p style={{fontSize:'16px'}}>Il sera définitivement supprimé.</p>
            </div>
            <div style={{ marginRight:'auto', marginLeft:'auto' }}>
                <button className='button' style={{ marginRight:50 }} onClick={() => toDelete ? deleteEntity('tools', toDelete.id) : null}>Oui</button>
                <button className='button' onClick={() => {setShowModalDelete(false)}}>Non</button>
            </div>
        </div>
    </Modal>
    </>
    )
};

// == Export
export default Boite;