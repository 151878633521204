// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import AlveolesContentAjout from '../../screen/AlveolesContentAjout';

// Action Creators
import { createAlveoleContent, getQuiz } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  quizzs: state.quizzs,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createAlveoleContent: (name, body, status, qr, quiz, file, videoLink, alveole) => {
    dispatch(createAlveoleContent(name, body, status, qr, quiz, file, videoLink, alveole, ownProps.history));
  },
  getQuiz: (page, params) => {
    dispatch(getQuiz(page, params, true));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlveolesContentAjout);