import React from "react";
import Accordion from "./Accordion";

// import "../css/main.css";

import "./main.scss";

const questionsAnswers = [
  {
    question: "[INSCRIPTION] Qui peut s’inscrire sur l’application web MIA ?",
    answer:
      "L’application web MIA est pour le moment uniquement destinée aux enseignants des établissements du secondaire du département des Alpes-Maritimes. Ceux-ci doivent renseigner les coordonnées de leur établissement à l’inscription afin de pouvoir se rattacher à leur collège d’enseignement.",
  },
  {
    question: "[INSCRIPTION] Comment savoir si mon inscription a bien été prise en compte ?",
    answer:
      "Vous devriez recevoir un email vous indiquant que votre inscription a bien été prise en compte. Les administrateurs valideront votre inscription dans les plus brefs délais afin que vous puissiez avoir accès à toutes les ressources de la Médiathèque et au planning de réservation pour les visites à la MIA.",
  },
  {
    question: "[UTILISATEUR] J’ai perdu mon mot de passe, que faire ?",
    answer: `Cliquez sur «Mot de passe oublié» sur la page de connexion ou sur «Modifier le mot de passe» sur votre page de profil. Un lien de réinitialisation vous sera envoyé par e-mail.`,
  },
  {
    question: "[RESERVATION] Comment savoir si ma réservation a bien été prise en compte ?",
    answer: `Vous devriez recevoir un email vous indiquant que votre réservation a bien été prise en compte. Les administrateurs valideront votre réservation dans les plus brefs délais. N’oubliez pas de consulter les informations pratiques et la Médiathèque afin de préparer au mieux votre visite.`,
  },
  {
    question: "[RESERVATION] Sous quels délais ma réservation va-t-elle être validée ?",
    answer: `Chat and email support is available 24/7. Phone lines are open during normal business hours.`,
  },
  {
    question: "[RESERVATION] J’aimerais modifier ma réservation, est-ce possible ?",
    answer: `Si vous souhaitez modifier votre réservation, veuillez contacter les administrateurs de l’application MIA via contact@maison-intelligence-artificielle.com.`,
  },
  {
    question: "[BOITE A OUTILS] Qui propose les ressources de la Médiathèque ?",
    answer: `Les contenus de la Médiathèque sont essentiellement proposés par la MIA via une licence Creative Commons BY NC SA, c'est-à-dire attribution sans utilisation commerciale et partage dans les mêmes conditions. Nous autorisons l’exploitation des contenus à des fins non commerciales, ainsi que la création d’œuvres dérivées, à condition qu’elles soient distribuées sous la même licence et que la paternité nous soit accordée.
    Certains contenus peuvent être proposés par des professeurs qui auront alors eux-mêmes agréé aux conditions de la licence Creative Commons.`,
  },
  {
    question: "[BOITE A OUTILS] Puis-je proposer un contenu pour la Médiathèque ?",
    answer: `Vous pouvez tout à fait proposer un contenu pour la Médiathèque. Vous pouvez décider de ne le rendre visible qu’à vous seul, ou bien le partager avec l’ensemble des utilisateurs en acceptant les conditions de la licence Creative Commons BY NC SA.`,
  },
  {
    question: "[BOITE A OUTILS] Qui peut accéder à un contenu que j’ai déposé ?",
    answer: `Si vous avez opté pour le partage de votre contenu, l’ensemble des utilisateurs inscrits de l’application web MIA pourront y accéder selon les conditions de la licence Creative Commons BY NC SA. SI vous avez opté pour une utilisation privée, vous serez seuls à accéder aux contenus que vous avez déposé.`,
  }
];

const App = () => {
  return (
    <div className="container">
      <div className="component">
        <Accordion questionsAnswers={questionsAnswers} />
      </div>
    </div>
  );
};

export default App;