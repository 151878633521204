// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg'; // a remove
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactLoading from 'react-loading';
import QRCode from 'qrcode.react';
import { guid } from 'react-agenda';
import FileUpload from '../../components/FileUpload';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
    {
        name: 'test',
        statut: 'En ligne',
    },
]

// == Composant
const AlveolesContentModif = ({ location, history, match, waitingRequest, editAlveoleContent, getalveolesContent, datas, getQuiz, quizzs, connected }) => {

    const [name, setName] = useState(null);
    const [body, setBody] = useState(null);
    const [online, setOnline] = useState(null);
    const [file, setFile] = useState(null);
    const [oldFile, setOldFile] = useState('');
    const [stateInit, setStateInit] = useState(false);
    const [qr, setQr] = useState("");
    const [Imageurl, setImageurl] = useState('');
    const [quiz, setQuiz] = useState(null)

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')) {
            getalveolesContent(match.params.id);
            getQuiz(1, '')
        }
    }, []);
    console.log('quizzs', quizzs)

    if(!stateInit && datas['@id'] === `/api/alveole_contents/${match.params.id}`) {
        console.log(datas)
        setName(datas.title);
        setBody(datas.content);
        setOnline(datas.statusPublished ? datas.statusPublished : null);
        setQr(datas.qrCode ? datas.qrCode : "")
        setOldFile(datas.file ? datas.file : null)
        setQuiz(datas.quizz ? datas.quizz.split('/')[3] : null)
        setStateInit(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const onImageChange = (event) => {
        if(event.target.files.length !== 0) {
            const reader = new FileReader();

            reader.onload = (e) => {
            setImageurl({ imageSrc: e.target.result });
            console.log(e.target.result)
            }

            reader.readAsDataURL(event.target.files[0]);
        }
    }

    return (
        <>
        <Header currentMenuItem={1} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
            <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                <h1 className='page-title' style={{ marginBottom: 20 }}>modifier l'activité</h1>
                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                <label style={{ marginTop: 10, textTransform:'uppercase' }}>Titre de l'activité</label>
                <input type='text' className="input-text" style={{ border:'1px solid lightgray' }} placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value); setQr(`${e.target.value}/${guid()}`)}}></input>
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px'}}>
                <CKEditor
                    editor={ ClassicEditor }
                    data={body}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setBody(data);
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
            <div style={{textAlign:'left', paddingLeft:50}}>
                <label>Ajouter une image</label>
                <FileUpload accept={"image/png, image/jpeg"} inputText={"Parcourir..."} OnClickDo={(e) => {onImageChange(e); const fileURI = e.target.files[0]; const data = new FormData(); data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-')); setFile(data); console.log('onClickdo', data.get('file'))}} buttonClass={"input-button"} />
                <label>Ou ajouter une video (lien youtube)</label>
                <img src={Imageurl.imageSrc ? Imageurl.imageSrc : `https://mia.api.wacan-extranet.com/${oldFile}`} />
                <input type='text' className="input-text" style={{ border:'1px solid lightgray' }} placeholder='Nom...' value={ typeof(file) == "string" ? file : ''} onChange={(e) => {setFile(e.target.value)}}></input>
            </div>
            <div style={{textAlign:'left', margin:'30px 0', padding:50}}>
                <select name="quizz" id="quizz" className='button button-shadow' value={quiz} onChange={e => {setQuiz(e.target.value)}}>
                    <option value="">Choisir un quizz</option>
                    {quizzs['hydra:member'] ? quizzs['hydra:member'].map(element => (
                        <option selected={quiz === element.id} value={element.id}>{element.title}</option>
                    )) : <></>}
                </select>
            </div>
            <div className='grey-background' >
                <QRCode style={{ marginTop:20, marginBottom:20 }} value={datas.qrCode ? datas.qrCode : qr} />
            </div>
            <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                <div>
                    <input type="radio" id="En ligne" name="drone" value="En ligne" onClick={(e) => {if(e.target.checked){setOnline(e.target.id)}}} />
                    <label for="En ligne">En ligne</label>
                </div>

                <div>
                    <input type="radio" id="Hors ligne" name="drone" value="Hors ligne" onClick={(e) => {if(e.target.checked){setOnline(e.target.id)}}} />
                    <label for="Hors ligne">Hors ligne</label>
                </div>
                <button onClick={() => {editAlveoleContent(name, file, body, online, quiz, match.params.id, qr)}} style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
            </div>
        </div>
        </>
    )
};

// == Export
export default AlveolesContentModif;