// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import QuizQuestionAdd from '../../screen/QuizQuestionAdd';

// Action Creators
import { addQuestions } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addQuestions: (question, reponse1, reponse2, reponse3, reponse4, correct, rank, quizz) => {
    dispatch(addQuestions(question, reponse1, reponse2, reponse3, reponse4, correct, rank, quizz, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuizQuestionAdd);