// == Import : npm
import React, {useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination';
import useWindowDimensions from '../../utils/dimension';

import { boEntries } from '../../utils/headerEntries.js';
import { useEffect } from 'react';

// == Composant
const Etablissement = ({ location, history, match, waitingRequest, datas, getSchools, connected }) => {

    const [content, setContent] = useState([]);
    const [contentFilter, setContentFilter] = useState([]);
    const [filterInput, setFilterInput] = useState('')
    const [init, setInit] = useState(false);

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN') && location.pathname === `/admin/parametres/etablissements/${match.params.page}`)
        getSchools(match.params.page)
        setInit(false);
    }, [match.params.page])

    console.log(location.pathname)

    if(datas['@id'] === '/api/schools' && !init) {
        setContent(datas)
        setInit(true);
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const handleOnInputChange = (event) => {
        setFilterInput(event.target.value);
    }

      return (
          <>
            <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
                <div className='page-wrapper'>
                    <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 50px' }}>
                        <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                            <h1 className='page-title' style={{ textTransform:'uppercase', margin:0 }}>Gestion des établissements</h1>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <Link to={'/admin/parametres/etablissement/ajout'} className={"button"} style={{marginRight:20}}>Ajouter un établissement</Link>
                                <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                            </div>
                        </div>
                    </div>
                    <div style={{height:'fit-content', backgroundColor:'#0578BE', borderRadius:10, boxShadow:'0 4px 4px rgba(0,0,0,0.25)', display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', padding:'15px 20px', marginBottom:30}}>
                        <p style={{color:'white', marginRight:'10%', fontSize:25, fontWeight:400}}>Filtre</p>
                        <input
                            onChange={(e) => {
                                setFilterInput(e.target.value);
                                return new Promise((resolve, reject) => {
                                    fetch(`https://mia.api.wacan-extranet.com/api/schools?order[id]&name=${e.target.value}`,{
                                            method: 'GET',
                                            headers: new Headers({
                                                'Accept': 'application/ld+json',
                                                'Content-Type' : 'application/ld+json',
                                            })
                                        })
                                        .then(response => response.json())
                                        .then((response) => {
                                            setContentFilter(response)
                                        })
                                        .catch(reject);
                                });
                            }}
                            className='input-text'
                            type='search'
                            placeholder='Rechercher un établissement...'
                            style={{ width:323, marginTop:width<1311 ? 10 : 0 }}>
                        </input>
                    </div>
                    <div className='table-wrapper'>
                        <table style={{ tableLayout:'auto' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign:'left', paddingLeft:60 }}>Nom de l'établissement</th>
                                    <th style={{ textAlign:'left', paddingLeft:60 }}>Nom du principal</th>
                                    <th>Visites</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {datas["@id"] === '/api/schools' && filterInput.length === 0 ?
                                 datas["hydra:member"].map((element) => (
                                        <tr>
                                            <td style={{ textAlign:'left', paddingLeft:60 }}>{element.name}</td>
                                            <td style={{ textAlign:'left', paddingLeft:60 }}>{element.principalName}</td>
                                            <td>{element.visites}</td>
                                            <td style={{ fontStyle:'italic', display:'flex'}}>
                                                <Link to={`/admin/parametres/etablissements/voir/${element.id}`}>Voir</Link>
                                            </td>
                                        </tr>
                                    ))
                                 : null}
                                {filterInput.length !== 0 && contentFilter.length !== 0 && contentFilter["hydra:member"].length !== 0 ? contentFilter["hydra:member"].map((element) => (
                                    <tr>
                                        <td style={{ textAlign:'left', paddingLeft:60 }}>{element.name}</td>
                                        <td style={{ textAlign:'left', paddingLeft:60 }}>{element.principalName}</td>
                                        <td>{element.visites}</td>
                                        <td style={{ fontStyle:'italic', display:'flex'}}>
                                            <Link to={`/admin/parametres/etablissements/voir/${element.id}`}>Voir</Link>
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        {content && content.length !== 0 ? contentFilter['hydra:member'] && filterInput.length !== 0 ? (
                            <Pagination datas={contentFilter} url={'/admin/parametres/etablissements/'} match={match} />
                        ) : (
                            <Pagination datas={content} url={'/admin/parametres/etablissements/'} match={match} />
                        ) : null}
                    </div>
                </div>
            </>
      )
};

// == Export
export default Etablissement;