// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import VisitesAjout from '../../screen/VisitesAjout';

// Action Creators
import { createVisitType } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createVisitType: (title, desc, partners, mediator) => {
    dispatch(createVisitType(title, desc, partners, mediator, ownProps.history));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisitesAjout);