// == Import : npm
import React, { useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/mediatheque', breadcrumb: 'Médiathèque' },
];


// == Composant
const WebappBoite = ({ location, match }) => {
    const breadcrumbs = useBreadcrumbs(routes);

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', paddingBottom:171 }}>
        <div style={{textAlign:'left', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Médiathèque</h1>
        </div>
        <Link to='/mediatheque/explorer' style={{textDecoration:'none'}}>
            <div className='button-shadow icon-text-button' style={{ }}>
                <div style={{  }}>
                    <img src='/images/tech.svg' style={{ height:85, width:85 }}></img>
                    <div>
                        Explorer
                    </div>
                </div>
                <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0 }}></img>
            </div>
        </Link>
        <Link to={'/mediatheque/charger-contenu'} style={{textDecoration:'none'}}>
            <div className='button-shadow icon-text-button' style={{ marginTop:40 }}>
                <div style={{  }}>
                    <img src='/images/tech.svg' style={{ height:85, width:85 }}></img>
                    <div>
                        Charger un contenu
                    </div>
                </div>
                <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0 }}></img>
            </div>
        </Link>
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappBoite;