// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappArchive from '../../screen/WebappArchive';

// Action Creators
import { getalveoles, getScores, getAllAlveolesContents, getQuiz } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  scores:state.scores,
  alveolesContents: state.alveolesContents,
  quizDiscover: state.quizzs,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getalveoles: () => {
    dispatch(getalveoles());
  },
  getScores: (params) => {
    dispatch(getScores(params));
  },
  getAllAlveolesContents: () => {
    dispatch(getAllAlveolesContents());
  },
  getQuiz: (params) => {
    dispatch(getQuiz(1, params, true));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappArchive);