// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
      {
          id:1,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:2,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:3,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:4,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:5,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:6,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:7,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:8,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:9,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
      {
          id:10,
          selected: false,
          name: 'test',
          mail: 'utilisateur@gmail.com',
          role: 'utilisateur',
      },
  ]

// == Composant
const RolesEdit = ({ location, history, match, waitingRequest, getUser, datas, connected, acceptUser, refusedUser }) => {

    const [userId, setuserId] = useState(null);
    const [mail, setMail] = useState(null);
    const [schoolName, setSchoolName] = useState(null);
    const [teacher, setTeacher] = useState(null);
    const [schoolAddress, setSchoolAddress] = useState(null);
    const [schoolPhoneNumber, setSchoolPhoneNumber] = useState(null);
    const [status, setStatus] = useState(null);
    const [notif, setNotif] = useState(null);
    const [stateInit, setStateInit] = useState(false);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getUser(match.params.id)
    }, [])

    if(!stateInit && datas['@id'] === `/api/users/${match.params.id}`) {
        setuserId(datas.id);
        setStatus(datas.statusApproved)
        setMail(datas.email);
        setSchoolName(datas.school.name);
        setTeacher(datas.school.principalName);
        setSchoolAddress(datas.school.adress);
        setSchoolPhoneNumber(datas.school.phoneNumber);
        setStateInit(true);
    }

     if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

      return (
          <>
                  <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                  <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                        <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                            <h1 className='page-title' style={{ textTransform:'uppercase' }}>Gestion des utilisateurs</h1>
                            <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                        </div>
                  </div>
                  <div className='grey-background' style={{ marginBottom: 0 }}>
                    <p style={{ marginBottom: 10, textTransform:'uppercase', fontWeight:500 }}>Adresse email</p>
                    <p style={{ marginBottom: 30 }}>{mail}</p>
                    <p style={{ marginBottom: 10, textTransform:'uppercase', fontWeight:500 }}>Nom de l'établissement</p>
                    <p style={{ marginBottom: 30 }}>{schoolName}</p>
                    <p style={{ marginBottom: 10, textTransform:'uppercase', fontWeight:500 }}>Nom du professeur</p>
                    <p style={{ marginBottom: 30 }}>{teacher}</p>
                    <p style={{ marginBottom: 10, textTransform:'uppercase', fontWeight:500 }}>Adresse de l'établissement</p>
                    <p style={{ marginBottom: 30 }}>{schoolAddress}</p>
                    <p style={{ marginBottom: 10, textTransform:'uppercase', fontWeight:500 }}>Téléphone de l'établissement</p>
                    <p style={{ marginBottom: 30 }}>{schoolPhoneNumber}</p>
                  </div>
                  {status === 'pending' ? (
                    <div style={{ display:'flex', flexDirection: 'row', alignItems:'flex-start', margin:'0 0%', marginBottom: 150, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                        <button className='button' onClick={() => acceptUser(userId, '+accepted')} style={{ marginTop:40, marginRight: 10, cursor:'pointer' }}>
                            <img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>
                            Valider
                        </button>
                        <button className='button' onClick={() => refusedUser(userId, '+refused')} style={{ marginTop:40, marginLeft:10, cursor:'pointer' }}>
                            <img src='/images/cross-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>
                            Refuser
                        </button>
                    </div>
                  ) : null}
            </div>
            </>
      )
};

// == Export
export default RolesEdit;