// == Initial State
const initialState = {
    currentMenuItem: '',
    datas: [],
    quizzs:[],
    cat:[],
    visitTypes:[],
    pendingTools:[],
    acceptedTools:[],
    messageNotif:{
      good: false,
      message:'',
    },
    visitRequest: {
      classe: '',
      phone: '',
      studentNumber: '',
      email: '',
      teacherName: '',
      companionName: '',
      visit: '',
      year: ''
    },
    waitingRequest:0,
    connected: null
  };
  
  // == Types
  const SET_CUREENT_MENU_ITEM = 'SET_CUREENT_MENU_ITEM';
  const SET_MESSAGE_NOTIF = 'SET_MESSAGE_NOTIF';
  const ADD_WAITING_REQUEST = 'ADD_WAITING_REQUEST';
  const REMOVE_WAITING_REQUEST = 'REMOVE_WAITING_REQUEST';
  export const GET_QUIZ = 'GET_QUIZ';
  export const GET_A_QUIZ = 'GET_A_QUIZ';
  export const CREATE_QUESTIONS = 'CREATE_QUESTIONS';
  export const EDIT_QUESTIONS = 'EDIT_QUESTIONS';
  export const GET_A_QUESTION = 'GET_A_QUESTION';
  export const EDIT_A_QUIZZ = 'EDIT_A_QUIZZ';
  export const CREATE_QUIZ = 'CREATE_QUIZ';
  const SET_QUIZ = "SET_QUIZ";
  const SET_QUIZZ_QUESTION = "SET_QUIZZ_QUESTION";
  const SET_QUIZZ_ANSWER = "SET_QUIZZ_ANSWER";
  const SET_CAT = "SET_CAT";
  export const GET_CAT = 'GET_CAT';
  export const GET_A_CAT = 'GET_A_CAT';
  export const CREATE_CAT = 'CREATE_CAT';
  export const EDIT_CAT = 'EDIT_CAT';
  export const GET_ALVEOLES = 'GET_ALVEOLES';
  export const CREATE_ALVEOLE = 'CREATE_ALVEOLE';
  export const GET_ALVEOLES_CONTENTS = 'GET_ALVEOLES_CONTENTS';
  export const GET_ALVEOLES_CONTENT = 'GET_ALVEOLES_CONTENT';
  export const CREATE_ALVEOLE_CONTENT = 'CREATE_ALVEOLE_CONTENT';
  export const EDIT_ALVEOLE_CONTENT = 'EDIT_ALVEOLE_CONTENT';
  export const GET_TOOLS = 'GET_TOOLS';
  export const CREATE_TOOLS = 'CREATE_TOOLS';
  export const GET_TOOL = 'GET_TOOL';
  export const EDIT_TOOL = 'EDIT_TOOL';
  export const EDIT_ALVEOLE = 'EDIT_ALVEOLE';
  export const GET_VISITES = 'GET_VISITES';
  export const GET_VISITE = 'GET_VISITE';
  export const CREATE_VISITES = 'CREATE_VISITES';
  export const EDIT_VISITES = 'EDIT_VISITES';
  export const GET_VISITES_REQUEST = 'GET_VISITES_REQUEST';
  export const GET_USERS = 'GET_USERS';
  export const GET_USER = 'GET_USER';
  export const EDIT_USER = 'EDIT_USER';
  export const GET_VISIT_TYPES = 'GET_VISIT_TYPES';
  export const GET_VISIT_TYPE = 'GET_VISIT_TYPE';
  export const SET_VISIT_TYPE = 'SET_VISIT_TYPE';
  export const SET_STATE_VISIT_TYPE = 'SET_STATE_VISIT_TYPE';
  export const CREATE_VISIT_TYPE = 'CREATE_VISIT_TYPE';
  export const LOGIN = 'LOGIN';
  export const UPLOAD = 'UPLOAD';
  export const DELETE = 'DELETE';
  export const SET_CONNECTED = 'SET_CONNECTED';
  export const GET_SCHOOLS = 'GET_SCHOOLS';
  export const GET_SCHOOLS_ANONYMOUS = 'GET_SCHOOLS_ANONYMOUS'
  export const CREATE_SCHOOL = 'CREATE_SCHOOL';
  export const GET_SCHOOL = 'GET_SCHOOL';
  export const GET_TOOLS_PENDING = 'GET_TOOLS_PENDING';
  export const SET_PENDING_TOOLS = 'SET_PENDING_TOOLS';
  export const GET_TOOLS_ACCEPTED = 'GET_TOOLS_ACCEPTED';
  export const SET_ACCEPTED_TOOLS = 'SET_ACCEPTED_TOOLS';
  export const EDIT_SCHOOL = 'EDIT_SCHOOL';
  export const EDIT_WEBCONTENT = 'EDIT_WEBCONTENT';
  export const GET_WEBCONTENT = 'GET_WEBCONTENT';
  export const SET_WEBCONTENT = 'SET_WEBCONTENT';
  export const ME = 'ME';
  export const CREATE_VISITES_REQUEST = 'CREATE_VISITES_REQUEST';
  export const SET_AS_QUIZZS = 'SET_AS_QUIZZS';
  export const GET_SCORE = 'GET_SCORE';
  export const SET_SCORE = 'SET_SCORE';
  export const SET_ALVEOLES_CONTENTS_A_PART = 'SET_ALVEOLES_CONTENTS_A_PART';
  export const GET_ALL_ALVEOLES_CONTENTS = 'GET_ALL_ALVEOLES_CONTENTS';
  export const GET_MY_VISITES_REQUESTS = 'GET_MY_VISITES_REQUESTS';
  export const SET_MY_VISITES_REQUESTS = 'SET_MY_VISITES_REQUESTS';
  export const GET_A_VISITES_REQUEST = 'GET_A_VISITES_REQUEST';
  export const ACCEPT_VISITES_REQUEST = 'ACCEPT_VISITES_REQUEST';
  export const DELETE_EVENT = 'DELETE_EVENT';
  export const CANCEL_VISITES_REQUEST = 'CANCEL_VISITES_REQUEST';
  export const CANCEL_SCHOOL_VISIT_REQUEST = 'CANCEL_SCHOOL_VISIT_REQUEST';
  export const REFUSED_VISIT_REQUEST = 'REFUSED_VISIT_REQUEST';
  export const CREATE_USER = 'CREATE_USER';
  export const GET_QUIZ_FILE = 'GET_QUIZ_FILE';
  export const EDIT_INFO_USER = 'EDIT_INFO_USER';
  export const ACCEPT_USER = 'ACCEPT_USER';
  export const REFUSED_USER = 'REFUSED_USER';
  export const GET_QUIZ_BY_STATUS = 'GET_QUIZ_BY_STATUS';
  export const ACCEPT_TOOL = 'ACCEPT_TOOL';
  export const REFUSED_TOOL = 'REFUSED_TOOL';
  export const FORGET_PASSWORD = 'FORGET_PASSWORD';
  export const ADD_USER = "ADD_USER";
  export const CREATE_EVENT_HEBDO = "CREATE_EVENT_HEBDO";
  export const CREATE_EVENT_DAILY = "CREATE_EVENT_DAILY";
  const UPDATE_DATAS = 'UPDATE_DATAS';
  const UPDATE_MY_VISIT_REQUEST = 'UPDATE_MY_VISIT_REQUEST';
  const SET_SCHOOL_INFOS = 'SET_SCHOOL_INFOS';
  const RESET_VISIT_REQUEST_INFOS = 'RESET_VISIT_REQUEST_INFOS';
  const SET_QUIZZ_FILE = 'SET_QUIZZ_FILE';
  const SET_QUIZZ_BY_STATUS = 'SET_QUIZZ_BY_STATUS';
  export const CREATE_VISITES_REQUEST_ADMIN = "CREATE_VISITES_REQUEST_ADMIN";
  export const SET_SCHOOLS = "SET_SCHOOLS";
  export const SET_USERS = "SET_USERS";
  
  
  // == Reducer
  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_CUREENT_MENU_ITEM:
        return {
          ...state,
          currentMenuItem: action.item,
        };
        break;
      case SET_MESSAGE_NOTIF:
        return {
          ...state,
          messageNotif: {message: action.message, good: action.good},
        };
        break;
      case ADD_WAITING_REQUEST:
        return {
          ...state,
          waitingRequest: state.waitingRequest + 1,
        };
        break;
      case REMOVE_WAITING_REQUEST:
        return {
          ...state,
          waitingRequest: state.waitingRequest - 1,
        };
        break;
      case SET_QUIZ:
        return {
          ...state,
          datas: {
            ...action.datas,
            quizzQuestionFile: action.question,
            quizzAnswerFile: action.answer
          }
        };
        break;
      case SET_QUIZZ_QUESTION:
        return {
          ...state,
          datas: {
            ...state.datas,
            quizzQuestionFile: action.datas
          }
        }
      case SET_QUIZZ_ANSWER:
        return {
          ...state,
          datas: {
            ...state.datas,
            quizzAnswerFile: action.datas
          }
        }
      case SET_CAT:
        return {
          ...state,
          cat: action.cat,
        };
        break;
      case SET_CONNECTED:
        return {
          ...state,
          connected: action.connected,
        };
        break;
      case SET_STATE_VISIT_TYPE:
        return {
          ...state,
          visitTypes: action.visitTypes,
        };
        break;
      case SET_PENDING_TOOLS:
        return {
          ...state,
          pendingTools: action.datas,
        };
        break;
      case SET_ACCEPTED_TOOLS:
        return {
          ...state,
          acceptedTools: action.datas,
        };
        break;
      case SET_WEBCONTENT:
        return {
          ...state,
          [action.datas.name]: action.datas,
        };
        break;
      case SET_AS_QUIZZS:
        return {
          ...state,
          quizzs: action.datas,
        };
        break;
      case SET_SCORE:
        return {
          ...state,
          scores: action.datas,
        };
        break;
      case SET_ALVEOLES_CONTENTS_A_PART:
        return {
          ...state,
          alveolesContents: action.datas,
        };
        break;
      case SET_MY_VISITES_REQUESTS:
        return {
          ...state,
          MyVisitsRequests: action.datas,
        };
        break;
      case UPDATE_DATAS:
        return {
          ...state,
          datas: action.datas
        }
      case UPDATE_MY_VISIT_REQUEST:
        const index = state.datas['hydra:member'].findIndex(data => data.id === action.datas.id); //finding index of the item
        const newArray = [...state.datas['hydra:member']]; //making a new array
        newArray[index].statusAccepted = action.datas.statusAccepted;
        return {
          ...state,
          datas: {
            ...state.datas,
            ['hydra:member']: newArray 
          }
        }
      case SET_SCHOOL_INFOS:
        return {
          ...state,
          visitRequest: {
            ...state.visitRequest,
            [action.name]: action.value
          }
        }
        break
      case RESET_VISIT_REQUEST_INFOS:
        return {
          ...state,
          visitRequest: {
            classe: '',
            phone: '',
            studentNumber: '',
            email: '',
            teacherName: '',
            visit: '',
            year: ''
          }
        }
      case SET_QUIZZ_FILE:
        console.log(action)
        return {
          ...state,
          quizzFile: {
            quizzQuestion: action.question,
            quizzAnswer: action.answer
          }
        }
      case SET_QUIZZ_BY_STATUS:
        return {
          ...state,
          quizzStatus: action.datas
        }
      case SET_SCHOOLS:
        return {
          ...state,
          schools: action.datas
        }
      case SET_USERS:
        return {
          ...state,
          users: action.users
        }
      default:
        return state;
    }
  };
  
  // == Action Creators
  export const setSchools = datas => ({
    type: SET_SCHOOLS,
    datas,
  });
  export const setCurrentMenuItem = item => ({
    type: SET_CUREENT_MENU_ITEM,
    item,
  });
  export const setMessageNotif = (message, good) => ({
    type: SET_MESSAGE_NOTIF,
    message,
    good,
  });
  export const addWaitingRequest = () => ({
    type: ADD_WAITING_REQUEST,
  });
  export const removeWaitingRequest = () => ({
    type: REMOVE_WAITING_REQUEST,
  });
  export const getQuiz = (page, params, asQuizzs) => ({
    type: GET_QUIZ,
    page,
    params,
    asQuizzs,
  });
  export const getQuizFile = () => ({
    type: GET_QUIZ_FILE,
  })
  export const getAQuiz = (id) => ({
    type: GET_A_QUIZ,
    id,
  });
  export const getAQuestion = (id) => ({
    type: GET_A_QUESTION,
    id,
  });
  export const addQuestions = (question, reponse1, reponse2, reponse3, reponse4, correct, rank, quizz, history) => ({
    type: CREATE_QUESTIONS,
    question,
    reponse1,
    reponse2,
    reponse3,
    reponse4,
    correct,
    rank,
    quizz,
    history
  });
  export const editQuestions = (id, question, reponse1, reponse2, reponse3, reponse4, correct, rank, quizz, history) => ({
    type: EDIT_QUESTIONS,
    id,
    question,
    reponse1,
    reponse2,
    reponse3,
    reponse4,
    correct,
    rank,
    quizz,
    history
  });
  export const editQuizz = (id, name, statut, questionFile, answerFile, fileQuestionId, fileAnswerId) => ({
    type: EDIT_A_QUIZZ,
    id,
    name,
    statut,
    questionFile,
    answerFile,
    fileQuestionId,
    fileAnswerId
  });
  export const createQuizz = ( name, statut, questionFile, answerFile, history) => ({
    type: CREATE_QUIZ,
    name,
    statut,
    questionFile,
    answerFile,
    history
  });
  export const setDatas = (datas, question, answer) => ({
    type: SET_QUIZ,
    datas,
    question,
    answer
  });
  export const setQuizzQuestion = (datas) => ({
    type: SET_QUIZZ_QUESTION,
    datas
  })
  export const setQuizzAnswer = (datas) => ({
    type: SET_QUIZZ_ANSWER,
    datas
  })
  export const setCat = (cat) => ({
    type: SET_CAT,
    cat,
  });
  export const getCat = () => ({
    type: GET_CAT,
  });
  export const getACat = (id) => ({
    type: GET_A_CAT,
    id,
  });
  export const createCat = (title, image, history) => ({
    type: CREATE_CAT,
    title,
    image,
    history
  });
  export const editCat = (id, title, image, history) => ({
    type: EDIT_CAT,
    id,
    title,
    image,
    history
  });
  export const getalveoles = () => ({
    type: GET_ALVEOLES,
  });
  export const getalveolesContents = (id) => ({
    type: GET_ALVEOLES_CONTENTS,
    id,
  });
  export const getAllAlveolesContents = () => ({
    type: GET_ALL_ALVEOLES_CONTENTS,
  });
  export const getalveolesContent = (id) => ({
    type: GET_ALVEOLES_CONTENT,
    id
  });
  export const createAlveoleContent = (name, body, status, qr, quiz, file, videoLink, alveole, history) => ({
    type: CREATE_ALVEOLE_CONTENT,
    name,
    body,
    status,
    quiz,
    file,
    videoLink,
    qr,
    alveole,
    history
  });
  export const editAlveoleContent = (name, file, body, status, quiz, id, qr, history) => ({
    type: EDIT_ALVEOLE_CONTENT,
    name,
    file,
    body,
    status,
    quiz,
    id,
    qr,
    history
  });
  export const getTools = (page, params) => ({
    type: GET_TOOLS,
    page,
    params,
  });
  export const getTool = (id) => ({
    type: GET_TOOL,
    id,
  });
  export const getToolsPending = (page) => ({
    type: GET_TOOLS_PENDING,
    page,
  });
  export const getAcceptedTool = (page) => ({
    type: GET_TOOLS_ACCEPTED,
    page,
  });
  export const setAcceptedTool = (datas) => ({
    type: SET_ACCEPTED_TOOLS,
    datas,
  });
  export const createTools = (name, cat, file, fileType, videoLink, status, history, uploaderName, uploaderLastName, uploaderEmail, schoolOnly, docTitle, docDescription, docLevel) => ({
    type: CREATE_TOOLS,
    name,
    cat,
    file,
    fileType,
    videoLink,
    status,
    history,
    uploaderName,
    uploaderLastName,
    uploaderEmail,
    schoolOnly,
    docTitle,
    docDescription,
    docLevel,
  });
  export const setPendingTool = (datas) => ({
    type: SET_PENDING_TOOLS,
    datas
  });
  export const editTool = (id, name, cat, file, fileType, videoLink, status, history) => ({
    type: EDIT_TOOL,
    id,
    name,
    cat,
    file,
    fileType,
    videoLink,
    status,
    history,
  });
  export const editAvleole = (id, title, description, icon, history) => ({
    type: EDIT_ALVEOLE,
    id,
    title,
    description,
    icon,
    history
  });
  export const createAvleole = () => ({
    type: CREATE_ALVEOLE,
  });
  export const getVisites = (page, params) => ({
    type: GET_VISITES,
    page,
    params
  });
  export const getVisite = (id) => ({
    type: GET_VISITE,
    id,
  });
  export const createVisites = (name, start, end, visitType, refreshDatas, history) => ({
    type: CREATE_VISITES,
    name,
    start,
    end,
    visitType,
    refreshDatas,
    history
  });
  export const editVisites = (name, id, start, end, visitType, refreshDatas, history) => ({
    type: EDIT_VISITES,
    name,
    id,
    start,
    end,
    visitType,
    refreshDatas,
    history
  });
  export const getVisitesRequest = (page) => ({
    type: GET_VISITES_REQUEST,
    page
  });
  export const setUsers = (users) => ({
    type: SET_USERS,
    users
  });
  export const getUsers = (page) => ({
    type: GET_USERS,
    page
  });
  export const getUser = (id) => ({
    type: GET_USER,
    id,
  });
  export const editUser = (id, mail, password, history) => ({
    type: EDIT_USER,
    id,
    mail,
    password,
    history
  });
  export const getVisitTypes = (page) => ({
    type: GET_VISIT_TYPES,
    page
  });
  export const getVisitType = (id) => ({
    type: GET_VISIT_TYPE,
    id,
  });
  export const setVisitType = (id, title, desc, partners, mediator, history) => ({
    type: SET_VISIT_TYPE,
    id,
    title,
    desc,
    partners,
    mediator,
    history
  });
  export const setStateVisitType = (visitTypes) => ({
    type: SET_STATE_VISIT_TYPE,
    visitTypes
  });
  export const createVisitType = ( title, desc, partners, mediator, history) => ({
    type: CREATE_VISIT_TYPE,
    title,
    desc,
    partners,
    mediator,
    history
  });
  export const login = ( mail, password, history) => ({
    type: LOGIN,
    mail, 
    password,
    history
  });
  export const upload = ( data ) => ({
    type: UPLOAD,
    data,
  });
  export const deleteEntity = ( entityType, id ) => ({
    type: DELETE,
    entityType,
    id,
  });
  export const setConnected = ( connected ) => ({
    type: SET_CONNECTED,
    connected,
  });
  export const getSchools = (page) => ({
    type: GET_SCHOOLS,
    page
  });
  export const getSchoolsAnonymous = () => ({
    type: GET_SCHOOLS_ANONYMOUS
  })
  export const getSchool = (id) => ({
    type: GET_SCHOOL,
    id,
  });
  export const createSchools = (name, principale, adresse, phone, zipCode, history ) => ({
    type: CREATE_SCHOOL,
    name,
    principale,
    adresse,
    phone,
    zipCode,
    history
  });
  export const editSchools = (name, principale, adresse, phone, zipCode, history, id) => ({
    type: EDIT_SCHOOL,
    name,
    principale,
    adresse,
    phone,
    zipCode,
    history,
    id
  });
  export const editWebContent = ( id, content ) => ({
    type: EDIT_WEBCONTENT,
    id,
    content,
  });
  export const setWebContent = ( datas ) => ({
    type: SET_WEBCONTENT,
    datas
  });
  export const getWebContent = ( id ) => ({
    type: GET_WEBCONTENT,
    id
  });
  export const me = (  ) => ({
    type: ME,
  });
  export const editInfoUser = (userId, mdp) => ({
    type: EDIT_INFO_USER,
    userId,
    mdp
  })
  export const createVisitRequest = (visit, history) => ({
    type: CREATE_VISITES_REQUEST,
    visit,
    history
  });
  export const setQuizzs = ( datas ) => ({
    type: SET_AS_QUIZZS,
    datas
  });
  export const getScores = ( params ) => ({
    type: GET_SCORE,
    params
  });
  export const setScores = ( datas ) => ({
    type: SET_SCORE,
    datas
  });
  export const setAlveolesContents = ( datas ) => ({
    type: SET_ALVEOLES_CONTENTS_A_PART,
    datas
  });
  export const getMyVisitsRequests = ( ) => ({
    type: GET_MY_VISITES_REQUESTS,
  });
  export const setMyVisitRequests = ( datas) => ({
    type: SET_MY_VISITES_REQUESTS,
    datas,
  });
  export const getAVistiRequest = ( id) => ({
    type: GET_A_VISITES_REQUEST,
    id,
  });
  export const acceptVistiRequest = ( id) => ({
    type: ACCEPT_VISITES_REQUEST,
    id,
  });
  export const deleteEvent = (id) => ({
    type: DELETE_EVENT,
    id,
  })
  export const cancelVisitRequest = (id) => ({
    type: CANCEL_VISITES_REQUEST,
    id,
  })
  export const cancelSchoolVisitRequest = (id) => ({
    type: CANCEL_SCHOOL_VISIT_REQUEST,
    id,
  })
  export const updateDatas = (datas) => ({
    type: UPDATE_DATAS,
    datas,
  })
  export const updateMyVisitRequests = (datas) => ({
    type: UPDATE_MY_VISIT_REQUEST,
    datas,
  })
  export const refusedVistiRequest = (id) => ({
    type: REFUSED_VISIT_REQUEST,
    id,
  })
  export const createUser = (input, selectedSchool) => ({
    type: CREATE_USER,
    input,
    selectedSchool
  })
  export const setSchoolInfos = (name, value) => ({
    type: SET_SCHOOL_INFOS,
    name,
    value
  })
  export const resetVisitRequestInfos = () => ({
    type: RESET_VISIT_REQUEST_INFOS,
  })
  export const setQuizzFile = (question, answer) => ({
    type: SET_QUIZZ_FILE,
    question,
    answer
  })
  export const acceptUser = (userId, accept) => ({
    type: ACCEPT_USER,
    userId,
    accept
  })
  export const refusedUser = (userId, refused) => ({
    type: REFUSED_USER,
    userId,
    refused
  })
  export const getQuizByStatus = () => ({
    type: GET_QUIZ_BY_STATUS,
  })
  export const setQuizzByStatus = (datas) => ({
    type: SET_QUIZZ_BY_STATUS,
    datas
  })
  export const acceptTool = (id, status, category) => ({
    type: ACCEPT_TOOL,
    id,
    status,
    category
  })
  export const refusedTool = (id, status, category) => ({
    type: REFUSED_TOOL,
    id,
    status,
    category
  })
  export const forgetPassword = (email, history) => ({
    type: FORGET_PASSWORD,
    email,
    history
  })
  export const addUser = (email, mdp, etablissement, classroom, lastName, firstName, notif) => ({
    type: ADD_USER,
    email,
    mdp,
    etablissement,
    classroom,
    lastName,
    firstName,
    notif
  })
  export const createEventHebdo = (object, history) => ({
    type: CREATE_EVENT_HEBDO,
    object,
    history
  })
  export const createEventDaily = (object, history) => ({
    type: CREATE_EVENT_DAILY,
    object,
    history
  })
  export const createVisiteRequestAdmin = (object, history) => ({
    type: CREATE_VISITES_REQUEST_ADMIN,
    object,
    history
  })
  
  
  // == Selectors
  
  
  // == Export
  export default reducer;