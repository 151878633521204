import axios from 'axios';
import { useHistory } from 'react-router-dom';
import httpsAgent from 'https-agent';
import { hashHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import moment from 'moment';




import { 
  GET_QUIZ,
  GET_A_QUIZ,
  GET_QUIZ_BY_STATUS,
  CREATE_QUESTIONS,
  EDIT_QUESTIONS,
  GET_A_QUESTION,
  EDIT_A_QUIZZ,
  CREATE_QUIZ,
  addWaitingRequest,
  removeWaitingRequest,
  setDatas,
  setCat,
  GET_CAT,
  GET_A_CAT,
  CREATE_CAT,
  EDIT_CAT,
  GET_ALVEOLES,
  CREATE_ALVEOLE,
  GET_ALVEOLES_CONTENTS,
  GET_ALVEOLES_CONTENT,
  CREATE_ALVEOLE_CONTENT,
  EDIT_ALVEOLE_CONTENT,
  GET_TOOLS,
  CREATE_TOOLS,
  GET_TOOL,
  EDIT_TOOL,
  EDIT_ALVEOLE,
  GET_VISITES,
  CREATE_VISITES,
  GET_VISITES_REQUEST,
  EDIT_VISITES,
  GET_USERS,
  GET_USER,
  EDIT_USER,
  GET_VISIT_TYPES,
  GET_VISIT_TYPE,
  SET_VISIT_TYPE,
  CREATE_VISIT_TYPE,
  GET_VISITE,
  LOGIN,
  UPLOAD,
  DELETE,
  setMessageNotif,
  setConnected,
  GET_SCHOOLS,
  GET_SCHOOLS_ANONYMOUS,
  CREATE_SCHOOL,
  setStateVisitType,
  getVisites,
  GET_SCHOOL,
  GET_TOOLS_PENDING,
  setPendingTool,
  GET_TOOLS_ACCEPTED,
  setAcceptedTool,
  EDIT_SCHOOL,
  EDIT_WEBCONTENT,
  GET_WEBCONTENT,
  setWebContent,
  ME,
  CREATE_VISITES_REQUEST,
  setQuizzs,
  GET_SCORE,
  setScores,
  setAlveolesContents,
  GET_ALL_ALVEOLES_CONTENTS,
  GET_MY_VISITES_REQUESTS,
  setMyVisitRequests,
  GET_A_VISITES_REQUEST,
  ACCEPT_VISITES_REQUEST,
  CANCEL_VISITES_REQUEST,
  CANCEL_SCHOOL_VISIT_REQUEST,
  DELETE_EVENT,
  REFUSED_VISIT_REQUEST,
  CREATE_USER,
  GET_QUIZ_FILE,
  EDIT_INFO_USER,
  ACCEPT_USER,
  REFUSED_USER,
  ACCEPT_TOOL,
  REFUSED_TOOL,
  FORGET_PASSWORD,
  ADD_USER,
  CREATE_EVENT_HEBDO,
  CREATE_EVENT_DAILY,
  updateDatas,
  updateMyVisitRequests,
  resetVisitRequestInfos,
  setQuizzFile,
  setQuizzByStatus,
  CREATE_VISITES_REQUEST_ADMIN,
  setSchools,
  setUsers
} from './reducer';

//const [cookies, setCookie, removeCookie] = useCookies(['connected']);

const baseUri = "https://mia.api.wacan-extranet.com/api";
// const baseUri = "http://127.0.0.1:8000/api";

const redirect = redirectUrl => {
  window.location = redirectUrl;
};

const logMiddleware = store => next => (action) => {
  console.log('Je suis le middleware, et je laisse passer cette action: ', action);
  next(action);

  const instance = axios.create({
    /*httpsAgent: httpsAgent({
      rejectUnauthorized: false,
    }),*/
    //withCredentials:true,
  });

  instance.interceptors.response.use((resp) => {
    if (!`${resp.status}`.startsWith('2')) {
      redirect('/');
      store.dispatch(setConnected(false));
      localStorage.removeItem("alreadyLaunched");
      throw resp.data;
    }
  
    return resp;
  }, (error) => {
    if (error && error.response && error.response.data) {
      if (error.response.status === 401) {
        redirect('/');
        store.dispatch(setConnected(false));
        localStorage.removeItem("alreadyLaunched");
        setMessageNotif('Votre session a expiré.', false)
        throw error.response.data; 
      }
    }
    throw error;
  })

  switch (action.type) {
    case GET_QUIZ:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/quizzs?page=${action.page}${action.params}`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        if(action.asQuizzs) {
          store.dispatch(setQuizzs(response.data))
        }else {
          store.dispatch(setDatas(response.data));
        }
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case GET_A_QUIZ:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/quizzs/${action.id}`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        let datas = response.data
        //store.dispatch(setDatas(response.data));

        axios.all([
          axios.get(`${baseUri}/tools?contentType=quizz/question`, {
            headers: {
              'Accept': 'application/ld+json',
              'Content-Type' : 'application/json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          }), 
          axios.get(`${baseUri}/tools?contentType=quizz/answer`, {
            headers: {
              'Accept': 'application/ld+json',
              'Content-Type' : 'application/json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          })
        ])
        .then(axios.spread((resp1, resp2) => {
          // Both requests are now complete
          store.dispatch(setDatas(datas, resp1.data["hydra:member"], resp2.data["hydra:member"]));
          store.dispatch(removeWaitingRequest());
        }));
      })
      .catch()
      break;
    case GET_QUIZ_BY_STATUS:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/quizzs?status=discover`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        store.dispatch(setQuizzByStatus(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case GET_A_QUESTION:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/questions/${action.id}`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case CREATE_QUESTIONS:
      console.log(action)
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/questions`, {
        question:action.question,
        reponse1:action.reponse1,
        reponse2:action.reponse2,
        reponse3:action.reponse3,
        response4:action.reponse4,
        correct:action.correct,
        rank:action.rank,
        quizz: `/api/quizzs/${action.quizz}`
      }, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(setMessageNotif('Votre question a bien été créée.', true))
        store.dispatch(removeWaitingRequest());
        action.history.goBack()
        //return redirect(`/admin/quiz/questions/${action.quizz}`);
      })
      .catch(error => {
        store.dispatch(setMessageNotif('Une erreur est survenue lors de la création de la question.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case EDIT_QUESTIONS:
      console.log(action)
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/questions/${action.id}`, 
      {
        question:action.question,
        reponse1:action.reponse1 ? action.reponse1 : undefined,
        reponse2:action.reponse2 ? action.reponse2 : undefined,
        reponse3:action.reponse3 ? action.reponse3 : undefined,
        response4:action.reponse4 ? action.reponse4 : undefined,
        correct:action.correct,
        rank:action.rank,
        quizz: action.quizz
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
        store.dispatch(removeWaitingRequest());
        action.history.goBack()
        //return redirect(`/admin/quiz/questions/${action.quizz}`);
      })
      .catch(error => {
        store.dispatch(setMessageNotif('Une erreur est survenue lors de l\'enregistrement de vos modifications.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case EDIT_A_QUIZZ:
      console.log(action)
      store.dispatch(addWaitingRequest());
      if (action.questionFile !== undefined && action.answerFile !== undefined && action.fileQuestionId === undefined && action.fileAnswerId === undefined) {
        axios.all([
          axios.post(`${baseUri}/media_objects`, action.questionFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          }), 
          axios.post(`${baseUri}/media_objects`, action.answerFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          })
        ])
        .then(axios.spread((obj1, obj2) => {
          // Both requests are now complete
          const question = obj1.data.contentUrl;
          const answer = obj2.data.contentUrl;

          axios.all([
            axios.post(`${baseUri}/tools`, {
              contentType: 'quizz/question',
              fileUrl: question,
              uploaderName: "admin",
              uploaderLastname: "admin",
              statusAccepted: 'special',
              title: 'question quizz test de connaissance'
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              },
            }), 
            axios.post(`${baseUri}/tools`, {
              contentType: 'quizz/answer',
              fileUrl: answer,
              uploaderName: "admin",
              uploaderLastname: "admin",
              statusAccepted: 'special',
              title: 'reponse quizz test de connaissance'
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              },
            })
          ])
          .then(axios.spread((resp1, resp2) => {
            // Both requests are now complete
            instance.patch(`${baseUri}/quizzs/${action.id}`, {
              title: action.name,
              status: action.statut
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/merge-patch+json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              }
            })
            .then(response => {
              store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
              store.dispatch(removeWaitingRequest());
              document.location.reload(true)
            })
            .catch( error => {
              store.dispatch(setMessageNotif('Une erreur est survenue.', false))
              store.dispatch(removeWaitingRequest());
            })
          }));
        }));
      } else if (action.questionFile !== undefined && action.answerFile !== undefined && action.fileQuestionId !== undefined && action.fileAnswerId !== undefined) {
        axios.all([
          axios.post(`${baseUri}/media_objects`, action.questionFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          }), 
          axios.post(`${baseUri}/media_objects`, action.answerFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          })
        ])
        .then(axios.spread((obj1, obj2) => {
          // Both requests are now complete
          const question = obj1.data.contentUrl;
          const answer = obj2.data.contentUrl;

          axios.all([
            axios.patch(`${baseUri}/tools/${action.fileQuestionId}`, {
              contentType: 'quizz/question',
              fileUrl: question,
              uploaderName: "admin",
              uploaderLastname: "admin",
              statusAccepted: 'special',
              title: 'question quizz test de connaissance'
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/merge-patch+json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              },
            }), 
            axios.patch(`${baseUri}/tools/${action.fileAnswerId}`, {
              contentType: 'quizz/answer',
              fileUrl: answer,
              uploaderName: "admin",
              uploaderLastname: "admin",
              statusAccepted: 'special',
              title: 'reponse quizz test de connaissance'
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/merge-patch+json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              },
            })
          ])
          .then(axios.spread((resp1, resp2) => {
            // Both requests are now complete
            instance.patch(`${baseUri}/quizzs/${action.id}`, {
              title: action.name,
              status: action.statut
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/merge-patch+json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              }
            })
            .then(response => {
              store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
              store.dispatch(removeWaitingRequest());
              document.location.reload(true)
            })
            .catch( error => {
              store.dispatch(setMessageNotif('Une erreur est survenue.', false))
              store.dispatch(removeWaitingRequest());
            })
          }));
        }));
      } else {
        instance.patch(`${baseUri}/quizzs/${action.id}`,
        {
          title: action.name,
          status: action.statut
        },
        {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/merge-patch+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          }
        })
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
          store.dispatch(removeWaitingRequest());
        })
        .catch(error => {
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      }
      break;
    case CREATE_QUIZ:
      store.dispatch(addWaitingRequest());
      if (action.questionFile && action.answerFile) {

        console.log(action)
        axios.all([
          axios.post(`${baseUri}/media_objects`, action.questionFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          }), 
          axios.post(`${baseUri}/media_objects`, action.answerFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          })
        ])
        .then(axios.spread((obj1, obj2) => {
          // Both requests are now complete
          const question = obj1.data.contentUrl;
          const answer = obj2.data.contentUrl;

          axios.all([
            axios.post(`${baseUri}/tools`, {
              contentType: 'quizz/question',
              fileUrl: question,
              uploaderName: "admin",
              uploaderLastname: "admin",
              statusAccepted: 'special',
              title: 'question quizz de test'
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              },
            }), 
            axios.post(`${baseUri}/tools`, {
              contentType: 'quizz/answer',
              fileUrl: answer,
              uploaderName: "admin",
              uploaderLastname: "admin",
              statusAccepted: 'special',
              title: 'reponse quizz de test'
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              },
            })
          ])
          .then(axios.spread((resp1, resp2) => {
            // Both requests are now complete
            console.log(resp1)
            console.log(resp2)
            instance.post(`${baseUri}/quizzs`, {
              title:action.name,
              status:action.statut
            }, {
              headers: {
                'Accept': 'application/ld+json',
                'Content-Type' : 'application/json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              }
            })
            .then(response => {
              //const { data } = response.
              //store.dispatch(setDatas(response.data));
              console.log(response.data);
              store.dispatch(setMessageNotif('Votre quiz a bien été créé.', true))
              store.dispatch(removeWaitingRequest());
              action.history.push(`/admin/quiz/questions/${response.data.id}`)
              //return redirect(`/admin/quiz/questions/${response.data.id}`);
            })
            .catch( error => {
              store.dispatch(setMessageNotif('Une erreur est survenue.', false))
              store.dispatch(removeWaitingRequest());
            })
          }));
        }));
      } else {
        instance.post(`${baseUri}/quizzs`, {
          title:action.name,
          status:action.statut
        }, {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          }
        })
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          store.dispatch(setMessageNotif('Votre quiz a bien été créé.', true))
          store.dispatch(removeWaitingRequest());
          action.history.push(`/admin/quiz/questions/${response.data.id}`)
          //return redirect(`/admin/quiz/questions/${response.data.id}`);
        })
        .catch( error => {
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      }
      break;
    case GET_CAT:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/categories`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setCat(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch( error => {
        store.dispatch(setMessageNotif('Une erreur est survenue.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case GET_A_CAT:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/categories/${action.id}`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case CREATE_CAT:
      store.dispatch(addWaitingRequest());
      console.log(action)
      instance.post(`${baseUri}/media_objects`, action.image, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then((resp) => {
        instance.post(`${baseUri}/categories`, {
          title: action.title,
          image: resp.data.contentUrl,
        },
          {
            headers: {
              'Accept': 'application/ld+json',
              'Content-Type' : 'application/json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            }
          }
        )
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          store.dispatch(setMessageNotif('Votre catégorie a bien été créé.', true))
          store.dispatch(removeWaitingRequest());
          action.history.goBack()
          //redirect('/admin/parametres/categories');
        })
        .catch((error) => {
          console.log(error)
          store.dispatch(setMessageNotif('Une erreur est survenue.', true))
          store.dispatch(removeWaitingRequest());
        })
      })
      .catch((err) => console.log(err.message));
      break;
    case EDIT_CAT:
      console.log('image', action.image)
      store.dispatch(addWaitingRequest());
      if (action.image) {
        instance.post(`${baseUri}/media_objects`, action.image, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        })
        .then((resp) => {
          instance.patch(`${baseUri}/categories/${action.id}`,
          {
            title:action.title,
            image:resp.data.contentUrl,
          },
          {
            headers: {
              'Accept': 'application/ld+json',
              'Content-Type': 'application/merge-patch+json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          })
          .then(response => {
            //const { data } = response.
            //store.dispatch(setDatas(response.data));
            console.log(response.data);
            store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
            store.dispatch(removeWaitingRequest());
            action.history.goBack()
            //redirect('/admin/parametres/categories');
          })
          .catch(error => {
            store.dispatch(setMessageNotif('Une erreur est survenue.', false))
            store.dispatch(removeWaitingRequest());
          }) 
        }).catch(error => {
          console.log(error)
        })
      } else {
        instance.patch(`${baseUri}/categories/${action.id}`,
        {
          title:action.title,
        },
        {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type': 'application/merge-patch+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        })
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
          store.dispatch(removeWaitingRequest());
          action.history.goBack()
          //redirect('/admin/parametres/categories');
        })
        .catch(error => {
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      }
      break;
    case GET_ALVEOLES:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method:'GET',
          url:`${baseUri}/alveoles?page=1`,
          headers: {
            'Accept': 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
          //withCredentials:true,
        }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case GET_ALVEOLES_CONTENTS:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/alveoles/${action.id}`, {
        headers: {
          'Accept': 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        if(action.alveoleContentsAPart) {
          store.dispatch(setAlveolesContents(response.data));
        } else {
          store.dispatch(setDatas(response.data));
        }
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case GET_ALL_ALVEOLES_CONTENTS:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/alveole_contents/?pagination=false`, {
        headers: {
          'Accept': 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setAlveolesContents(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case EDIT_ALVEOLE:
        store.dispatch(addWaitingRequest());
        console.log(action.icon)
        if(action.icon) {
          instance.post(`${baseUri}/media_objects`, action.icon, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            },
          })
          .then((resp) => {
            instance.patch(`${baseUri}/alveoles/${action.id}`, {
              title: action.title,
              description: action.description,
              icon: resp.data.contentUrl,
            },
              {
                headers: {
                  'Accept': 'application/ld+json',
                  'Content-Type' : 'application/merge-patch+json',
                  'X-AUTH-TOKEN' : store.getState().connected.apiToken,
                }
              }
            )
            .then(response => {
              //const { data } = response.
              store.dispatch(setDatas(response.data));
              store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
              store.dispatch(removeWaitingRequest());
            })
            .catch((error) => {
              console.log(error)
              store.dispatch(setMessageNotif('Une erreur est survenue.', true))
              store.dispatch(removeWaitingRequest());
            })
          })
          .catch((err) => console.log(err.message));
        } else {
          instance.patch(`${baseUri}/alveoles/${action.id}`, {
            title: action.title,
            description: action.description
          },
            {
              headers: {
                'Accept': 'application/ld+json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
                'Content-Type' : 'application/merge-patch+json',
              }
            }
          )
          .then(response => {
            //const { data } = response.
            store.dispatch(setDatas(response.data));
            store.dispatch(setMessageNotif('Vos modifications ont bien été enregistrées.', true))
            store.dispatch(removeWaitingRequest());
          })
          .catch((error) => {
            console.log(error)
            store.dispatch(setMessageNotif('Une erreur est survenue.', true))
            store.dispatch(removeWaitingRequest());
          })
        }
        break;
    case CREATE_ALVEOLE:
        store.dispatch(addWaitingRequest());
        instance.post(`${baseUri}/alveoles`, {
          title: "Nouvelle activité"
        },
        {
          headers: {
            'Accept': 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            'Content-Type' : 'application/ld+json',
          },
        })
        .then((resp) => {
          action.history.push(`/admin/visites/${resp.data['@id'].split('/')[resp.data['@id'].split('/').length - 1]}`)
          //redirect(`/admin/alveoles/${resp.data['@id'].split('/')[resp.data['@id'].split('/').length - 1]}`)
          store.dispatch(setMessageNotif('Activité créé avec succès.', true))
          store.dispatch(removeWaitingRequest());
        })
        .catch((err) => {
          console.log(err.message)
          store.dispatch(setMessageNotif('un erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        });
        break;
    case CREATE_ALVEOLE_CONTENT:
      store.dispatch(addWaitingRequest());
      console.log(action)
      if(action.file) {
        instance.post(`${baseUri}/media_objects`, action.file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        })
        .then((resp) => {
        instance.post(`${baseUri}/alveole_contents`, {
          title: action.name,
          content: action.body,
          statusPublished: action.status,
          qrCode: action.qr,
          alveole: `/api/alveoles/${action.alveole}`,
          file:resp.data.contentUrl,
          quizz:`/api/quizzs/${action.quiz}`
        }, {
          headers: {
          'Accept': 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/json',
          }
        })
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          store.dispatch(setMessageNotif('Contenu créé avec succès.', true))
          store.dispatch(removeWaitingRequest());
          action.history.push(`/admin/visites/${action.alveole}`)
          //return redirect(`/admin/alveoles/${action.alveole}`);
        })
        .catch((error) => {
          console.log(error)
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      })
      .catch((err) => console.log(err.message));
      } else {
        instance.post(`${baseUri}/alveole_contents`, {
          title: action.name,
          content: action.body,
          statusPublished: action.status,
          qrCode: action.qr,
          alveole: `/api/alveoles/${action.alveole}`,
          quizz:`/api/quizzs/${action.quiz}`,
          file:action.videoLink
        }, {
          headers: {
            'Accept': 'application/json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            'Content-Type' : 'application/json',
          }
        })
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          store.dispatch(setMessageNotif('Contenu créé avec succès.', true))
          store.dispatch(removeWaitingRequest());
          action.history.push(`/admin/visites/${action.alveole}`)
          //return redirect(`/admin/alveoles/${action.alveole}`);
        })
        .catch((error) => {
          console.log(error)
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      }
      break;
    case EDIT_ALVEOLE_CONTENT:
      store.dispatch(addWaitingRequest());
      if(action.file) {
        instance.post(`${baseUri}/media_objects`, action.file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        })
        .then((resp) => {
          instance.patch(`${baseUri}/alveole_contents/${action.id}`, {
            title: action.name,
            file:resp.data.contentUrl,
            content: action.body,
            qrCode: action.qr,
            statusPublished: action.status,
            quizz:`/api/quizzs/${action.quiz}`
          },
          {
            headers: {
              'Accept': 'application/json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              'Content-Type' : 'application/merge-patch+json',
            },
          }
          )
          .then(response => {
            //const { data } = response.
            //store.dispatch(setDatas(response.data));
            console.log(response.data);
            const datas = response.data;
            store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
            store.dispatch(removeWaitingRequest());
            //action.history.push(`/admin/alveoles/${datas.alveole.split('/')[datas.alveole.split('/').length-1]}`)
            //return redirect(`/admin/alveoles/${datas.alveole.split('/')[datas.alveole.split('/').length-1]}`);
          })
          .catch((error) => {
            console.log(error)
            store.dispatch(setMessageNotif('Une erreur est survenue.', false))
            store.dispatch(removeWaitingRequest());
          })
        }).catch((err) => console.log(err.message));
      } else {
        instance.patch(`${baseUri}/alveole_contents/${action.id}`, {
          title: action.name,
          content: action.body,
          qrCode: action.qr,
          statusPublished: action.status,
          quizz:`/api/quizzs/${action.quiz}`
        },
        {
          headers: {
            'Accept': 'application/json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            'Content-Type' : 'application/merge-patch+json',
          },
        }
        )
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          console.log(response.data);
          const datas = response.data;
          store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
          store.dispatch(removeWaitingRequest());
          //action.history.push(`/admin/alveoles/${datas.alveole.split('/')[datas.alveole.split('/').length-1]}`)
          //return redirect(`/admin/alveoles/${datas.alveole.split('/')[datas.alveole.split('/').length-1]}`);
        })
        .catch((error) => {
          console.log(error)
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      }
      break;
    case GET_ALVEOLES_CONTENT:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/alveole_contents/${action.id}`, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(removeWaitingRequest());
      })
      .catch()
      break;
    case GET_TOOLS:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/tools${action.page ? '?page='+action.page : '?pagination=false'}&${action.params!=undefined ? action.params : ''}&order[addedAt]`, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_TOOL:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/tools/${action.id}`, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_TOOLS_PENDING:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/tools?statusAccepted=pending&pagination=false&order[addedAt]`, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setPendingTool(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_TOOLS_ACCEPTED:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/tools?statusAccepted=accepted&order[addedAt]&page=${action.page}`, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setDatas(response.data));
        store.dispatch(setAcceptedTool(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case CREATE_TOOLS:
      if (action.file) {
        store.dispatch(addWaitingRequest());
        instance.post(`${baseUri}/media_objects`, action.file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        })
        .then((response) => {
          console.log('status', action.status)
          instance.post(`${baseUri}/tools`, 
          {
            contentType: action.fileType,
            fileUrl:  response.data.contentUrl,
            title: action.name ? action.name : action.docTitle,
            uploaderName: action.uploaderName,
            uploaderLastname: action.uploaderLastName,
            uploaderEmail: action.uploaderEmail,
            uploaderClass: null,
            schoolOnly: action.schoolOnly,
            category: action.cat ? `/api/categories/${action.cat ? action.cat : ""}` : undefined,
            statusAccepted: action.status,
            description: action.docDescription,
            level: action.docLevel,
            videoLink: null
          },
          {
            headers: {
              'Accept': 'application/json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              'Content-Type' : 'application/json',
            },
          }
          )
          .then(response => {
            //const { data } = response.
            //store.dispatch(setDatas(response.data));
            store.dispatch(setMessageNotif('Contenu créé avec succès.', true))
            store.dispatch(removeWaitingRequest());
            action.history.goBack()
            //return redirect(`/admin/mediatheque/1`);
          })
          .catch((error) => {
            console.log(error)
            store.dispatch(setMessageNotif('Une erreur est survenue.', false))
            store.dispatch(removeWaitingRequest());
          })
        })
        .catch((err) => console.log(err.message)); 
      } else {
        store.dispatch(addWaitingRequest());
        instance.post(`${baseUri}/tools`, 
          {
            contentType: 'vidéo',
            fileUrl:  action.videoLink,
            title: action.name ? action.name : action.docTitle,
            uploaderName: action.uploaderName,
            uploaderLastname: action.uploaderLastName,
            uploaderEmail: action.uploaderEmail,
            uploaderClass: null,
            schoolOnly: action.schoolOnly,
            category: action.cat ? `/api/categories/${action.cat ? action.cat : ""}` : undefined,
            statusAccepted: action.status,
            description: action.docDescription,
            level: action.docLevel,
            videoLink: action.videoLink ? action.videoLink : null
          },
          {
            headers: {
              'Accept': 'application/json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              'Content-Type' : 'application/json',
            },
          }
          )
          .then(response => {
            store.dispatch(setMessageNotif('Contenu créé avec succès.', true))
            store.dispatch(removeWaitingRequest());
            //action.history.goBack()
          })
          .catch((error) => {
            console.log(error)
            store.dispatch(setMessageNotif('Une erreur est survenue lors de la crétion du contenue.', false))
            store.dispatch(removeWaitingRequest());
          })
      }
      break;
    case GET_TOOL:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/tools/${action.id}`, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case EDIT_TOOL:
      store.dispatch(addWaitingRequest());
      //const data = action.file;
      if (action.file) {
        instance.post(`${baseUri}/media_objects`, action.file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        }).then((response) => {
          instance.patch(`${baseUri}/tools/${action.id}`, {
            title: action.name,
            category: action.cat ? '/api/categories/'+action.cat : undefined,
            fileUrl: response.data ? response.data.contentUrl : undefined,//action.file
            contentType: action.fileType,
            statusAccepted: action.status,
          },
            {
              headers: {
                'Accept': 'application/ld+json',
                'X-AUTH-TOKEN' : store.getState().connected.apiToken,
                'Content-Type' : 'application/merge-patch+json',
              }
            }
          )
          .then(response => {
            //const { data } = response.
            //store.dispatch(setDatas(response.data));
            store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
            store.dispatch(removeWaitingRequest());
            //redirect(`/admin/mediatheque/1`);
            action.history.goBack()
            //return redirect(`/admin/mediatheque/1`);
          })
          .catch((error) => {
            console.log(error)
            store.dispatch(setMessageNotif('Une erreur est survenue.', false))
            store.dispatch(removeWaitingRequest());
          })
        }).catch((err) => console.log(err.message)) 
      } else {
        console.log(action)
        instance.patch(`${baseUri}/tools/${action.id}`, {
          title: action.name,
          category: action.cat ? '/api/categories/'+action.cat : null,
          statusAccepted: action.status ? action.status : null,
          fileUrl: action.videoLink,
          contentType: 'vidéo'
        },
          {
            headers: {
              'Accept': 'application/ld+json',
              'X-AUTH-TOKEN' : store.getState().connected.apiToken,
              'Content-Type' : 'application/merge-patch+json',
            }
          }
        )
        .then(response => {
          //const { data } = response.
          //store.dispatch(setDatas(response.data));
          store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
          store.dispatch(removeWaitingRequest());
          //redirect(`/admin/mediatheque/1`);
          action.history.goBack()
          //return redirect(`/admin/mediatheque/1`);
        })
        .catch((error) => {
          console.log(error)
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        })
      }
      break;
    case GET_VISITES:
      store.dispatch(addWaitingRequest());
      console.log('page', action.page)
      instance.get(`${baseUri}/visits${action.page?'?page='+action.page:'?pagination=false'}${action.params}`, 
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_VISITE:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/visits/${action.id}`, 
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case CREATE_VISITES:
      store.dispatch(addWaitingRequest());
      console.log(action)
      axios({ 
        method: 'post',
        url: `${baseUri}/visits`,
        headers: {
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Accept': 'application/ld+json',
        },
        data: {
          title: action.name,
          start: moment(action.start).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(action.end).format('YYYY-MM-DD HH:mm:ss'),
          visitType: action.visitType ? `/api/visit_types/${action.visitType}` : undefined,
        }
      })
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu créé avec succès.', true))
        store.dispatch(getVisites());
        store.dispatch(removeWaitingRequest());
        if(action.refreshDatas) {
          action.history.push('/admin/planning-reservation')
          //redirect('/admin/planning-reservation')
        }
        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case EDIT_VISITES:
      store.dispatch(addWaitingRequest());
      console.log(action)
      instance.patch(`${baseUri}/visits/${action.id}`,
        {
          title: action.name,
          start: action.start,
          end: action.end,
          visitType: action.visitType ? `/api/visit_types/${action.visitType}` : undefined,
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/merge-patch+json',
        },
      }
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
        console.log('refresh', action.refreshDatas)
        if(action.refreshDatas) {
          action.history.push('/admin/planning-reservation')
          //redirect('/admin/planning-reservation')
        }
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case DELETE_EVENT:
      store.dispatch(addWaitingRequest());
      instance.delete(`${baseUri}/visits/${action.id}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/ld+json',
          },
        }
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu supprimé avec succès.', true))
        store.dispatch(removeWaitingRequest());
        document.location.reload(true)
        //return redirect(Window.location);
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 500) {
          store.dispatch(setMessageNotif('Une réservation existe sur ce créneau', false))
          store.dispatch(removeWaitingRequest());
        } else {
          store.dispatch(setMessageNotif('Une erreur est survenue.', false))
          store.dispatch(removeWaitingRequest());
        }
      })
      break
    case GET_VISITES_REQUEST:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/visit_requests${action.page ? `?page=${action.page}` : '?pagination=false'}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_A_VISITES_REQUEST:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/visit_requests/${action.id}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_MY_VISITES_REQUESTS:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/visit_requests?page=1&school=${store.getState().connected.school}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setMyVisitRequests(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case CREATE_VISITES_REQUEST:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/visit_requests`,
        {
          year: store.getState().visitRequest.year,
          studentNumber: store.getState().visitRequest.studentNumber ? parseInt(store.getState().visitRequest.studentNumber) : 0,
          teacherName: store.getState().visitRequest.teacherName,
          companionName: store.getState().visitRequest.companionName,
          teacherEmail: store.getState().visitRequest.email,
          phoneNumber: store.getState().visitRequest.phone,
          visit: action.visit,
          classe: store.getState().visitRequest.classe,
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        action.history.push('/accueil')
        store.dispatch(resetVisitRequestInfos())
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case CREATE_VISITES_REQUEST_ADMIN:
      store.dispatch(addWaitingRequest());
      console.log(action.object)
      instance.post(`${baseUri}/visit_requests`,
        {
          school: action.object.etName,
          year: action.object.annee,
          studentNumber: action.object.nbEleve ? parseInt(action.object.nbEleve) : 0,
          teacherName: action.object.teacherName,
          companionName: action.object.companionName,
          teacherEmail: action.object.teacherEmail,
          phoneNumber: action.object.phoneNumber,
          visit: action.object.selected,
          classe: action.object.classeName,
          creator: action.object.user,
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        // action.history.push('/accueil')
        store.dispatch(resetVisitRequestInfos())
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue', false))
        store.dispatch(getVisites());
        store.dispatch(removeWaitingRequest());
      })
      break;
    case ACCEPT_VISITES_REQUEST:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/visit_requests/${action.id}`,
        {
          statusAccepted: '+accepted',
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(updateDatas(response.data));
        store.dispatch(setMessageNotif('Réservation acceptée', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case REFUSED_VISIT_REQUEST:
      store.dispatch(addWaitingRequest());
      console.log(action.id)
      instance.patch(`${baseUri}/visit_requests/${action.id}`,
        {
          statusAccepted: '+refused',
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        //console.log(response.data)
        store.dispatch(updateDatas(response.data));
        store.dispatch(setMessageNotif('La réservation a bien été refusée.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case CANCEL_VISITES_REQUEST:
      store.dispatch(addWaitingRequest());
      console.log(action.id)
      instance.patch(`${baseUri}/visit_requests/${action.id}`,
        {
          statusAccepted: '+canceled',
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        //console.log(response.data)
        store.dispatch(updateDatas(response.data));
        store.dispatch(setMessageNotif('réservation annulée', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case CANCEL_SCHOOL_VISIT_REQUEST:
      store.dispatch(addWaitingRequest());
      console.log(action.id)
      instance.patch(`${baseUri}/visit_requests/cancel/${action.id}`,
        {
          statusAccepted: '+canceled',
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        //console.log(response)
        store.dispatch(updateMyVisitRequests(response.data));
        store.dispatch(setMessageNotif('Réservation annulée', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case GET_USERS:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/users?page=${action.page}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(setUsers(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_USER:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/users/${action.id}`,
        {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
          //withCredentials:true,
        }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case EDIT_USER:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/users/${action.id}`,
        {
          email:action.mail,
          password: action.password
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/ld+json',
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu modifié avec succès.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case GET_VISIT_TYPES:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/visit_types${action.page?'?page'+action.page:''}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setStateVisitType(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_VISIT_TYPE:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/visit_types/${action.id}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case SET_VISIT_TYPE:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/visit_types/${action.id}`,
        {
          title:action.title,
          description:action.desc,
          partners: "",//action.partners,
          mediator: action.mediator,
        },
        {
          headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/merge-patch+json',
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
        action.history.goBack()
        //return redirect(`/admin/parametres/type-visite/1`);
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case CREATE_VISIT_TYPE:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/visit_types`, {
        title:action.title,
        description:action.desc,
        partners: "",//action.partners,
        mediator: action.mediator,
      }, {
        headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/ld+json',
        }
      }
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu créé avec succès.', true))
        store.dispatch(removeWaitingRequest());
        action.history.goBack()
        //return redirect(`/admin/parametres/type-visite/1`);
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case GET_SCHOOLS:
      store.dispatch(addWaitingRequest());
      axios({ 
        method: 'get',
        url: `${baseUri}/schools?order[id]&page=${action.page}`,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(setSchools(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_SCHOOLS_ANONYMOUS:
      store.dispatch(addWaitingRequest());
      axios({
        method: 'get',
        url: `${baseUri}/schools`,
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
        }
      })
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_SCHOOL:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/schools/${action.id}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      }
      )
      .then(response => {
        //const { data } = response.
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case CREATE_SCHOOL:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/schools`,
        {
          name: action.name,
          principalName: action.principale,
          adress: action.adresse,
          phoneNumber: action.phone,
          zipCode: action.zipCode
        }, {
          headers: {
            'Accept': 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            'Content-Type' : 'application/ld+json',
          }
        }
      )
      .then(response => {
        store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('L\'établissement a bien été créé.', true))
        store.dispatch(removeWaitingRequest());
      }).catch((error) => {
        store.dispatch(setMessageNotif('Une erreur s\'est produite lors de la création de L\'établissement.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case EDIT_SCHOOL:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/schools/${action.id}`,
        {
          name: action.name,
          principalName: action.principale,
          adress: action.adresse,
          phoneNumber: action.phone,
          zipCode: action.zipCode
        }, {
          headers: {
            'Accept': 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
            'Content-Type' : 'application/merge-patch+json',
          }
        }
      )
      .then(response => {
        store.dispatch(setDatas(response.data));
        action.history.goBack();
        store.dispatch(setMessageNotif('L\'établissement à bien été modifié.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        store.dispatch(setMessageNotif('Une erreur s\'est produite lors de la modification de l\'établissement', true))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case EDIT_WEBCONTENT:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/web_contents/${action.id}`,
      {
        content: action.content,
      },
      {
        headers: {
        'Accept': 'application/ld+json',
        'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        'Content-Type' : 'application/merge-patch+json',
        },
      },
      )
      .then(response => {
        redirect('/informations-pratiques');
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_WEBCONTENT:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/web_contents/${action.id}`,
      {
        headers: {
        'Accept': 'application/ld+json',
        'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      },
      )
      .then(response => {
        store.dispatch(setWebContent(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case GET_SCORE:
      store.dispatch(addWaitingRequest());
      instance.get(`${baseUri}/score_quizzs/${action.params}`,
      {
        headers: {
        'Accept': 'application/ld+json',
        'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      },
      )
      .then(response => {
        store.dispatch(setScores(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error)
      })
      break;
    case CREATE_USER:
      store.dispatch(addWaitingRequest());
      console.log(action.input)
      instance.post(`${baseUri}/users`,
      {
        email: action.input.email.value,
        password: 'test123456',
        school: `/api/schools/${action.selectedSchool}`,
        notify: false
      }, {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/ld+json',
        }
      }).then(response => {
        store.dispatch(setMessageNotif('Votre compte a bien été créé et est en attente de validation.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response.data['hydra:description'] === "email: This email is unavailable.") {
          store.dispatch(setMessageNotif('cette adresse email est dèjà enregistré. Veuillez vous connectez.', false))
          store.dispatch(removeWaitingRequest());
        }
      })
      break
    case DELETE:
      store.dispatch(addWaitingRequest());
      instance.delete(`${baseUri}/${action.entityType}/${action.id}`,
        {
          headers: {
          'Accept': 'application/ld+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          'Content-Type' : 'application/ld+json',
        },
      }
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        store.dispatch(setMessageNotif('Contenu supprimé avec succès.', true))
        store.dispatch(removeWaitingRequest());
        document.location.reload(true)
        //return redirect(Window.location);
      })
      .catch((error) => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case LOGIN:
      store.dispatch(addWaitingRequest());
      instance(
        {
          method: 'POST',
          url:`https://mia.api.wacan-extranet.com/login`,
          headers: {
            //'Accept': 'application/ld+json',
            'Content-Type' : 'application/json',
          },
          //withCredentials:true,
          data: {
            email:action.mail,
            password:action.password,
          }
        },
      )
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        console.log('LOGIN =>', response.data)
        store.dispatch(setConnected(response.data))
        //setCookie('connected', response.data, {path: '/'});
        store.dispatch(removeWaitingRequest());
        if(response.data.roles.find(element => element === "ROLE_ADMIN")) {
          //action.history.push('/admin/mediatheque/1')
          redirect('/admin/mediatheque/1')
        } else if(response.data.roles.find(element => element === "ROLE_USER")) {
          //action.history.push('/admin/mediatheque/1')
          redirect('/accueil')
        }
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response.status === 401 && error.response.data.error === 'Invalid credentials.') {
          store.dispatch(setMessageNotif('Vos identifiants sont incorrect.', false))
          store.dispatch(removeWaitingRequest());
        }
      })
      break;
    case UPLOAD:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/media_objects`, action.data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err.message));
      break;
    case ME:
      store.dispatch(addWaitingRequest());
      instance.get(`https://mia.api.wacan-extranet.com/me`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then((response) => {
        store.dispatch(setDatas(response.data));
        store.dispatch(removeWaitingRequest());
      })
      .catch((err) => console.log(err.message));
      break;
    case EDIT_INFO_USER:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/users/${action.userId}`,
      {
        password: action.mdp,
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        //const { data } = response.
        //store.dispatch(setDatas(response.data));
        console.log(response.data);
        store.dispatch(setMessageNotif('Vos informations ont bien été enregistrées.', true))
        store.dispatch(removeWaitingRequest());
        action.history.goBack()
        //return redirect(`/admin/quiz/questions/${action.quizz}`);
      })
      .catch(error => {
        store.dispatch(setMessageNotif('Une erreur est survenue lors de l\'enregistrement de vos modifications.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case GET_QUIZ_FILE:
      store.dispatch(addWaitingRequest());
      axios.all([
        axios.get(`${baseUri}/tools?contentType=quizz/question`, {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        }), 
        axios.get(`${baseUri}/tools?contentType=quizz/answer`, {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          },
        })
      ])
      .then(axios.spread((resp1, resp2) => {
        // Both requests are now complete
        store.dispatch(setQuizzFile(resp1.data["hydra:member"], resp2.data["hydra:member"]));
        store.dispatch(removeWaitingRequest());
      })).catch();
      break;
    case ACCEPT_USER:
      store.dispatch(addWaitingRequest());
      console.log(action.accepted)
      instance.patch(`${baseUri}/users/${action.userId}`,
      {
        statusApproved: '+accepted'
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setMessageNotif('L\'utilisateur a bien été accepté.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch(error => {
        console.log(error)
        store.dispatch(setMessageNotif('Une erreur est survenue lors de l\'acceptation de l\'utilisateur.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case REFUSED_USER:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/users/${action.userId}`,
      {
        statusApproved: '+refused'
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setMessageNotif('L\'utilisateur a bien été refusé.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch(error => {
        store.dispatch(setMessageNotif('Une erreur est survenue lors du refus de l\'utilisateur.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case ACCEPT_TOOL:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/tools/${action.id}`,
      {
        statusAccepted: '+accepted',
        category: action.category ? `/api/categories/${action.category}` : undefined,
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setMessageNotif('Le contenu a bien été accepté.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch(error => {
        store.dispatch(setMessageNotif('Une erreur est survenue lors de l\'acceptation du contenu.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case REFUSED_TOOL:
      store.dispatch(addWaitingRequest());
      instance.patch(`${baseUri}/tools/${action.id}`,
      {
        statusAccepted: '+refused'
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/merge-patch+json',
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        }
      })
      .then(response => {
        console.log(response.data);
        store.dispatch(setMessageNotif('Le contenu a bien été refusé.', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch(error => {
        store.dispatch(setMessageNotif('Une erreur est survenue lors du refus du contenu.', false))
        store.dispatch(removeWaitingRequest());
      })
      break;
    case FORGET_PASSWORD:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/forgotten-password/`,
      {
        email: action.email
      },
      {
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type' : 'application/json'
        }
      })
      .then(response => {
        action.history.goBack()
        store.dispatch(setMessageNotif('Un email vous à été envoyé pour réinitialiser votre mot de passe', true))
        store.dispatch(removeWaitingRequest());
      })
      .catch(error => {
        if (error.response.status === 400) {
          store.dispatch(setMessageNotif(`${error.response.data.error}`, false))
        }
        store.dispatch(removeWaitingRequest());
      })
      break;
    case ADD_USER:
      store.dispatch(addWaitingRequest());
      if (action.etablissement === null) {
        instance.post(`${baseUri}/users`,
        {
          email: action.email,
          password: action.mdp,
          roles: ['ROLE_ADMIN'],
          statusApproved: '+accepted',
          notify: action.notif
        }, {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          }
        }).then(response => {
          store.dispatch(setMessageNotif('L\'utilisateur a bien été créé.', true))
          store.dispatch(removeWaitingRequest());
        })
        .catch((error) => {
          if (error.response.status === 400 && error.response.data['hydra:description'] === "email: This email is unavailable.") {
            store.dispatch(setMessageNotif('cette adresse email est dèjà enregistré. Veuillez utiliser une autre adresse email.', false))
            store.dispatch(removeWaitingRequest());
          }
        }) 
      } else {
        instance.post(`${baseUri}/users`,
        {
          email: action.email,
          password: action.mdp,
          school: `/api/schools/${action.etablissement}`,
          statusApproved: '+accepted',
          notify: action.notif
        }, {
          headers: {
            'Accept': 'application/ld+json',
            'Content-Type' : 'application/ld+json',
            'X-AUTH-TOKEN' : store.getState().connected.apiToken,
          }
        }).then(response => {
          store.dispatch(setMessageNotif('L\'utilisateur a bien été créé.', true))
          store.dispatch(removeWaitingRequest());
        })
        .catch((error) => {
          if (error.response.status === 400 && error.response.data['hydra:description'] === "email: This email is unavailable.") {
            store.dispatch(setMessageNotif('cette adresse email est dèjà enregistré. Veuillez utiliser une autre adresse email.', false))
            store.dispatch(removeWaitingRequest());
          }
        })
      }
      break;
    case CREATE_EVENT_HEBDO:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/recurring-visit/weekly`, action.object, {
        headers: {
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then((resp) => {
        console.log(resp)
        store.dispatch(removeWaitingRequest());
        document.location.reload()
      })
      .catch((err) => console.log(err.message));
      break;
    case CREATE_EVENT_DAILY:
      store.dispatch(addWaitingRequest());
      instance.post(`${baseUri}/recurring-visit/daily`, action.object, {
        headers: {
          'X-AUTH-TOKEN' : store.getState().connected.apiToken,
        },
      })
      .then((resp) => {
        console.log(resp)
        store.dispatch(removeWaitingRequest());
        document.location.reload()
      })
      .catch((err) => console.log(err.message));
      break;
    default:
      next(action);
  }
};

export default logMiddleware;