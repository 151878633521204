// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import QuizQuestionEdit from '../../screen/QuizQuestionEdit';

// Action Creators
import { editQuestions, getAQuestion } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editQuestions: (id, question, reponse1, reponse2, reponse3, reponse4, correct, rank, quizz) => {
    dispatch(editQuestions(id, question, reponse1, reponse2, reponse3, reponse4, correct, rank, quizz, ownProps.history));
  },
  getAQuestion: (id) => {
    dispatch(getAQuestion(id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuizQuestionEdit);