// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import NouveauMembre from '../../screen/NouveauMembre';

// Action Creators
import { createUser, getSchoolsAnonymous } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  /*setCurrentMenuItem: (item) => {
      console.log(item)
    dispatch(setCurrentMenuItem(item));
  },*/
  createUser: (input, selectedSchool) => {
    dispatch(createUser(input, selectedSchool));
  },
  getSchoolsAnonymous: () => {
    dispatch(getSchoolsAnonymous())
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NouveauMembre);