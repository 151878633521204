// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import RolesEdit from '../../screen/RolesEdit';

// Action Creators
import { getUser, editUser, acceptUser, refusedUser } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUser: (id) => {
    dispatch(getUser(id));
  },
  editUser: (id, mail, password) => {
    dispatch(editUser(id, mail, password, ownProps.history));
  },
  acceptUser: (userId, accept) => {
    dispatch(acceptUser(userId, accept))
  },
  refusedUser: (userId, refused) => {
    dispatch(refusedUser(userId, refused))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RolesEdit);