// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import Quiz from '../../screen/Quiz';

// Action Creators
import { getQuiz, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getQuiz: (page) => {
    dispatch(getQuiz(page));
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Quiz);