// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';
import './explorer.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/explorer', breadcrumb: 'Explorer' },
];


// == Composant
const WebappExplore = ({ location, match, datas, getCat, waitingRequest, connected }) => {

    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes);

    const [cat, setCate] = useState([]);
    const [init, setInit] = useState(false);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        getCat()
    }, [])

    if(datas['@id'] === '/api/categories' && !init) {
        setCate(datas['hydra:member'])
        setInit(true);
        console.log('----------------------------------------')
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    } /*else if(datas['@id'] != undefined && datas['@id'] != '/api/tools') {
        getTools();
   }*/

console.log(cat)
    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%' }}>
        <div style={{textAlign:'left', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent:'space-between',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left', marginBottom:0 }}>Sélectionner une catégorie</h1>
            <Link className='button-shadow' to='/charger-contenu' style={{ width:'fit-content', backgroundColor:'#0578BE', color:'white', padding:10, borderRadius:10 }}>
                <div style={{ width: 'fit-content',marginLeft: 'auto',marginRight: 'auto',display: 'flex',flexDirection: 'row-reverse',alignItems: 'center' }}>
                    <p style={{ fontSize:15, fontFamily:'Roboto',marginLeft:10, textTransform:'uppercase' }}>Charger un contenu</p>
                    <img src='/images/plus-button-white.svg' style={{ height:20, width:20, margin:0 }}></img>
                </div>
            </Link>
        </div>
        <div className="category-card-container" style={{display:'flex', justifyContent:'space-between', flexDirection:'row', margin:'0', flexWrap:'wrap', maxWidth:1141}}>
            {cat.map((element) => (
                <Link to={`/explorer/categorie/${element.id}`} style={{textDecoration:'none', marginTop:20, marginBottom:60}}>
                    <div className='button-shadow' style={{backgroundImage:`url(https://mia.api.wacan-extranet.com/${element.image})`}}></div>
                    <p className="title-card">{element.title}</p>
                </Link>
            ))}
        </div>
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    </>
    )
};

// == Export
export default WebappExplore;