// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination'

import { boEntries } from '../../utils/headerEntries.js';
import { useFilePicker } from 'react-sage';

const testContent = [
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
    {
        id:1,
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        statut: 'EN LIGNE',
    },
]

// == Composant
const Quiz = ({ location, match, waitingRequest, getQuiz, datas, deleteEntity, connected }) => {

    const [content, setContent] = useState([])
    const [initItems, setInitItems] = useState(false)

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getQuiz(match.params.page);
    }, [])

    if(datas['@id'] === '/api/quizzs' && !initItems) {
        setContent(datas['hydra:member'])
        setInitItems(true)
    }
    console.log('datas ', datas)


    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={2} entries={boEntries} location={location} match={match} />
        <div className='page-wrapper'>
            <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                    <h1 className='page-title'>TABLEAU DE BORD QUIZ</h1>
                    <Link to='/admin/quiz/ajout' className='button buton-link' style={{ display:'flex', flexDirection:'row' }}>Créer un nouveau quiz</Link>
            </div>
                <div className='table-wrapper'>
                    <table>
                        <thead>
                            <tr>
                                <th>Nom de l'activité</th>
                                <th>Statut</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {content.map((element) => (
                                <tr>
                                    <td>{element.title}</td>
                                    <td>{element.status}</td>
                                    <td>
                                        <Link to={`/admin/quiz/questions/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                            <p>Modifier</p>
                                            <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                        </Link>
                                        <div onClick={() => {deleteEntity('quizzs', element.id)}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none', cursor: 'pointer'}}>
                                            <p>Supprimer</p>
                                            <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className='pagination'>
                        <Pagination datas={datas} url={'/admin/quiz/'} match={match} />
                    </div>
                </div>
        </div>
        </>
    )
};

// == Export
export default Quiz;