// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappParametres from '../../screen/WebappParametres';

// Action Creators
import { getVisitesRequest, me, editInfoUser } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVisitesRequest: () => {
    dispatch(getVisitesRequest());
  },
  getMe: () => {
    dispatch(me())
  },
  editInfoUser: (userId, mdp) => {
    dispatch(editInfoUser(userId, mdp))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappParametres);