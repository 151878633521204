// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import BoiteAjout from '../../screen/BoiteAjout';

// Action Creators
import { createTools, upload, getCat, setMessageNotif } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  cats:state.cat,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createTools: (name, cat, file, fileType, videoLink, status, history, uploaderName, uploaderLastName, uploaderEmail, schoolOnly, docTitle, docDescription, docLevel) => {
    //console.log(name, cat, file, ownProps.history, status)
    dispatch(createTools(name, cat, file, fileType, videoLink, status, history, uploaderName, uploaderLastName, uploaderEmail, schoolOnly, docTitle, docDescription, docLevel));
  },
  upload: (data) => {
    console.log(data)
    dispatch(upload(data));
  },
  getCat: () => {
    dispatch(getCat());
  },
  setMessageNotif: (message, good) => {
    dispatch(setMessageNotif(message, good));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoiteAjout);