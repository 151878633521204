// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import AlveolesContent from '../../screen/AlveolesContent';

// Action Creators
import { getalveolesContents, editAvleole, deleteEntity } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getalveolesContents: (id) => {
    dispatch(getalveolesContents(id));
  },
  editAvleole: (id, title, description, icon) => {
    dispatch(editAvleole(id, title, description, icon, ownProps.history));
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlveolesContent);