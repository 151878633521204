// == Import : npm
import React, { useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]


// == Composant
const WebappQuizTestScore = ({ location, match, waitingRequest, connected }) => {

    const [selected, setSelected] = useState(null);

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    let score = 0;
    location.infos.selected.forEach((element, index) => {
        if(element == location.infos.questions[index].correct) {
            score+=1
        }
    })

    return (
    <>
    <Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <MenuVisite currentMenuItem={2} />
        <div style={{ display:'flex', justifyContent: 'left',width:'100%', margin:'100px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'center', width:'100%' }}>Score</h1>
        </div>
        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%' }}>
            <div style={{ height:130, width:'45%', borderRadius:10, backgroundColor:'#0578BE', position:'relative' }}>
                <p style={{ color:'white', textTransform:'uppercase', fontWeight:'bold', fontSize:15, position:'absolute', top:'48%', left:'10%' }}>Votre score final</p>
                <p style={{ color:'white', textTransform:'uppercase', fontWeight:'bold', fontSize:50, position:'absolute', top:'20%', right:'20%' }}>{score}</p>
    <p style={{ color:'white', textTransform:'uppercase', fontWeight:'bold', fontSize:50, position:'absolute', top:'48%', right:'10%' }}>{location.infos.questions.length}</p>
                <div style={{ height:3, width:75, position:'absolute', top:'50%', right:'10%', backgroundColor:'white', transform:'rotate(-45deg)'}}></div>
            </div>
            {score >= location.infos.questions.length/2 && <div style={{ height:130, width:'45%', borderRadius:10, backgroundImage:'url(/images/fond-boite-outil.png)', backgroundRepeat:'no-repeat', backgroundSize:'cover', color:'white', textAlign:'left', padding:'40px 60px', boxSizing:'border-box' }}>
                <p style={{ fontWeight:'bold' }}>Bravo !</p>
                <br />
                <p>Votre classe est prêtre a venir visiter la maison de l'intelligence artificielle</p>
            </div>}
            {score < location.infos.questions.length/2 && <div style={{ height:130, width:'45%', borderRadius:10, backgroundImage:'url(/images/fond-boite-outil.png)', backgroundRepeat:'no-repeat', backgroundSize:'cover', color:'white', textAlign:'left', padding:'40px 60px', boxSizing:'border-box' }}>
                <p style={{ fontWeight:'bold' }}>Dommage...</p>
                <br />
                <p>Votre classe est prêtre a venir visiter la maison de l'intelligence artificielle</p>
            </div>}
        </div>
        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', marginTop:80 }}>
            {/*<Link className='button' style={{ height:'fit-content' }}>Consulter les réponse fausses</Link>*/}
            <Link to='/accueil' className='button' style={{ height:'fit-content' }}>Page d'accueil</Link>
        </div>
    </div>
    <div>

    </div>
    <Actu />
    </>
    )
};

// == Export
export default WebappQuizTestScore;