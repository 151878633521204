// == Import : npm
import React, {useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import { useLocation } from "react-router";

// == Import : local
import '../../style/style.scss';
//import './header.scss';

// == Composant


const pagination = ({ datas, url, match }) => {

    let number_of_page = 1

    if (datas['hydra:view'] && datas['hydra:view']['hydra:last']) {
        number_of_page = datas['hydra:view']['hydra:last'].split('=')[datas['hydra:view']['hydra:last'].split('=').length-1];
    } else {
        number_of_page = 1
    }
    
    /*if(match.params.page < 1 || match.params.page > number_of_page) {
        return <Redirect to={`${url}1`} />
    }*/

    let toDisplay = [];

    for (let index = 1; index <= number_of_page; index++) {
        toDisplay[index] = <Link 
        key={index}
        to={`${url}${index}`}
        className='page-number' 
        style={match.params.page == index ? {textDecoration: 'underline'} : {textDecoration:'none'}}>
            {index}
        </Link>;
    }

    return (
        <>
        <Link to={`${url}${match.params.page == 1 ? 1 : parseInt(match.params.page) - 1}`} style={{display:`${match.params.page == 1 || number_of_page <= 1 ? 'none' : 'block' }`}} className='prev-next'>{'<'}</Link>
        <>{toDisplay}</>
        <Link to={`${url}${match.params.page == number_of_page ? number_of_page : parseInt(match.params.page) + 1}`} style={{display:`${match.params.page == number_of_page || number_of_page <= 1 ? 'none' : 'block' }`}} className='prev-next'>{'>'}</Link>
        </>
    );
}

// == Export
export default pagination;