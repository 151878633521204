// == Import : npm
import React, { useEffect, useState } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import FileUpload from '../../components/FileUpload';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const BoiteEdit = ({ location, history, match, waitingRequest, datas, getTool, editTool, cats, getCat, connected, setMessageNotif }) => {

    
    const [name, setName] = useState(null);
    const [cat, setcat] = useState(null);
    const [categories, setCategories] = useState([]);
    const [file, setFile] = useState(null);
    const [reset, setReset] = useState({});
    const [stateInit, setStateInit] = useState(false);
    const [fileUrl, setFileUrl] = useState('')
    const [displayVideo, setDisplayVideo] = useState(false)
    const [videoLink, setVideoLink] = useState('')
    
    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN')) {
            getCat();
            getTool(match.params.id);
        }
    }, []);

    if(name===null) {
        setName(datas.uploaderName)
    }

    console.log(datas)
    if(!stateInit && datas['@id'] === `/api/tools/${match.params.id}` && cats['@id'] === '/api/categories') {
        setName(datas.title);
        setcat(datas.category?datas.category.id:null);
        setFile(null);
        setCategories(cats['hydra:member'])
        setStateInit(true)
    }

    const arrondi = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const OnClickDo = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'image/jpg' || fileURI.type === 'image/png' || fileURI.type === 'image/jpeg' || fileURI.type === 'application/pdf') {
          //setFileName(fileURI.name)
          setFileUrl(URL.createObjectURL(fileURI))
    
          const data = new FormData();
          data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
          setFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    console.log(cat)
    const data = new FormData();
    console.log('file', file)

    return (
    <>
    <Header currentMenuItem={0} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper'>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%', flexDirection:'row', width:'100%' }}>
            <h1 className='page-title'>Modifier un contenu</h1>
            <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Nom du fichier</div>
                <input type='text' className="input-text" placeholder='Nom du fichier' value={name} onChange={(e) => {setName(e.target.value)}}></input>
            </div>
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Indiquer la categorie</div>
                <div className="white-background">
                    {
                        categories.map((element) => (
                            <div>
                                <label htmlFor={element.id}>
                                    <input type="radio" checked={cat == element.id} id={element.id} style={{width:'auto'}} name="category" value={element.title} onClick={(e) => {setcat(e.target.id)}} />
                                    {element.title}
                                </label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Type de fichier Téléchargé</div>
                    {file ? ( /* <-- Ici je check si l'utilisateur a upload un fichier dans ce cas j'affiche ce fichier plutot que celui de la requête */
                        file.get('file').type !== 'application/pdf' ? file.get('file').type === 'application/pdf' ? null : (
                            /* Ici je suis dans la condition "file.get('file').type !== 'application/pdf'" il s'agit donc d'une image. On ne fait pas de preview de vidéo pour l'instant, sinon voir plus bas */
                            <>
                                <div style={{textAlign:'center', marginTop:30}}>
                                    <img src={fileUrl} style={{width:'auto',height:'auto', maxWidth:'700px'}} alt=""/>
                                </div>
                                <div className="file-details">
                                    {file ? <h4>Fichier: {file.get('file').name}</h4> : <></>}
                                </div>
                            </>
                        ) : (
                            /* Ici je suis dans la condition "file.get('file').type === 'application/pdf'" il s'agit donc d'un PDF */
                            <>
                                <div style={{textAlign:'center', marginTop:30}}>
                                    <img src='/images/pdf-color.svg' style={{width:45,height:'auto'}} alt=""/>
                                </div>
                                <div className="file-details">
                                    {file ? <h4>Fichier: {file.get('file').name}</h4> : <></>}
                                </div>
                            </>
                        )
                    ) : (
                        datas ? datas.contentType !== 'application/pdf' ? datas.contentType === 'application/pdf' ? null : ( /* <-- Ici je check le type de fichier reçu par la requête */
                            datas.contentType === 'vidéo' ? ( /* <-- Ici je suis dans la condition "datas.contentType !== 'application/pdf'" et je check si le type de fichier est une vidéo */
                                <div style={{marginTop:30}}>
                                    <p>Fichier: {datas.fileUrl ? datas.fileUrl : null}</p>
                                </div>
                            ) : ( /* <-- Si ce n'est pas une vidéo dans ce cas c'est une image */
                                <>
                                    <div style={{textAlign:'center', marginTop:30}}>
                                        <img src={`https://mia.api.wacan-extranet.com/${datas.fileUrl}`} style={{width:'auto',height:'auto', maxWidth:'700px'}} alt=""/>
                                    </div>
                                    <div>
                                        <p>Fichier: {datas.fileUrl ? datas.fileUrl.split('_')[1] : null}</p>
                                    </div>
                                </>
                            )
                    ) : (
                        /* Ici je suis dans la condition "datas.contentType === 'application/pdf'" */
                        <>
                            <div style={{textAlign:'center', marginTop:30}}>
                                <img src='/images/pdf-color.svg' style={{width:45,height:'auto'}} alt=""/>
                            </div>
                            <div>
                                <p>Fichier: {datas.fileUrl ? datas.fileUrl.split('_')[1] : null}</p>
                            </div>
                        </>
                    ) : null /* <-- Si le type de fichier n'est pas reconnu dans ce cas je n'affiche rien */
                    )}
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
                    <label htmlFor="pdf-file-upload" onClick={() => setDisplayVideo(false)} className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer'}}>
                        PDF
                        <img src="/images/pdf-icon.svg"></img>
                        {/*<FileUpload accept={"application/pdf"} id="pdf-file-upload" inputText={"Parcourir..."} OnClickDo={(fileURI) => {data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-')); setFile(data); console.log('onClickdo', data.get('file'))}} buttonClass={"input-button"} />*/}
                        <input
                            className="custom-file-input"
                            style={{display:'none'}}
                            type="file"
                            name="customFile"
                            id="pdf-file-upload"
                            aria-describedby="inputGroupFileAddon01"
                            accept="application/pdf"
                            onChange={(e) => {
                                const accept = "application/pdf"
                                accept.split(',').forEach((element) => {
                                if(element === element.replace(/\s+/g, '')){
                                    OnClickDo(e)
                                }
                                })
                            }}
                        />
                        <div className="input-button">Parcourir...</div>
                    </label>
                    <label htmlFor="image-file-upload" onClick={() => setDisplayVideo(false)} className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer'}}>
                        Image
                        <img src="/images/image-icon.svg"></img>
                        {/*<FileUpload accept={"image/png, image/jpeg"} id="image-file-upload" inputText={"Parcourir..."} OnClickDo={(fileURI) => {data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-')); setFile(data); console.log('onClickdo', data.get('file'))}} buttonClass={"input-button"} />*/}
                        <input
                            className="custom-file-input"
                            style={{display:'none'}}
                            type="file"
                            name="customFile"
                            id="image-file-upload"
                            aria-describedby="inputGroupFileAddon01"
                            accept="image/x-png,image/jpeg"
                            onChange={(e) => {
                                const accept = "image/png, image/jpeg"
                                accept.split(',').forEach((element) => {
                                if(element === element.replace(/\s+/g, '')){
                                    OnClickDo(e)
                                }
                                })
                            }}
                        />
                        <div className="input-button">Parcourir...</div>
                    </label>
                    <label className="button white-background" onClick={() => {
                        if(file) {
                            setFile(null); /* Si l'utilisateur a upload un fichier je le supprime pour pas créer de conflit */
                            setDisplayVideo(true)
                        } else {
                            setDisplayVideo(true)
                        }
                    }} style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', alignItems:'center', cursor:'pointer' }}>
                        <div>Lien Vidéo</div>
                        <img style={{width:34, height:42, marginBottom:0}} src="/images/video-icon.svg"></img>
                        <div></div>
                    </label>
                </div>
                {displayVideo ? (
                    <div>
                        <input type='text' className='input-text' placeholder='Lien vidéo' style={{marginTop:30, backgroundColor:'#F0F0F0', width:'100%'}} value={videoLink} onChange={(e)=>{setVideoLink(e.target.value)}} />
                    </div>
                ) : null}
                <div onClick={() => {if(name!=null && name!="" && cat!=null){editTool(match.params.id, name, cat, file, file ? file.get('file').type : null, videoLink, '+accepted', history)}else{setMessageNotif('Veuillez renseigner tout les champs', false)}}} className="button" style={{ margin:'0 auto', marginTop:40, height:'fit-content', cursor:'pointer' }}><img src="/images/check-icon.svg" style={{ width:15, height:15 }}></img>Valider</div>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default BoiteEdit;