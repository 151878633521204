// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import VoirReservation from '../../screen/VoirReservation';

// Action Creators
import { getAVistiRequest, acceptVistiRequest, deleteEntity, cancelVisitRequest, refusedVistiRequest } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAVistiRequest: (id) => {
    dispatch(getAVistiRequest(id));
  },
  acceptVistiRequest: (id) => {
    dispatch(acceptVistiRequest(id));
  },
  deleteEntity: (entityType, id) => {
    dispatch(deleteEntity(entityType, id));
  },
  cancelVisitRequest: (id) => {
    dispatch(cancelVisitRequest(id));
  },
  refusedVistiRequest: (id) => {
    dispatch(refusedVistiRequest(id))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoirReservation);