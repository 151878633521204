// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../containers/HeaderSchool';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/preparer-visite', breadcrumb: 'Votre visite' },
    { path: '/preparer-visite/test-connaissance', breadcrumb: 'Testé vos connaissances' },
];


// == Composant
const WebappQuizTest = ({ location, history, match, waitingRequest, getQuizFile, datas, connected, quizzFile }) => {

    const [selected, setSelected] = useState([]);
    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes);

    const [quizz, setDatasz] = useState([])
    const [questions, setQuestions] = useState([])
    const [init, setInit] = useState(false)

    const [questionIndex, setQuestionIndex] = useState(0);

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        //getQuiz('&status=discover')
        getQuizFile()
    }, [])

    if(datas['@id'] === '/api/quizzs' && !init) {
        datas['hydra:member'].forEach(element => {
            if(element.questions.length)
            {
                setDatasz(element)
                console.log(element.questions.length)
                if(element.questions.length === 1) {
                    setQuestions(element.questions.sort((a, b) => (b.rank - a.rank)))
                } else {
                    setQuestions(element.questions)
                }
                setInit(true)
            }
        });
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    console.log(quizzFile)

    return (
    <>
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        <div style={{ display:'flex', justifyContent: 'left',width:'100%', margin:'100px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left' }}>Quiz</h1>
        </div>
        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly', marginTop:30, width:'100%' }}>
            {quizzFile && quizzFile.quizzQuestion.length !== 0 ? (
                <a className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }} href={`https://mia.api.wacan-extranet.com${quizzFile.quizzQuestion[0].fileUrl}`}>
                    <div>Questionnaire</div>
                    <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                    <div className="input-button">Télécharger</div>
                </a>
            ) : null}
            {quizzFile && quizzFile.quizzAnswer.length !== 0 ? (
                <a className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }} href={`https://mia.api.wacan-extranet.com${quizzFile.quizzAnswer[0].fileUrl}`}>
                    <div>Réponse</div>
                    <img style={{width:34, height:42, marginBottom:0}} src="/images/pdf-icon.svg"></img>
                    <div className="input-button">Télécharger</div>
                </a>
            ) : null}
        </div>
    </div>
    <div>

    </div>
    <Actu />
    </>
    )
};

// == Export
export default WebappQuizTest;