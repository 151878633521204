// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import Pagination from '../../components/Pagination'

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        id:1,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:2,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:3,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:4,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:5,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:6,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:7,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:8,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:9,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
    {
        id:10,
        selected: false,
        name: 'test',
        dateDebut: '01/01/0001',
        dateFin: '01/01/0001',
    },
]

// == Composant
const GererReservation = ({ location, match, waitingRequest, datas, getVisitesRequest, connected, deleteEntity }) => {

    const [allChecked, setAllchecked] = useState(false);
    const [content, setcontent] = useState(testContent);
    const [displayActions, setdisplayActions] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);

    // console.log(datas)

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getVisitesRequest(/*match.params.page*/);
    }, [])

    if(datas['@id']==='/api/visit_requests' && content === testContent) {
        setcontent(datas['hydra:member'])
    }

    const checkAll = (e) => {
        console.log('event', e.target.checked)
        if(e.target.checked === true) {
            setAllchecked(true);
        } else {
            setAllchecked(false)
        }

        datas['hydra:member'].forEach(element => {
            element.selected = !allChecked;
        });
        console.log(content)
    }

    const onClickDisplayActions = () => {
        if (displayActions === true) {
            setdisplayActions(false);
        } else {
            setdisplayActions(true);
        }
    }

    const handleAction = () => {
        if(selectedAction === 'delete') {
            datas['hydra:member'].forEach(element => {
                if(element.selected === true) {
                    deleteEntity('visit_requests', element['@id'].split('/')[3])
                }
            })
        }
    }

    const statusName = (status) => {
        switch (status) {
            case 'pending':
              return 'En attente'
            case 'accepted':
                return 'Accepté'
            case 'canceled':
                return 'Annulé'
            default:
                return 'Refusé'
        }
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0%' }}>
                    <div style={{ display:'flex',flexDirection:'column', justifyContent: 'space-between' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase' }}>Demande de réservation</h1>
                        <h2 className='page-subtitle' style={{ marginBottom: 50, textTransform:'uppercase' }}>gestion des réservations</h2>
                    </div>
                </div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'20px 0%' }}>
                    <div>
                        <select name="actions" id="actions"  className='button button-shadow' style={{ marginRight:20 }} onChange={(e) => {setSelectedAction(e.target.value)}}>
                            <option value="">action groupée</option>
                            <option value="delete">Supprimer</option>
                        </select>
                        <input type='submit' className='button button-shadow' value='Appliquer' onClick={() => {handleAction()}}></input>
                    </div>
                    <div>
                        <input type='text' placeholder='Rechercher...' className="input-text" style={{ width:212, marginRight:20 }}></input>
                        <input type='submit' className='button button-shadow' value='Chercher une réservation'></input>
                    </div>
                </div>

                <div className='table-wrapper'>
                    <table style={{ tableLayout:'auto' }}>
                        <thead>
                            <tr>
                                <th style={{ width:'fit-content' }}><input type='checkbox' onClick={checkAll}></input></th>
                                <th>Réservation émise par</th>
                                <th>début de l'évévénement</th>
                                <th>fin de l'événement</th>
                                <th>Statut de l'événement</th>
                                <th style={{width:'10%'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas["@id"] === '/api/visit_requests' ? datas['hydra:member'].map((element) => (
                                <tr>
                                    <td style={{ width:'fit-content' }}><input checked={element.selected} type='checkbox' onClick={() => {console.log(element.selected); element.selected = !element.selected; setcontent([...content]); console.log(content)}}></input></td>
                                    <td>{element.teacherName}</td>
                                    <td>{element.visit ? moment(element.visit.start).format('DD/MM/YY HH:mm') : ''}</td>
                                    <td>{element.visit ? moment(element.visit.end).format('DD/MM/YY HH:mm') : ''}</td>
                                    <td>{statusName(element.statusAccepted)}</td>
                                    <td style={{ fontStyle:'italic', width:'10%' }}>
                                        <Link to={`/admin/gerer-reservation/voir/${element['@id'] ? element['@id'].split('/')[3]: ''}`}>Voir</Link>
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>

                    <div className='pagination'>
                        <Pagination datas={datas} url={'/admin/gerer-reservation/'} match={match} />
                    </div>
                </div>
            </div>
        </>
    )
};

// == Export
export default GererReservation;