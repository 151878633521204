// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import VoirEtablissement from '../../screen/VoirEtablissement';

// Action Creators
import { getSchool, editSchools } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSchool: (id) => {
    dispatch(getSchool(id));
  },
  editSchools: (name, principale, adresse, phone, zipCode, history, id) => {
    dispatch(editSchools(name, principale, adresse, phone, zipCode, history, id))
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoirEtablissement);