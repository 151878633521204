// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import AlveolesContentModif from '../../screen/AlveolesContentModif';

// Action Creators
import { editAlveoleContent, getalveolesContent, getQuiz } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  quizzs: state.quizzs,
  waitingRequest: state.waitingRequest,
  connected: state.connected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editAlveoleContent: (name,file, body, status, quiz, id, qr) => {
    dispatch(editAlveoleContent(name, file, body, status, quiz, id, qr, ownProps.history));
  },
  getalveolesContent: (id) => {
    dispatch(getalveolesContent(id));
  },
  getQuiz: (page, params) => {
    dispatch(getQuiz(page, params, true));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlveolesContentModif);