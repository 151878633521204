// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';
import useWindowDimensions from '../../utils/dimension';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import Actu from '../../components/Actu';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/accueil', breadcrumb: 'Accueil' },
];


// == Composant
const WebappHome = ({ location, match }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalEvent, setShowModalEvent] = useState(false);
    const [showModalRecurrent, setShowModalRecurrent] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem("alreadyLaunched")) {
            setShowModal(true);
        }
        if (localStorage.getItem("eventRegister") && localStorage.getItem("eventRegister").startDate !== null) {
            setShowModalEvent(true);
        }
    })

    const { height, width } = useWindowDimensions();
    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/'] });

    /*if (!localStorage.getItem("alreadyLaunched")) {
        setShowModal(true);
        if (showModal !== true) {
            localStorage.setItem("alreadyLaunched", 'true');   
        }
    }*/

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          justifyContent:'space-between',
          //height:307,
          width:575,
          padding: '30px 40px',
          color: '#0578BE',
          boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
          borderRadius:10
        }
      };

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={width >= 1115 ? { paddingLeft:'20%', paddingRight:'20%', paddingBottom:'77px' } : { paddingLeft:'10%', paddingRight:'10%', paddingBottom:'77px' }}>
        <div style={{textAlign:'left', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        {/*<div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', marginTop:50}}>
            {homeMenu.map((menu) => (
                <Link to={menu.link} style={{width:'17%', height:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%'}}>
                        <img src={menu.icon} style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>{menu.title}</p>
                    </div>
                </Link>
            ))}
        </div>*/}
        {width >= 1115 ?
        <>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-evenly', marginTop:50}}>
                <Link to='/explorer' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                        <img src='/images/tech.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Médiathèque</p>
                    </div>
                </Link>
                <Link to='/preparer-visite' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                        <img src='/images/002-visitor.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Votre visite</p>
                    </div>
                </Link>
                <Link to='/visite' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                        <img src='/images/001-calendar.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Mes réservations</p>
                    </div>
                </Link>
            </div>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-evenly', marginTop:50}}>
                <Link to='/informations-pratiques' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                        <img src='/images/info.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Informations pratiques</p>
                    </div>
                </Link>
                <Link to='/archives' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                        <img src='/images/archives-icon.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Historique</p>
                    </div>
                </Link>
                <Link to='/parametres' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                    <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                        <img src='/images/settings.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                        <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Changer de mot de passe</p>
                    </div>
                </Link>
            </div>
        </> : 
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-evenly', marginTop:30}}>
            <Link to='/explorer' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                    <img src='/images/tech.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                    <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Médiathèque</p>
                </div>
            </Link>
            <Link to='/preparer-visite' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                    <img src='/images/LOGO-MIA_blanc.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                    <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Votre visite</p>
                </div>
            </Link>
            <Link to='/visite' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                    <img src='/images/LOGO-MIA_blanc.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                    <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Mes réservations</p>
                </div>
            </Link>
            <Link to='/informations-pratiques' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                    <img src='/images/info.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                    <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Informations pratiques</p>
                </div>
            </Link>
            <Link to='/archives' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                    <img src='/images/archives-icon.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                    <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Historique</p>
                </div>
            </Link>
            <Link to='/parametres' style={{width:'193px', height:'193px',maxWidth:'193px', margin:15, backgroundColor:'#0578BE', borderRadius:10}}>
                <div className="button-shadow" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100%', borderRadius:10}}>
                    <img src='/images/settings.svg' style={{ height:85, width:85, marginBottom:25 }}></img>
                    <p style={{color:'white', fontSize:18, textTransform:'uppercase', fontWeight:'bold', width:'70%'}}>Changer de mot de passe</p>
                </div>
            </Link>
        </div>
        }
        {/*<div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 0 0' }}>
            <h1 className='page-title'>Selectionnez une catégorie</h1>
        </div>
        <Link to='/mediatheque' style={{textDecoration:'none'}}>
            <div className='button-shadow icon-text-button' style={{  }}>
                <div style={{  }}>
                    <img src='/images/book.svg' style={{ height:85, width:85 }}></img>
                    <div>
                        Boite à outils
                    </div>
                </div>
                <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0 }}></img>
            </div>
        </Link>
        <Link to='/visite' style={{textDecoration:'none'}}>
            <div className='button-shadow icon-text-button' style={{ marginTop:40 }}>
                <div style={{  }}>
                    <img src='/images/tech.svg' style={{ height:85, width:85 }}></img>
                    <div>
                        Mes réservations
                    </div>
                </div>
                <img src='/images/chevron-blanc.svg' style={{ height:45, width:19, margin:0 }}></img>
            </div>
        </Link>*/}
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    <Modal
    isOpen={showModal}
    //onAfterOpen={afterOpenModal}
    onRequestClose={() => {
        setShowModal(false);
        localStorage.setItem("alreadyLaunched", 'true')
    }}
    style={customStyles}
    contentLabel="Example Modal"
    >
        <div style={{position:'relative'}}>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%' }}>
                <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="2x" icon={faTimes} onClick={() => {setShowModal(false); localStorage.setItem("alreadyLaunched", 'true')}} />
            </div>
            <div style={{ width:'100%', marginBottom:'28px' }}>
                <p style={{fontSize:'16px'}}>Bonjour,</p>
                <br/>
                <p style={{fontSize:'16px'}}>Bienvenue sur la web app de la Maison de l’Intelligence Artificielle !</p>
                <br />
                <p style={{fontSize:'16px', marginBottom:5 }}>Vous trouverez ici :</p>
                <ul style={{listStyle:'initial', marginLeft:15}}>
                    <li style={{marginBottom:5}}>une Médiathèque contenant de nombreuses ressources vous permettant d’aborder la thématique de l’Intelligence Artificielle avec vos élèves en classe;</li>
                    <li style={{marginBottom:5}}>l'ensemble des parcours pédagogiques de la MIA;</li>
                    <li>la possibilité de réserver une visite sur place pour expérimenter les activités pédagogiques.</li>
                </ul>
            </div>
        </div>
    </Modal>
    <Modal
    isOpen={showModalEvent}
    //onAfterOpen={afterOpenModal}
    onRequestClose={() => {
        setShowModalEvent(false);
        localStorage.removeItem("eventRegister")
    }}
    style={customStyles}
    contentLabel="Example Modal"
    >
        <div style={{position:'relative'}}>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-end', width:'100%' }}>
                <FontAwesomeIcon style={{color:'red', cursor:'pointer'}} size="2x" icon={faTimes} onClick={() => {setShowModalEvent(false); localStorage.removeItem("eventRegister")}} />
            </div>
            <div style={{ width:'100%', marginBottom:'28px' }}>
                <p style={{fontSize:'16px'}}>Votre réservation a bien été prise en compte. Un email de confirmation va vous être envoyé.</p>
                <br/>
                <p style={{fontSize:'16px'}}>Récapitulatif de votre demande : {localStorage.getItem("eventRegister") ? JSON.parse(localStorage.getItem("eventRegister")).startDate : null}</p>
                <br />
                <p style={{fontSize:'16px'}}>Un administrateur va étudier votre demande et y répondra dans les meilleurs délais.</p>
            </div>
        </div>
    </Modal>
    </>
    )
};

// == Export
export default WebappHome;