// == Import : npm
import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import ReactLoading from 'react-loading';
import SelectSearch from 'react-select-search';

// == Import : local
import '../../style/style.scss';
import './roles-ajout.scss'

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const RolesAjout = ({ location, history, match, waitingRequest, getUser, datas, connected, addUser }) => {

     const [mail, setMail] = useState(null);
     const [mdp, setMdp] = useState(null);
     const [notif, setNotif] = useState(false);
     const [role, setRole] = useState(null);
     const [etablissement, setEtablissement] = useState(null);
     const [classroom, setClassroom] = useState(null);
     const [lastName, setLastName] = useState(null);
     const [firstName, setFirstName] = useState(null);
     const [stateInit, setStateInit] = useState(false);

     useEffect(() => {
        //getUser(match.params.id)
     }, [])

     if(!stateInit && datas['@id'] === `/api/users/${match.params.id}`) {
        //setIdentifiant(datas.username);
        setMail(datas.email);
        setMdp(datas.password);
        setRole(datas.roles[0])
        setStateInit(true)
    }

     if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
        <>
            <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%' }}>
                    <div style={{ display:'flex',flexDirection:'row', justifyContent: 'space-between', width:'100%' }}>
                        <h1 className='page-title' style={{ textTransform:'uppercase' }}>Gestion des utilisateurs</h1>
                        <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                    </div>
                </div>
                <div className='grey-background' style={{ marginBottom: 0 }}>
                    <label style={{ marginTop: 30, textTransform: 'uppercase', fontSize:20, fontWeight:500, textAlign:'left', width:'50%' }}>
                        Adresse email
                        <input style={{marginTop:10}} type='email' className="input-text" placeholder='Adresse email' value={mail} onChange={(e) => {setMail(e.target.value)}}></input>
                    </label>
                    <label style={{ marginTop: 30, textTransform: 'uppercase', fontSize:20, fontWeight:500, textAlign:'left', width:'50%' }}>
                        Mot de passe
                        <input style={{marginTop:10}} type='password' className="input-text" placeholder='Mot de passe' value={mdp} onChange={(e) => {setMdp(e.target.value)}}></input>
                    </label>
                    <div style={{ marginTop:20 }}>
                        <label style={{ display:'inline' }}>
                            <input type='checkbox' name='notify' value='notify' onClick={(e) => {setNotif(e.target.checked); console.log(e.target.checked)}}></input>
                            <span style={{marginLeft:5}}>Notifier par email l'utilisateur à propos de son compte</span>
                        </label>
                    </div>
                </div>
                <div style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', flexWrap:'wrap', margin:'0 0%', marginBottom: 150, padding:'20px 50px', borderRadius:10, color: '#0578BE' }}>
                    <label style={{ marginTop: 30, textTransform: 'uppercase', fontSize:20, marginBottom:10, fontWeight:500 }}>Rôle</label>
                    <select name="role" id="role-select" className='button' style={{ height:'fit-content', marginBottom:30 }} onChange={(e) => {setRole(e.target.value)}}>
                        <option value="">Selectionner un rôle</option>
                        <option value="ROLE_USER" selected={role === 'ROLE_USER'}>Etablissement</option>
                        <option value="ROLE_ADMIN" selected={role === 'ROLE_ADMIN'}>Admin</option>
                        <option value="" selected={role === ''}>Anonyme</option>
                    </select>
                    {role === 'ROLE_USER' ? (
                    <>
                        <label style={{ marginTop: 10, textTransform: 'uppercase', fontSize:20, fontWeight:500, textAlign:'left', width:'50%' }}>
                            Etablissement
                            <SelectSearch
                                options={[]}
                                getOptions={(query) => {
                                    //getOptions(query)
                                    return new Promise((resolve, reject) => {
                                        fetch(`https://mia.api.wacan-extranet.com/api/schools?zipCode=${query}`,{ 
                                                method: 'GET',
                                                headers: new Headers({
                                                    'Accept': 'application/ld+json',
                                                    'Content-Type' : 'application/ld+json',
                                                })
                                            })
                                            .then(response => response.json())
                                            .then((response) => {
                                                resolve(response['hydra:member'].map((elem) => ({value: elem.id, name: elem.name})))
                                            })
                                            .catch(reject);
                                    });
                                }}
                                type="search"
                                //style={{ position: 'fixed', left: -1000 }}
                                value={etablissement}
                                search
                                autoComplete="disabled"
                                placeholder="Selectionnez l'établissement de l'utilisateur"
                                onChange={(e) => setEtablissement(e)}
                            />
                            {/*<input style={{marginTop:10}} type='text' className="input-text" placeholder='Etablissement' value={etablissement} onChange={(e) => setEtablissement(e.target.value)}></input>*/}
                        </label>
                        {/*<label style={{ marginTop: 30, textTransform: 'uppercase', fontSize:20, fontWeight:500, textAlign:'left', width:'50%' }}>
                            Classe
                            <input style={{marginTop:10}} type='text' className="input-text" placeholder='Classe' value={classroom} onChange={(e) => setClassroom(e.target.value)}></input>
                        </label>
                        <label style={{ marginTop: 30, textTransform: 'uppercase', fontSize:20, fontWeight:500, textAlign:'left', width:'50%' }}>
                            Nom
                            <input style={{marginTop:10}} type='text' className="input-text" placeholder='Nom' value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
                        </label>
                        <label style={{ marginTop: 30, textTransform: 'uppercase', fontSize:20, fontWeight:500, textAlign:'left', width:'50%' }}>
                            Prénom
                            <input style={{marginTop:10}} type='text' className="input-text" placeholder='Prénom' value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                            </label>*/}
                    </>
                    ) : null}
                    <button className='button' style={{ marginTop:40 }} onClick={() => addUser(mail, mdp, etablissement, classroom, lastName, firstName, notif)}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Valider</button>
                </div>
            </div>
        </>
    )
};

// == Export
export default RolesAjout;