// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappAjoutContenu from '../../screen/WebappAjoutContenu';

// Action Creators
import { createTools, setMessageNotif } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  //datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createTools: (name, cat, file, fileType, videoLink, status, history, uploaderName, uploaderLastName, uploaderEmail, schoolOnly, docTitle, docDescription, docLevel) => {
    //console.log(name, cat, file, ownProps.history, status)
    dispatch(createTools(name, cat, file, fileType, videoLink, status, history, uploaderName, uploaderLastName, uploaderEmail, schoolOnly, docTitle, docDescription, docLevel));
  },
  setMessageNotif: (message, good) => {
    dispatch(setMessageNotif(message, good));
  },
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappAjoutContenu);