// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import { ReactAgenda , ReactAgendaCtrl , guid } from 'react-agenda';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';
import HeaderSchool from '../../components/HeaderSchool';
import Footer from '../../components/Footer';
import MenuVisite from '../../components/MenuVisite';
import Actu from '../../components/Actu';

import moment from 'moment';

const headerEntries = [
    {
        title:'Accueil',
        link:'/accueil',
        class:''
    },
    {
        title:'Historique',
        link:'/archives',
        class:''
    },
    {
        title:'paramètres',
        link:'/parametres',
        class:'',
    },
]

const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    { path: '/visite', breadcrumb: 'Réserver une visite' },
    { path: '/visite/nouvelle-demande/3', breadcrumb: 'Nouvelle demande - étape 3' },
];

var colors= {
    'color-1':"rgba(5, 120, 190 , 1)" ,
    "color-2":"rgba(75, 75, 75, 1)" ,
}

var now = new Date();

var items = [
    {
        _id            :guid(),
        name          : 'Meeting , dev staff!',
        startDateTime : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
        endDateTime   : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0),
        classes       : 'color-1'
    },
    {
        _id            :guid(),
        name          : 'Working lunch , Holly',
        startDateTime : new Date(now.getFullYear(), now.getMonth(), now.getDate()+1, 11, 0),
        endDateTime   : new Date(now.getFullYear(), now.getMonth(), now.getDate()+1, 13, 0),
        classes       : 'color-2'
    },
    
];

// == Composant
const WebappVisiteDemande3 = ({ location, history, match, waitingRequest, getVisites, datas, createVisitRequest, connected }) => {

    const [Items, setItems] = useState(items);
    const [selected, setSelected] = useState([]);
    const [cellHeight, setCellHeight] = useState(30);
    const [showModal, setShowModal] = useState(false);
    const [showModalRecurrent, setShowModalRecurrent] = useState(false);
    const [locale, setlocale] = useState("fr");
    const [rowsPerHour, setRowsPerHour] = useState(1);
    const [numberOfDays, setnumberOfDays] = useState(7);
    const [startDate, setStartDate] = useState(new Date());

    const [initItems, setInitItems] = useState(false)

    const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/visite/nouvelle-demande'] });

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_USER')=='ROLE_USER'))
        getVisites('&status!=locked')
        console.log(connected)
    },[]);

    if(datas["@id"] === "/api/visits" && !initItems) {
        const temp = datas["hydra:member"].map((element) => {
            const start = new Date(element.start)
            start.setHours(start.getHours())
            const end = new Date(element.end)
            end.setHours(end.getHours())
            return {
                _id           : element.id,
                name          : element.title,
                startDateTime : element.status !== 'locked' ? start : null,
                endDateTime   : element.status !== 'locked' ? end : null,
                visitType     : element.visitType ? element.visitType.title : null,
                description   : element.visitType ? element.visitType.description : null,
                classes       : 'color-2'
            }
        })
        setItems(temp)
        setInitItems(true)
        console.log(initItems)
    }

    const handleCellSelection = (item) =>{
        console.log('handleCellSelection',item)
        //setSelected([item])
        //setShowModal(true)
    }
    
    const handleItemEdit = (item) => {
        console.log('handleCellSelection',item)
        //setSelected([item])
        //setShowModal(true)
    }

    const handleRangeSelection = (item) => {
        console.log('handleCellSelection',item)
        //setSelected([item])
        //setShowModal(true)
    }

    const editItem = (item) => {
        console.log(item);
        setSelected([item]);
        setShowModal(true);
    }
    const editEvent = () => {
    }

    const addNewEvent = () => {
    }
    const chooseItem = (item) => {
        //setShowModal(true)
    }
    
    console.log('main', Items)
    
    const AgendaCard = ({item}) => {
        return (
            <div style={{display:'block', /*position:'absolute' ,*/ color:'white', padding:10, backgroundColor:'#FFFFFF26', width:'-webkit-fill-available', display:'flex', justifyContent:'space-between' }} onClick={()=> {editItem(item)}}>
                {item.name}
                <div style={{ color:'white' }}>Voir</div>
            </div>
        );
    }

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems:'center',
          justifyContent:'space-between',
          //height:307,
          width:305,
          padding: '30px 40px',
          color: '#0578BE',
          boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
          borderRadius:10
        }
      };


      if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_USER')!='ROLE_USER')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    return (
    <>
    {/*<Header currentMenuItem={0} entries={headerEntries} location={location} match={match} deco />*/}
    <HeaderSchool />
    <div className='page-wrapper' style={{ paddingLeft:'20%', paddingRight:'20%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div style={{textAlign:'left', alignSelf:'flex-start', margin:'30px 0', color:'#0578BE', textTransform:'uppercase', fontWeight:'bold'}}>
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <span key={breadcrumb.match.url}>
                    <Link to={breadcrumb.match.url}>{breadcrumb.breadcrumb} {arr.length - 1 === i ? '' : <span> / </span>}</Link>
                </span>
            ))}
        </div>
        {/*<MenuVisite currentMenuItem={1} />*/}
        <div style={{ display:'flex',flexDirection:'column', justifyContent: 'left',width:'100%', margin:'50px 0% 0 0' }}>
            <h1 className='page-title' style={{ textAlign:'left' }}>Nouvelle demande</h1>
            <h2 className='page-subtitle' style={{ textAlign:'left' }}>3. Période de visite</h2>
        </div>
        <div className="agenda-container" style={{ width:'100%', /*margin:'0 -35%'*/ }}>
            <ReactAgenda
                itemComponent={AgendaCard}
                minDate={now}
                maxDate={new Date(now.getFullYear(), now.getMonth()+3)}
                disablePrevButton={false}
                startDate={startDate}
                startAtTime={8}
                endAtTime={18}
                cellHeight={cellHeight}
                locale={locale}
                items={Items}
                numberOfDays={numberOfDays}
                rowsPerHour={rowsPerHour}
                itemColors={colors}
                autoScale={false}
                fixedHeader={true}
                onItemEdit={handleItemEdit}
                onCellSelect={handleCellSelection}
                onRangeSelection={handleRangeSelection}
            />
        </div>
        
    </div>
    <div>

    </div>
    <Actu />
    <Footer />
    <Modal
    isOpen={showModal}
    //onAfterOpen={afterOpenModal}
    onRequestClose={() => {
        setShowModal(false)
    }}
    style={customStyles}
    contentLabel="Example Modal"
    >
        <div style={{ width:'100%' }}>
            <p style={{ fontSize:15, textTransform:'uppercase', fontWeight:500, marginBottom:20}}>{selected[0] ? selected[0].name : ''}</p>
            <p style={{ fontSize:15, fontWeight:500}}>Descriptif de la visite</p>
            <p>{selected[0] ? selected[0].description : ''}</p>
            <p style={{ marginTop:15 }}><span style={{ fontSize:15, fontWeight:500}}>Type de visite : </span><span>{selected[0] ? selected[0].visitType : null}</span></p>
            <p style={{ marginTop:15 }}><span style={{ fontSize:15, fontWeight:500}}>date</span> : {selected[0] ? moment(selected[0].startDateTime).format('DD/MM/YY') : ''}</p>
            <p style={{ marginTop:15 }}><span style={{ fontSize:15, fontWeight:500}}>heure</span> : {selected[0] ? moment(selected[0].startDateTime).format('HH:mm') + ' - ' + moment(selected[0].endDateTime).format('HH:mm') : ''}</p>
        </div>
        <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', marginTop:20 }}>
            <button className='button' onClick={() => setShowModal(false)}>RETOUR</button>
            <button onClick={() => {createVisitRequest(`/api/visits/${selected[0] ? selected[0]['_id'] : ''}`, history); setShowModal(false); localStorage.setItem("eventRegister", JSON.stringify({'startDate':moment(selected[0].startDateTime).format('LLLL'), 'endDate':moment(selected[0].endDateTime).format('LLLL')}))}} className='button' style={{ textTransform:'uppercase' }}>Réserver</button>
        </div>
    </Modal>
    </>
    )
};

// == Export
export default WebappVisiteDemande3;