// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import FileUpload from '../../components/FileUpload';
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

const testContent = [
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
    {
        name: 'test',
        category: 'activité',
        date: '01/01/0001',
        type: 'PDF',
    },
]

// == Composant
const CategoryAjout = ({ location, history, match, waitingRequest, datas, editCat, getACat, connected }) => {
    
    const [name, setName] = useState(null);
    const [file, setFile] = useState(null);
    const [reset, setReset] = useState({});
    const [init, setInit] = useState(false);
    const [fileUrl, setFileUrl] = useState('')

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getACat(match.params.id)
    }, [])

    if(datas['@id'] === `/api/categories/${match.params.id}` && !init) {
        setName(datas.title);
        setInit(true)
    }

    const arrondi = (x) => {
        return Number.parseFloat(x).toFixed(2);
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const OnClickDo = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'image/jpg' || fileURI.type === 'image/png' || fileURI.type === 'image/jpeg' || fileURI.type === 'application/pdf') {
          //setFileName(fileURI.name)
          setFileUrl(URL.createObjectURL(fileURI))
    
          const data = new FormData();
          data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
          setFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    const data = new FormData();
    return (
    <>
    <Header currentMenuItem={4} entries={boEntries} location={location} match={match} />
    <div className='page-wrapper'>
        <div style={{ display:'flex', justifyContent: 'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
            <h1 className='page-title'>MODIFIER UNE CATEGORIE</h1>
            <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
        </div>
        <div className="form-wrapper">
            <div className='fieldset-wrapper'>
                <div className="fieldset-title">Nom de la catégorie</div>
                <input type='text' className="input-text" placeholder='Nom de la catégorie' value={name} onChange={(e) => {setName(e.target.value)}}></input>
            </div>
            <div className='fieldset-wrapper'>
                <div style={{display:'flex', justifyContent:'center'}}><img style={{height:100, width: 'auto', marginTop:30}} src={`${fileUrl !== '' ? fileUrl : `https://mia.api.wacan-extranet.com${datas.image}`}`}></img></div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
                    <label className="white-background button" style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', width:'15%', height:'fit-content', alignItems:'center', cursor:'pointer' }}>
                        <div> Image</div>
                        <img src="/images/image-icon.svg"></img>
                        {/*<FileUpload accept={"image/jpeg, image/png"} inputText={"Parcourir..."} OnClickDo={(fileURI) => {data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-')); setFile(data); console.log('onClickdo', data.get('file'))}} buttonClass={"input-button"} />*/}
                        <input
                            className="custom-file-input"
                            style={{display:'none'}}
                            type="file"
                            name="customFile"
                            id="pdf-file-upload"
                            aria-describedby="inputGroupFileAddon01"
                            accept="image/x-png,image/jpeg"
                            onChange={(e) => {
                                const accept = "image/x-png,image/jpeg"
                                accept.split(',').forEach((element) => {
                                if(element === element.replace(/\s+/g, '')){
                                    OnClickDo(e)
                                }
                                })
                            }}
                        />
                        <div className="input-button">Parcourir...</div>
                    </label>
                </div>
                <div className="file-details">
                    {file ? <h4>Fichier: {file.get('file').name}</h4> : <></>}
                </div>
                <div onClick={() => {editCat(match.params.id, name, file, history)}} className="button" style={{ margin:'0 auto', marginTop:40, height:'fit-content' }}><img src="/images/check-icon.svg" style={{ width:15, height:15 }}></img>Valider</div>
            </div>
        </div>
    </div>
    </>
    )
};

// == Export
export default CategoryAjout;