// == Import : npm
import { connect } from 'react-redux';

// == Import : local
import WebappQuizTest from '../../screen/WebappQuizTest';

// Action Creators
import { getQuiz, getQuizFile } from '../../store/reducer';

const mapStateToProps = (state, ownProps) => ({
  datas: state.datas,
  waitingRequest: state.waitingRequest,
  connected: state.connected,
  quizzFile: state.quizzFile
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getQuiz: (params) => {
    dispatch(getQuiz(1, params));
  },
  getQuizFile: () => {
    dispatch(getQuizFile())
  }
});

// Container
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebappQuizTest);