// == Import : npm
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';

// == Import : local
import '../../style/style.scss';

import Header from '../../containers/Header';

import { boEntries } from '../../utils/headerEntries.js';

// == Composant
const QuizQuestion = ({ location, history, match, waitingRequest, datas, getAQuiz, editQuizz, deleteEntity, connected }) => {

    const [name, setName] = useState(null);
    const [emplacement, setEmplacement] = useState(null);
    const [statut, setStatut] = useState(null);
    const [questionFile, setQuestionFile] = useState(null);
    const [answerFile, setAnswerFile] = useState(null);
    const [fileNameQuestion, setFileNameQuestion] = useState(null);
    const [fileQuestionId, setFileQuestionId] = useState(null);
    const [fileAnswerId, setFileAnswerId] = useState(null);
    const [fileNameAnswer, setFileNameAnswer] = useState(null);
    const [quizzFile, setQuizzFile] = useState({
        quizzQuestion: null,
        quizzAnswer: null
    })

    const [initItems, setInitItems] = useState(false)

    useEffect(() => {
        if(connected!=undefined || connected!=null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')=='ROLE_ADMIN'))
        getAQuiz(match.params.id);
    }, [])

    if(datas['@id'] === `/api/quizzs/${match.params.id}` && !initItems) {
        setName(datas.title);
        setStatut(datas.status)
        console.log(datas)
        setQuizzFile({
            quizzQuestion: datas.quizzQuestionFile,
            quizzAnswer: datas.quizzAnswerFile
        })

        setInitItems(true)
    }

    if(waitingRequest > 0 || connected==undefined || connected==null || (connected && connected.roles.find(element => element =='ROLE_ADMIN')!='ROLE_ADMIN')) {
        return (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100vw' }}>
                <ReactLoading type={'cylon'} color={'#0578BE'} height={'10%'} width={'10%'} />
            </div>
        )
    }

    const uploadQuestion = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'application/pdf') {
            setFileNameQuestion(fileURI.name)
            const data = new FormData();
            data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
            setQuestionFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    const uploadAnswers = (e) => {
        const fileURI = e.target.files[0];
        console.log(fileURI)
        if(fileURI.type === 'application/pdf') {
            setFileNameAnswer(fileURI.name)
            const data = new FormData();
            data.append('file', fileURI, fileURI.name.replace(/\s+/g, '-'));
            setAnswerFile(data)
        } else {
          console.log('fichier non valide !')
        }
    }

    return (
        <>
            <Header currentMenuItem={2} entries={boEntries} location={location} match={match} />
            <div className='page-wrapper'>
                <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%', width:'100%' }}>
                    <h1 className='page-title' style={{ marginBottom: 20 }}>Modifier le quiz</h1>
                    <button onClick={() => history.goBack()} className='button buton-link' style={{ display:'flex', flexDirection:'row' }}><img src='/images/undobleu.png' style={{ width:20, height:20, margin:0, marginRight:10 }}></img>Retour</button>
                </div>
                <div className='grey-background' style={{ marginBottom: 150 }}>
                    <h2 className='page-subtitle' style={{ marginBottom: 30, textTransform:'uppercase' }}>Informations sur le quiz</h2>
                    <label style={{ marginTop: 10, marginBottom:10 }}>Nom du quiz</label>
                    <input type='text' className="input-text" placeholder='Nom...' value={name} onChange={(e) => {setName(e.target.value)}}></input>
                    <label style={{ marginTop: 40, textTransform: 'uppercase', fontSize:20, marginBottom:10 }}>Statut du Quiz</label>
                    <p style={{ marginBottom:10 }}>Mise en ligne du quiz dans l'emplacement choisi</p>
                    {statut === "discover" ? (
                        <div>
                            <input type="radio" id="webapp" name="drone" value="discover" checked={statut === "discover"} onClick={(e) => {if(e.target.value){setStatut(e.target.value)}}} />
                            <label htmlFor="webapp">Quizz de test (webapp)</label>
                        </div>
                    ) : (
                        <>
                            <div>
                                <input type="radio" id="En ligne" name="drone" value="En ligne" checked={statut === "En ligne"} onClick={(e) => {if(e.target.checked) {setStatut(e.target.id)}}} />
                                <label htmlFor="En ligne">En ligne(désactivera par defaut tout autre quizz en ligne dans l'emplacement sélectionné</label>
                            </div>
                            <div>
                                <input type="radio" id="Hors ligne" name="drone" value="Hors ligne" checked={statut === "Hors ligne"} onClick={(e) => {if(e.target.checked) {setStatut(e.target.id)}}} />
                                <label htmlFor="Hors ligne">Hors ligne</label>
                            </div>
                            <div>
                                <input type="radio" id="application" name="drone" value="pres" checked={statut === "pres"} onClick={(e) => {if(e.target.value){setStatut(e.target.value)}}} />
                                <label htmlFor="application">Quizz de présentation (application)</label>
                            </div>
                        </>
                    )}
                </div>
                {statut && statut === "discover" ? (
                    <>
                        <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%' }}>
                            <h2 className='page-title' style={{ marginBottom: 20 }}>Questionnaire</h2>
                            {quizzFile['quizzQuestion'].length === 0 ? (
                                <label className='button buton-link' style={{cursor:'pointer'}} htmlFor="quizzQuestion">
                                    Ajouter un questionnaire
                                    <input
                                        className="custom-file-input"
                                        style={{display:'none'}}
                                        type="file"
                                        name="customFile"
                                        id="quizzQuestion"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                            const accept = "image/png, image/jpeg"
                                            accept.split(',').forEach((element) => {
                                            if(element === element.replace(/\s+/g, '')){
                                                uploadQuestion(e)
                                            }
                                            })
                                        }}
                                    />
                                </label>
                            ) : null}
                        </div>

                        <div className='table-wrapper' style={{ marginBottom:100 }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>rang</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quizzFile['quizzQuestion'] === null && fileNameQuestion !== null ? (
                                        <tr>
                                            <td>{fileNameQuestion}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ) : null}
                                    {quizzFile['quizzQuestion'] ? quizzFile['quizzQuestion'].map((element) => (
                                        //console.log(element)
                                        <tr>
                                            <td>{fileNameQuestion !== null ? fileNameQuestion : element.title}</td>
                                            <td>{element.rank}</td>
                                            <td>
                                                <div style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                                    <a href={`https://mia.api.wacan-extranet.com/${element.fileUrl}`} target="_blank" rel="noreferrer noopener">Télécharger</a>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                                </div>
                                                <div style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                                <label style={{cursor:'pointer'}} htmlFor="quizzQuestion">
                                                    Modifier
                                                    <input
                                                        className="custom-file-input"
                                                        style={{display:'none'}}
                                                        type="file"
                                                        name="customFile"
                                                        id="quizzQuestion"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        accept="application/pdf"
                                                        onChange={(e) => {
                                                            const accept = "image/png, image/jpeg"
                                                            setFileQuestionId(element.id)
                                                            accept.split(',').forEach((element) => {
                                                            if(element === element.replace(/\s+/g, '')){
                                                                uploadQuestion(e)
                                                            }
                                                            })
                                                        }}
                                                    />
                                                </label>
                                                <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                                </div>
                                                <div onClick={() => {deleteEntity('tools', element.id)}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none', cursor:'pointer'}}>
                                                    <p>Supprimer</p>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : null}
                                </tbody>
                            </table>
                        </div>

                        <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%' }}>
                            <h2 className='page-title' style={{ marginBottom: 20 }}>Réponse</h2>
                            {quizzFile['quizzAnswer'].length === 0 ? (
                                <label className='button buton-link' style={{cursor:'pointer'}} htmlFor="quizzAnswer">
                                    Ajouter une réponse
                                    <input
                                        className="custom-file-input"
                                        style={{display:'none'}}
                                        type="file"
                                        name="customFile"
                                        id="quizzAnswer"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept="application/pdf"
                                        onChange={(e) => {
                                            const accept = "image/png, image/jpeg"
                                            accept.split(',').forEach((element) => {
                                            if(element === element.replace(/\s+/g, '')){
                                                uploadAnswers(e)
                                            }
                                            })
                                        }}
                                    />
                                </label>
                            ) : null}
                        </div>

                        <div className='table-wrapper' style={{ marginBottom:100 }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>rang</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quizzFile['quizzAnswer'] === null && fileNameAnswer !== null ? (
                                        <tr>
                                            <td>{fileNameAnswer}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ) : null}
                                    {quizzFile['quizzAnswer'] ? quizzFile['quizzAnswer'].map((element) => (
                                        //console.log(element)
                                        <tr>
                                            <td>{fileNameAnswer !== null ? fileNameAnswer : element.title}</td>
                                            <td>{element.rank}</td>
                                            <td>
                                                <div style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                                    <a href={`https://mia.api.wacan-extranet.com/${element.fileUrl}`} target="_blank" rel="noreferrer noopener">Télécharger</a>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                                </div>
                                                <div style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                                <label style={{cursor:'pointer'}} htmlFor="quizzAnswer">
                                                    Modifier
                                                    <input
                                                        className="custom-file-input"
                                                        style={{display:'none'}}
                                                        type="file"
                                                        name="customFile"
                                                        id="quizzAnswer"
                                                        aria-describedby="inputGroupFileAddon01"
                                                        accept="application/pdf"
                                                        onChange={(e) => {
                                                            const accept = "image/png, image/jpeg"
                                                            setFileAnswerId(element.id)
                                                            accept.split(',').forEach((element) => {
                                                            if(element === element.replace(/\s+/g, '')){
                                                                uploadAnswers(e)
                                                            }
                                                            })
                                                        }}
                                                    />
                                                </label>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                                </div>
                                                <div onClick={() => {deleteEntity('tools', element.id)}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none', cursor:'pointer'}}>
                                                    <p>Supprimer</p>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <></>}
                                </tbody>
                            </table>
                        </div>
                        <div style={{display:'flex'}}>
                            <button onClick={() => {
                                if(questionFile !== null && answerFile !== null && fileQuestionId === null && fileAnswerId === null) {
                                    editQuizz(match.params.id, name, statut, questionFile, answerFile)
                                } else if (questionFile !== null && answerFile !== null && fileQuestionId !== null && fileAnswerId !== null) {
                                    editQuizz(match.params.id, name, statut, questionFile, answerFile, fileQuestionId, fileAnswerId)
                                } else {
                                    editQuizz(match.params.id, name, statut)
                                }
                            }} style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Enregistrer</button>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ display:'flex',flexDirection: 'row', justifyContent:'space-between', margin:'100px 0% 10px 0%' }}>
                            <h2 className='page-title' style={{ marginBottom: 20 }}>liste des questions</h2>
                            <Link to={`/admin/quiz/questions/${match.params.id}/ajout-question`} className='button buton-link'>Nouvelle question</Link>
                        </div>

                        <div className='table-wrapper' style={{ marginBottom:100 }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>rang</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datas['questions'] ? datas['questions'].map((element) => (
                                        <tr>
                                            <td>{element.question}</td>
                                            <td>{element.rank}</td>
                                            <td>
                                                <Link to={`/admin/quiz/questions/${match.params.id}/edit-question/${element.id}`} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none'}}>
                                                    <p>Modifier</p>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/pen-icon.svg'></img>
                                                </Link>
                                                <div onClick={() => {deleteEntity('questions', element.id)}} style={{display:'flex', flexDirection:'row', width:100, justifyContent:'space-between', margin:'0 auto', marginTop:10, textDecoration:'none', cursor:'pointer'}}>
                                                    <p>Supprimer</p>
                                                    <img style={{width:14, height:14, margin:0}} src='/images/cross-icon.svg'></img>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <></>}
                                </tbody>
                            </table>
                        </div>
                        <div style={{display:'flex'}}>
                            <button onClick={() => {
                                if(questionFile !== null && answerFile !== null && fileQuestionId === null && fileAnswerId === null) {
                                    editQuizz(match.params.id, name, statut, questionFile, answerFile)
                                } else if (questionFile !== null && answerFile !== null && fileQuestionId !== null && fileAnswerId !== null) {
                                    editQuizz(match.params.id, name, statut, questionFile, answerFile, fileQuestionId, fileAnswerId)
                                } else {
                                    editQuizz(match.params.id, name, statut)
                                }
                            }} style={{ border:'1px solid #0578BE', borderRadius:10, padding:'7px 20px', background:'transparent', color:'#0578BE', fontSize:14, marginTop:20, fontWeight:'bold', cursor:'pointer' }}><img src='/images/check-icon.svg' style={{ height:13, width:13, margin:0, marginRight:10 }}></img>Enregistrer</button>
                        </div>
                    </>
                )}
            </div>
        </>
    )
};

// == Export
export default QuizQuestion;